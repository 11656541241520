import '@/lib/datadog'
import '@/styles/globals.css'

import { ApolloProvider } from '@apollo/client'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import Cookies from 'cookies'
import type { NextPage } from 'next'
import type { AppContext, AppInitialProps, AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import type { ReactElement, ReactNode } from 'react'
import { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'

import { ErrorBoundary } from '@/components'
import { Storage } from '@/constants'
import I18nWrapper from '@/containers/I18nWrapper'
import ThemeWrapper from '@/containers/ThemeWrapper'
import { CurrentUserContextProvider } from '@/contexts/CurrentUserContext'
import fetchUser from '@/helpers/user/fetchUser'
import client, { getSSRClient } from '@/lib/apollo'
import { getFeatureFlags } from '@/lib/launchdarkly'
import Pwa from '@/utils/Pwa'

const PRELOAD_FONTS = [
  'Matter/Matter-Medium.woff2',
  'Matter/Matter-MediumItalic.woff2',
  'Matter/Matter-Regular.woff2',
  'Matter/Matter-RegularItalic.woff2',
  'Matter/Matter-SemiBold.woff2',
  'Matter/Matter-SemiBoldItalic.woff2',
]

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout: (page: ReactElement) => ReactNode
  isLoading?: boolean
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const MyApp = ({ Component, pageProps }: AppPropsWithLayout): JSX.Element => {
  const { query } = useRouter()

  useEffect(() => {
    if (!query?.cohortId) return

    localStorage.setItem(Storage.CURRENT_COHORT_ID, query.cohortId as string)
  }, [query])

  return (
    <>
      <ErrorBoundary>
        <Head>
          {PRELOAD_FONTS.map(font => (
            <link
              key={font}
              rel="preload"
              href={`/fonts/${font}`}
              as="font"
              type="font/woff2"
              crossOrigin="anonymous"
            />
          ))}

          <Pwa />

          <title>Coleap</title>
        </Head>

        <Script
          id="segment-snippet"
          dangerouslySetInnerHTML={{
            __html: `  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="YOUR_WRITE_KEY";analytics.SNIPPET_VERSION="4.15.2";
            analytics.load("${process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY ?? ''}");
            }}();`,
          }}
        />

        <ApolloProvider client={client}>
          <ThemeWrapper>
            <I18nWrapper>
              <I18nProvider i18n={i18n}>
                <CurrentUserContextProvider user={pageProps.user} featureFlags={pageProps.featureFlags}>
                  {Component.getLayout(<Component {...pageProps} />)}
                </CurrentUserContextProvider>
              </I18nProvider>
            </I18nWrapper>
          </ThemeWrapper>
        </ApolloProvider>

        <Toaster position="top-right" />
      </ErrorBoundary>
    </>
  )
}

MyApp.getInitialProps = async (context: AppContext): Promise<AppInitialProps> => {
  if (!context.ctx.req || !context.ctx.res) {
    return {
      pageProps: {},
    }
  }

  const cookies = new Cookies(context.ctx.req, context.ctx.res)
  const ssrClient = getSSRClient(cookies.get(Storage.API_TOKEN) ?? '')

  const user = await fetchUser(ssrClient)
  const featureFlags = await getFeatureFlags(user)

  return {
    pageProps: {
      user,
      featureFlags,
    },
  }
}

export default MyApp
