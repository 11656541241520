import classNames from 'classnames'

type Props = {
  radius?: 'sm' | 'md' | 'base' | 'lg' | 'xl'
  justify?: 'left' | 'center'
  padding?: 'sm' | 'md' | 'lg'
  spaceless?: boolean
  overflowHidden?: boolean
  className?: string
}

const Card: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  radius = 'xl',
  justify = 'left',
  padding = 'lg',
  spaceless,
  overflowHidden = false,
  className = 'bg-white',
}) => {
  const classes = classNames(className, {
    'rounded-sm': radius === 'sm',
    'rounded-md': radius === 'md',
    'rounded-base': radius === 'base',
    'rounded-lg': radius === 'lg',
    'rounded-xl': radius === 'xl',
    'text-center': justify === 'center',
    'text-left': justify === 'left',
    'overflow-hidden': overflowHidden,
    'p-10': !spaceless && padding === 'lg',
    'p-6': !spaceless && padding === 'md',
    'p-4': !spaceless && padding === 'sm',
  })

  return <div className={classes}>{children}</div>
}

export default Card
