import classNames from 'classnames'

import { AVATAR_GROUP_VISIBLE_IMAGES_LIMIT } from '@/constants'

import Avatar, { AvatarSizes, getWidthAndHeight } from '../Avatar/Avatar'

type Props = {
  images: {
    name: string
    url: string
  }[]
  size?: AvatarSizes
  ringColor?: 'white' | 'grey-extra-light' | 'grey-bright'
  tight?: boolean
  shouldHideImageCount?: boolean
}

const AvatarGroup: React.FC<React.PropsWithChildren<Props>> = ({
  images,
  size = 'sm',
  ringColor,
  tight,
  shouldHideImageCount = false,
}) => {
  const visibleImages = images.slice(0, AVATAR_GROUP_VISIBLE_IMAGES_LIMIT)
  const hiddenImagesCount = images.length - AVATAR_GROUP_VISIBLE_IMAGES_LIMIT
  const widthAndHeight = getWidthAndHeight(size)
  const ringBackgroundColor =
    ringColor === 'white'
      ? 'ring-white'
      : ringColor === 'grey-extra-light'
      ? 'ring-grey-extra-light'
      : ringColor === 'grey-bright'
      ? 'ring-grey-bright'
      : ''

  const commonClasses = classNames('rounded-full', ringBackgroundColor, {
    'ring-2': ringColor && (size === 'sm' || size === 'md'),
    'ring-6': ringColor && !(size === 'sm' || size === 'md'),
  })
  const classes = {
    image: classNames(`relative rounded-full flex`, commonClasses),
    hiddenImages: classNames(
      'relative bg-purple-bright font-medium justify-center flex items-center',
      size === 'sm' || size === 'md' ? 'text-xs' : size === 'lg' ? 'md:text-sm' : 'md:text-xl',
      commonClasses,
    ),
  }

  return (
    <>
      <div
        className={classNames('flex', {
          '-space-x-1': !tight && (size === 'sm' || size === 'md'),
          '-space-x-2': !tight && !(size === 'sm' || size === 'md'),
          '-space-x-3': tight && (size === 'sm' || size === 'md'),
          '-space-x-5': tight && !(size === 'sm' || size === 'md'),
        })}
      >
        {visibleImages.map(({ name, url }, index) => (
          <div key={index} style={{ width: widthAndHeight, height: widthAndHeight }} className={classes.image}>
            <Avatar url={url} name={name} size="3xl" />
          </div>
        ))}

        {hiddenImagesCount > 0 && !shouldHideImageCount ? (
          <div style={{ width: widthAndHeight, height: widthAndHeight }} className={classes.hiddenImages}>
            +{hiddenImagesCount}
          </div>
        ) : null}
      </div>
    </>
  )
}

export default AvatarGroup
