import { useRouter } from 'next/router'

import { useBlockContext } from '@/contexts/BlockContext'
import { useCohortContext } from '@/contexts/CohortContext'
import { useLearningCommunityContext } from '@/contexts/LearningCommunityContext'

export type EventData = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

type BlockData = {
  id?: string
  title?: string
  type: {
    identifier: string
  }
}

type ReturnType = {
  trackEvent: (eventName: string, ...data: EventData[]) => void
  setupPageViewTracking: () => () => void
  withCurrentLearningCommunity: () => EventData
  withCurrentBlock: () => EventData
  withCurrentCohortId: () => EventData
  withCohortIdFromQuery: () => EventData
  withLearningCommunitySlugFromQuery: () => EventData
  withBlockIdFromQuery: () => EventData
  withBlock: (block?: BlockData | null) => EventData
  withFeedEntryIdFromQuery: () => EventData
}
const useTracking = (): ReturnType => {
  const learningCommunityContext = useLearningCommunityContext()
  const cohortContext = useCohortContext()
  const blockContext = useBlockContext()

  const {
    query: { slug, cohortId, blockId, feedEntryId },
    events,
  } = useRouter()

  return {
    setupPageViewTracking: () => {
      const handleRouteChange = (): void => {
        analytics?.page(
          {},
          {
            Intercom: { hideDefaultLauncher: true },
          },
        )
      }
      events?.on('routeChangeComplete', handleRouteChange)
      return () => events?.off('routeChangeComplete', handleRouteChange)
    },
    trackEvent: (eventName: string, ...data: EventData[]) => {
      const eventData = data.reduce((mergedData, data) => {
        return {
          ...mergedData,
          ...data,
        }
      }, {})

      analytics?.track(eventName, eventData)
    },
    withCurrentLearningCommunity: () => {
      return {
        learningCommunity: {
          id: learningCommunityContext?.learningCommunity?.id ?? null,
          name: learningCommunityContext?.learningCommunity?.name ?? null,
          slug: learningCommunityContext?.learningCommunity?.slug ?? null,
        },
      }
    },
    withCurrentBlock: () => {
      return {
        block: {
          id: blockContext?.block?.id ?? null,
          type: blockContext?.block?.type?.identifier?.toLowerCase() ?? null,
          title: blockContext?.block?.title ?? null,
        },
      }
    },
    withCurrentCohortId: () => {
      return {
        cohortId: cohortContext?.cohort?.id ?? null,
      }
    },
    withCohortIdFromQuery: () => {
      return {
        cohortId: cohortId ?? null,
      }
    },
    withBlockIdFromQuery: () => {
      return {
        block: {
          id: blockId ?? null,
        },
      }
    },
    withFeedEntryIdFromQuery: () => {
      return {
        feedEntry: {
          id: feedEntryId ?? null,
        },
      }
    },
    withLearningCommunitySlugFromQuery: () => {
      return {
        learningCommunity: {
          slug: slug ?? null,
        },
      }
    },
    withBlock: (block?: BlockData | null) => {
      return {
        block: {
          id: block?.id ?? null,
          title: block?.title ?? null,
          type: block?.type?.identifier?.toLowerCase() ?? null,
        },
      }
    },
  }
}

export default useTracking
