import { NextRouter, useRouter } from 'next/router'

import useFlags from '../useFlags'

type ReturnType = {
  router: NextRouter
  pushWithSSR: (url: string) => void
  getArticleRoute: (blockSlug: string) => string
  getEventRoute: (eventId: string) => string
  getLearningCommunityEventsRoute: (lcSlug: string) => string
  getCohortHomeRoute: (lcSlug: string, cohortId: string) => string
  routes: {
    LOGIN: string
    MANAGE?: string
    LEARN?: string
    APPLY?: string
  }
}

const useRoutes = (): ReturnType => {
  const router = useRouter()
  const { viewHome } = useFlags()
  const { slug, cohortId } = router.query

  return {
    router,
    pushWithSSR: url => {
      window.location.href = url
    },
    getArticleRoute: blockSlug => `/${slug}/articles/${blockSlug}`,
    getCohortHomeRoute: (lcSlug, cohortId) => `/${lcSlug}/learn/${cohortId}${viewHome ? '/dashboard' : ''}`,
    getEventRoute: eventId => `/events/${eventId}`,
    getLearningCommunityEventsRoute: lcSlug => `/${lcSlug}/events`,
    routes: {
      LOGIN: '/auth/login',
      MANAGE: `/${slug}/manage/${cohortId}`,
      LEARN: `/${slug}/learn/${cohortId}`,
      APPLY: `/${slug}/apply`,
    },
  }
}

export default useRoutes
