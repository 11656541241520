import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-toward-subtle.css'

import Tippy, { TippyProps } from '@tippyjs/react'
import { ReactElement } from 'react'

type Props = TippyProps & {
  content: ReactElement | string | null
}

const Tooltip: React.FC<React.PropsWithChildren<Props>> = ({ content, children, ...props }) => {
  const isMobile = window.innerWidth < 768

  if (isMobile) return <>{children}</>

  return (
    <>
      {content ? (
        <Tippy {...props} content={content} className="px-1 pb-0.5 font-medium font-sans capsize">
          {children}
        </Tippy>
      ) : (
        children
      )}
    </>
  )
}

export default Tooltip
