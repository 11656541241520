import { createContext, useContext } from 'react'

import { CustomLearningCommunity } from '@/types/custom'

type LearningCommunityContextProviderProps = {
  children: React.ReactNode
  learningCommunity: CustomLearningCommunity
}
type LearningCommunityContextProps = {
  learningCommunity: CustomLearningCommunity
  hasLearningPath: boolean
}

export const LearningCommunityContext = createContext<LearningCommunityContextProps | undefined>(undefined)

export const useLearningCommunityContext = (): LearningCommunityContextProps | undefined =>
  useContext<LearningCommunityContextProps | undefined>(LearningCommunityContext)

export const LearningCommunityContextProvider: React.FC<
  React.PropsWithChildren<LearningCommunityContextProviderProps>
> = ({ children, learningCommunity }) => {
  const hasLearningPath = !!learningCommunity?.cohorts?.find(cohort => cohort?.learningPath?.sections?.length)

  const learningCommunityContext = {
    learningCommunity,
    hasLearningPath,
  }

  return (
    <LearningCommunityContext.Provider value={learningCommunityContext}>{children}</LearningCommunityContext.Provider>
  )
}
