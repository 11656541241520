var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5cf3ef32507fc81dd7e0a4873ff9bf06f9612bf3"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const SAMPLE_RATE = process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: SAMPLE_RATE,
  sampleRate: SAMPLE_RATE,
  release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
})
