import { createContext, useContext } from 'react'

import { CustomLearningBlock } from '@/types/custom'

type BlockContextProviderProps = {
  children: React.ReactNode
  block?: CustomLearningBlock
}
type BlockContextProps = {
  block?: CustomLearningBlock
}

export const BlockContext = createContext<BlockContextProps | undefined>(undefined)

export const useBlockContext = (): BlockContextProps | undefined =>
  useContext<BlockContextProps | undefined>(BlockContext)

export const BlockContextProvider: React.FC<React.PropsWithChildren<BlockContextProviderProps>> = ({
  children,
  block,
}) => {
  const blockContext = {
    block,
  }

  return <BlockContext.Provider value={blockContext}>{children}</BlockContext.Provider>
}
