import { gql, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { PropsWithChildren, useEffect } from 'react'

import { initLocale, setLocale } from '@/helpers/i18n'

export default function I18nWrapper({ children }: PropsWithChildren): JSX.Element {
  const { query, isReady } = useRouter()
  const { data } = useQuery(GET_LEARNING_COMMUNITY_LOCALE, {
    skip: !query?.slug || !isReady,
    variables: {
      slug: query?.slug,
    },
  })

  useEffect(() => {
    initLocale()
  }, [])

  useEffect(() => {
    if (!data) return

    setLocale(data?.learningCommunity?.locale?.toLowerCase() ?? 'en')
  }, [data])

  return <>{children}</>
}

const GET_LEARNING_COMMUNITY_LOCALE = gql`
  query LearningCommunity($slug: String!) {
    learningCommunity(slug: $slug) {
      id
      locale
    }
  }
`
