import { useCurrentUserContext } from '@/contexts/CurrentUserContext'

type ReturnType = { [key: string]: boolean }

const useFlags = (): ReturnType => {
  const currentUserContext = useCurrentUserContext()

  return currentUserContext?.featureFlags ?? {}
}

export default useFlags
