import { gql, useMutation } from '@apollo/client'
import { t } from '@lingui/macro'
import { useRouter } from 'next/router'

import { Toast } from '@/components'
import { useLearningCommunityContext } from '@/contexts/LearningCommunityContext'
import { ChannelSourceTypeEnum, ChannelTypeEnum, Maybe } from '@/graphql/generated'
import { userListContainsAIAssistant } from '@/helpers/aiAssistant/userListContainsAIAssistant'
import { CustomCohort } from '@/types/custom'

export interface ReturnType {
  creatingChannel: boolean
  openChannel: (
    participants: Maybe<{ id: string }[]>,
    cohort?: CustomCohort,
    channelType?: ChannelTypeEnum,
  ) => Promise<void>
}

const useDirectMessages = ({
  onDone,
  redirectToCreatedChannel = true,
}: { onDone?: (channelId?: string) => void; redirectToCreatedChannel?: boolean } = {}): ReturnType => {
  const learningCommunityContext = useLearningCommunityContext()
  const { query, push } = useRouter()

  const [createChannel, { loading: creatingChannel }] = useMutation(CREATE_CHANNEL_MUTATION, {
    update: (cache, { data }, { variables }) => {
      if (variables?.attributes?.type === ChannelTypeEnum.Ai) {
        cache.modify({
          id: cache.identify({
            id: variables?.attributes?.sourceId,
            __typename: 'Cohort',
          }),
          fields: {
            aiChannels: (existingChannelsRef: { id: string }[] = [], { readField }) => {
              const newChannelRef = cache.writeFragment({
                data: data.createChannel.channel,
                fragment: gql`
                  fragment NewChannel on Channel {
                    id
                    name
                    type
                    emojiIcon
                    viewer {
                      hasUnreadMessages
                    }
                  }
                `,
              })

              if (existingChannelsRef.some(ref => readField('id', ref) === data.createChannel.channel.id)) {
                return existingChannelsRef
              }
              return [...existingChannelsRef, newChannelRef]
            },
          },
        })
      }
    },
    onCompleted: data => {
      if (!data?.createChannel?.errors) {
        // We need to think about how to handle this
        // for when clicking on profile cards vs create a new
        // channel from the sidebar.

        onDone?.(data?.createChannel?.channel?.id)
        if (redirectToCreatedChannel) {
          push({
            pathname: '/[slug]/space/[channelId]',
            query: { ...query, channelId: data?.createChannel?.channel?.id },
          })
        }

        return
      }

      Toast({
        message: t`Something went wrong. Try again later.`,
        type: 'error',
      })
    },
    refetchQueries: ['GetDirectChannels'],
  })

  async function openChannel(
    participants: Maybe<{ id: string }[]>,
    cohort?: CustomCohort,
    channelType = ChannelTypeEnum.Direct,
  ): Promise<void> {
    const userIds = participants?.map(user => user.id) ?? []

    const isAIAssistantChannel =
      userListContainsAIAssistant(participants) && channelType !== ChannelTypeEnum.AskYtChannel

    if (isAIAssistantChannel && !cohort) {
      Toast({
        message: t`Please select a cohort to use AI Assistant`,
        type: 'error',
      })
      return
    }

    await createChannel({
      variables: {
        attributes: {
          userIds,
          type: isAIAssistantChannel ? ChannelTypeEnum.Ai : channelType,
          sourceType: isAIAssistantChannel ? ChannelSourceTypeEnum.Cohort : ChannelSourceTypeEnum.LearningCommunity,
          sourceId: isAIAssistantChannel ? cohort?.id : learningCommunityContext?.learningCommunity?.id,
        },
      },
    })
  }

  return { openChannel, creatingChannel }
}

const CREATE_CHANNEL_MUTATION = gql`
  mutation CreateChannel($attributes: ChannelInput!) {
    createChannel(attributes: $attributes) {
      channel {
        id
        name
        type
        emojiIcon
        users {
          id
          avatarUrl
          firstName
        }
        viewer {
          hasUnreadMessages
        }
      }
      errors {
        field
        message
        path
      }
    }
  }
`

export default useDirectMessages
