import classNames from 'classnames'
import { createElement, ReactNode } from 'react'

import { TextStyle } from '@/constants/TextStyle'

enum Breakpoints {
  default = 'default',
  md = 'md',
}

type VariantOptions = {
  [key in Breakpoints]?: TextStyle
}

type Props = {
  as?: 'div' | 'p' | 'span'
  variant?: (arg: typeof TextStyle) => TextStyle | VariantOptions
  className?: string
  capsize?: boolean
  inline?: boolean
}

const Text: React.FC<React.PropsWithChildren<Props>> = ({
  as = 'p',
  children,
  variant = TextStyle => TextStyle.REGULAR_BASE_NORMAL,
  className,
  capsize,
  inline,
}) => {
  // Depending if `variant` is an object or a `TextStyle` we need to build the classes accordingly
  const variantValue = variant(TextStyle)
  const variantClasses =
    typeof variantValue === 'object'
      ? Object.keys(variantValue)
          .map(breakpointKey => {
            const breakpointValue = variantValue[breakpointKey as Breakpoints]

            if (breakpointKey === Breakpoints.default) {
              return breakpointValue
            }

            return `${breakpointKey}:${breakpointValue}`
          })
          .flat()
          .join(' ')
      : variantValue

  const classes = classNames(variantClasses, className, {
    'font-sans capsize': capsize,
    'inline-block': inline,
  })

  const create = (el: string): ReactNode =>
    createElement(
      el,
      {
        className: classes,
      },
      children,
    )
  const component = create(as)

  return <>{component}</>
}

export default Text
