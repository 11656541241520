import { useApolloClient } from '@apollo/client'
import { createContext, useContext, useEffect, useState } from 'react'

import { Query } from '@/graphql/generated'
import fetchUser from '@/helpers/user/fetchUser'
import useRealtime from '@/hooks/useRealtime'

export type CurrentUserContextProviderProps = {
  children: React.ReactNode
  user: Pick<Query, 'me'>
  featureFlags: { [key: string]: boolean }
}
type CurrentUserContextProps = {
  currentUser: Pick<Query, 'me'> | undefined
  featureFlags: { [key: string]: boolean }
  refetchUser: () => Promise<void>
  resetUser: () => void
  setUnreadChannelsCount: (count: number) => void
  unreadChannelsCount?: number
  isLoggingOut: boolean
  setLoggingOut: (isLoggingOut: boolean) => void
}

export const CurrentUserContext = createContext<CurrentUserContextProps | undefined>(undefined)

export const useCurrentUserContext = (): CurrentUserContextProps | undefined =>
  useContext<CurrentUserContextProps | undefined>(CurrentUserContext)

export const CurrentUserContextProvider: React.FC<React.PropsWithChildren<CurrentUserContextProviderProps>> = ({
  user,
  featureFlags,
  children,
}) => {
  const client = useApolloClient()
  const [currentUser, setCurrentUser] = useState<Pick<Query, 'me'> | undefined>(user)
  const [currentFeatureFlags, setCurrentFeatureFlags] = useState(featureFlags)
  const [unreadChannelsCount, setUnreadChannelsCount] = useState(user?.me?.unreadChannelsCount ?? 0)
  const [isLoggingOut, setLoggingOut] = useState(false)
  useRealtime({
    currentUser,
    increaseUnreadChannelsCount: () => setUnreadChannelsCount(unreadChannelsCount + 1),
  })

  useEffect(() => {
    if (!user?.me?.id) return

    setCurrentUser(user)
    setCurrentFeatureFlags(featureFlags)
  }, [user, user?.me?.id, featureFlags])

  const currentUserContext = {
    currentUser,
    featureFlags: currentFeatureFlags,
    refetchUser: async () => {
      const user = await fetchUser(client)
      setCurrentUser(user)
      setUnreadChannelsCount(user?.me?.unreadChannelsCount ?? 0)
    },
    resetUser: () => {
      setCurrentUser(undefined)
    },
    setUnreadChannelsCount,
    unreadChannelsCount,
    isLoggingOut,
    setLoggingOut,
  }

  return <CurrentUserContext.Provider value={currentUserContext}>{children}</CurrentUserContext.Provider>
}
