export enum Events {
  CLICKED_ON_RESOURCE = 'Clicked on Resource',
  CLICKED_LIVE_SESSION_FULL_SCREEN = 'Clicked Live Session Full Screen',
  LEFT_VIDEO_MEETING = 'Left Video Meeting',
  PLAYED_RECORDING_VIDEO = 'Played Recording Video',
  FINISHED_PLAYING_RECORDING_VIDEO = 'Finished Playing Recording Video',
  VISITED_APPLICATION_FORM = 'Visited Application Form',
  VISITED_SIGNUP_PAGE = 'Visited Learning Community Signup Page',
  PROCEED_TO_PAYMENT = 'Proceed to Payment',
  VISITED_SUCCESS_PAGE = 'Visited Success Page',
  CREATED_SECTION = 'Created Section',
  EDITED_SECTION = 'Edited Section',
  DELETED_SECTION = 'Deleted Section',
  DUPLICATED_SECTION = 'Duplicated Section',
  DELETED_BLOCK = 'Deleted Block',
  DUPLICATED_BLOCK = 'Duplicated Block',
  CLICKED_LEARN_MORE_LIVE_SESSION_BLOCK = 'Clicked Learn More Live Session Block',
  SENT_CALENDAR_INVITES = 'Sent Calendar Invites',
  CLICKED_CREATE_BLOCK = 'Clicked Create Block',
  CLICKED_EDIT_BLOCK = 'Clicked Edit Block',
  CREATED_BLOCK = 'Created Block',
  EDITED_BLOCK = 'Edited Block',
  APPLIED_FEED_FILTER = 'Applied Feed Filter',
  CLEARED_FEED_FILTERS = 'Cleared Feed Filters',
  MILESTONE_SUBMISSION_SHARED = 'Milestone Submission Shared',
  FEEDBACK_REQUEST_COMMENT_ADDED = 'Feedback Request Comment Added',
  FEEDBACK_REQUEST_REACTION_ADDED = 'Feedback Request Reaction Added',
  CLICKED_TO_VIEW_SUBMISSION_FEED = 'Clicked to View Shared Submission in Feed',
  CLICKED_TO_VIEW_SUBMISSION_CREATOR_CONSOLE = 'Clicked to Review Submission',
  VIEWED_PEER_PROFILE = 'Viewed Peer Profile',
  FAVORITED_PEER = 'Favorited Peer',
  REMOVED_FAVORITE_PEER = 'Removed Favorite Peer',
  CLICKED_TO_VIEW_NEXT_PROFILE = 'Clicked to View Next Profile',
  CLICKED_TO_VIEW_PREVIOUS_PROFILE = 'Clicked to View Previous Profile',
  CLICKED_COMPLETE_PROFILE_BUTTON = 'Clicked on Complete Profile Button',
  RATED_BLOCK_WITH_FEEDBACK = 'Rated Block With Feedback',
  RATED_BLOCK_WITHOUT_FEEDBACK = 'Rated Block Without Feedback',
  UPDATED_COHORT_PROFILE = 'Updated Cohort Profile',
  UPDATED_USER_PROFILE = 'Updated User Profile',
  UPDATED_COHORT_AVAILABILITY_SLOTS = 'Updated Cohort Availability Slots',
  CLICKED_NOTIFICATION_ITEM = 'Clicked Notification Item',
  ADDED_MILESTONE_SUBMISSION_FEEDBACK = 'Added Milestone Submission Feedback',
  COHORT_POST_COMMENT_ADDED = 'Cohort Post Comment Added',
  COHORT_POST_REACTION_ADDED = 'Cohort Post Reaction Added',
  COHORT_POST_CREATED = 'Cohort Post Created',
  COHORT_POST_UPDATED = 'Cohort Post Updated',
  CLICKED_TO_DOWNLOAD_CERTIFICATE = 'Clicked To Download Certificate',
  CLICKED_TO_SHARE_CERTIFICATE_ON_TWITTER = 'Clicked To Share Certificate On Twitter',
  CLICKED_TO_SHARE_CERTIFICATE_ON_LINKEDIN = 'Clicked To Share Certificate On LinkedIn',
  CLICKED_TO_VIEW_CERTIFICATE_SIDEBAR = 'Clicked To View Certificate From Sidebar',
  CLICKED_TO_VIEW_SUBMISSION_BLOCK = 'Clicked to View Submission in Block',
  ADDED_COMMENT_REACTION = 'Added Comment Reaction',
  ADDED_COMMENT_REPLY = 'Added Comment Reply',
  ADDED_COMMENT_TO_BLOCK = 'Added Comment to Block',
  ADDED_COMMENT_TO_EVENT = 'Added Comment to Event',
  ADDED_REPLY_TO_CHANNEL_MESSAGE = 'Added Reply to Channel Message',
  SENT_MESSAGE_IN_CHANNEL = 'Sent Message In Channel',
  CREATED_NEW_CHANNEL = 'Created New Channel',
  CLICKED_TO_VIEW_GROUP_MEMBERS_IN_CHANNEL = 'Clicked To View Group Members In Channel',
  CLICKED_ON_CHANNEL = 'Clicked On Channel',
  CLICKED_CREATE_NEW_CHANNEL = 'Clicked On Create New Channel',
  SELECTED_NEW_CHANNEL_PARTICIPANT = 'Selected New Channel Participant',
  TOGGLE_BLOCK_RESOURCES = 'Toggle Block Resources',
  CLICKED_TO_OPEN_EMOJI_PICKER = 'Clicked To Open Emoji Picker',
  CLICKED_TO_CLOSE_EMOJI_PICKER = 'Clicked To Close Emoji Picker',
  CLICKED_ON_EMOJI_IN_PICKER = 'Clicked On Emoji In Picker',
  CLICKED_TO_OPEN_GIF_PICKER = 'Clicked To Open Gif Picker',
  CLICKED_TO_CLOSE_GIF_PICKER = 'Clicked To Close Gif Picker',
  CLICKED_ON_GIF_IN_PICKER = 'Clicked On Gif In Picker',
  CLICKED_ON_SEND_IN_GIF_PICKER = 'Clicked On Send In Gif Picker',
  ADDED_FEEDBACK_REQUEST_RESPONSE = 'Added Feedback Request Response',
  CLICKED_ON_GIVE_FEEDBACK_BUTTON = 'Clicked On Give Feedback Button',
  CLICKED_ON_BLOCK = 'Clicked On Block',
  SELECTED_MENTION = 'Selected Mention',
  CLICKED_MESSAGE_ICON = 'Clicked Message Icon',
  HOVERED_MENTION = 'Hovered Mention',
  HOVERED_PROFILE = 'Hovered Profile',
  TOGGLE_BLOCKS_NAVIGATION_SIDEBAR = 'Toggle Blocks Navigation Sidebar',
  COHORT_PROFILE_INTRODUCTION_SHARED = 'Cohort Profile Introduction Shared',
  COHORT_PROFILE_INTRODUCTION_REACTION_ADDED = 'Cohort Profile Introduction Reaction Added',
  COHORT_PROFILE_INTRODUCTION_COMMENT_ADDED = 'Cohort Profile Introduction Comment Added',
  CHANNEL_MESSAGE_REACTION_ADDED = 'Channel Message Reaction Added',
  CLICKED_ON_ASK_FOR_FEEDBACK = 'Clicked On Ask For Feedback',
  DISMISSED_SHARE_PROFILE_CARD = 'Dismissed Share Profile Card',
  OPENED_SHARE_PROFILE_MODAL = 'Opened Share Profile Modal',
  COPIED_PUBLIC_LEARNING_BLOCK_LINK = 'Copied Public Learning Block Link',
  LOADED_NEXT_FEED_PAGE = 'Loaded Next Feed Page',
  LOADED_PREVIOUS_SPACE_PAGE = 'Loaded Previous Space Page',
  DELETED_POST = 'Deleted Post',
  DELETED_COMMENT = 'Deleted Comment',
  DELETED_CHANNEL_MESSAGE = 'Deleted Channel Message',
  COPIED_FEED_ENTRY_LINK = 'Copied Feed Entry Link',
  CLICKED_EDIT_POST = 'Clicked Edit Post',
  LOADED_NEXT_COMMUNITY_PAGE = 'Loaded Next Community Page',
  CLICKED_EDIT_COMMENT = 'Clicked Edit Comment',
  COMMENT_EDITED = 'Comment Edited',
  PLAYED_VIDEO = 'Played Video',
  PAUSED_VIDEO = 'Paused Video',
  WATCHED_VIDEO = 'Watched Video',
  CREATED_COHORT = 'Created Cohort',
  EDITED_COHORT = 'Edited Cohort',
  FILTERED_COMMUNITY = 'Filtered Community',
  CLICKED_SET_MY_AVAILABILITY_BANNER = 'Clicked Set My Availability Banner',
  CLICKED_UPDATE_AVAILABILITY_BANNER = 'Clicked Update Availability Banner',
  RSVP_YET_TO_LIVE_SESSION_BLOCK = 'RSVP Yes To Live Session Block',
  CAPTURED_MAGIC_BELL_ERROR = 'Captured Magic Bell Error',
  VISITED_EVENTS_PAGE = 'Visited Events Page',
  VISITED_EVENTS_MANAGEMENT_PAGE = 'Visited Events Management Page',
  CLICKED_ON_VIEW_LEARNING_PATH = 'Clicked On View Learning Path',
  CLICKED_ON_UPCOMING_LEARNING_BLOCK = 'Clicked On Upcoming Learning Block',
  CLICKED_ON_UPCOMING_EVENT = 'Clicked On Upcoming Event',
  CLICKED_ON_TRENDING_POST = 'Clicked On Trending Post',
  SENT_BLOCK_TO_AI_PROCESSING = 'Sent Block to AI Processing',
  ASKED_QUESTION_TO_AI = 'Asked Question To AI',
  RECEIVED_AI_REPLY_TO_QUESTION = 'Received AI Reply To Question',
  REMOVED_BLOCK_FROM_AI_DATA = 'Removed Block From AI Data',
  CLICKED_AI_MESSAGE_THUMB_UP = 'Clicked AI Message Thumb Up',
  CLICKED_AI_MESSAGE_THUMB_DOWN = 'Clicked AI Message Thumb Down',
}
