export const DEFAULT_THEME_COLOR = '84 70 188'

export const AvailableThemes: { name: string; primaryColor: string }[] = [
  {
    name: 'Purple',
    primaryColor: DEFAULT_THEME_COLOR,
  },
  {
    name: 'Orange',
    primaryColor: '249 115 22', // #f97316
  },
  {
    name: 'Yellow',
    primaryColor: '234 179 8', // #eab308
  },
  {
    name: 'Amber',
    primaryColor: '245 158 11', // #f59e0b
  },
  {
    name: 'Lime',
    primaryColor: '132 204 22', // #84cc16
  },
  {
    name: 'Green',
    primaryColor: '34 197 94', // #22c55e
  },
  {
    name: 'Emerald',
    primaryColor: '16 185 129', // #10b981
  },
  {
    name: 'Teal',
    primaryColor: '20 184 166', // #14b8a6
  },
  {
    name: 'Cyan',
    primaryColor: '6 182 212', // #06b6d4
  },
  {
    name: 'Sky',
    primaryColor: '14 165 233', // #0ea5e9
  },
  {
    name: 'Blue',
    primaryColor: '59 130 246', // #3b82f6
  },
  {
    name: 'Indigo',
    primaryColor: '99 102 241', // #6366f1
  },
  {
    name: 'Violet',
    primaryColor: '139 92 246', // #8b5cf6
  },
  {
    name: 'Fuchsia',
    primaryColor: '217 70 239', // #d946ef
  },
  {
    name: 'Red',
    primaryColor: '239 68 68', // #ef4444
  },
  {
    name: 'Rose',
    primaryColor: '244 63 94', // #f43f5e
  },
  {
    name: 'Slate',
    primaryColor: '100 116 139', // #64748b
  },
  {
    name: 'Stone',
    primaryColor: '120 113 108', // #78716c
  },
]
