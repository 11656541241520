import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment, useEffect, useState } from 'react'

import { Close } from '../Icons/v2'

type Props = {
  opened?: boolean
  onClose?: () => void
  showClose?: boolean
  size?: 'auto' | 'screen-xl' | '2xlarge' | 'xlarge' | 'large' | 'medium' | 'small'
  screenHeight?: boolean
}

const Modal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  opened = false,
  onClose,
  showClose,
  size = 'large',
  screenHeight = false,
}) => {
  const [isOpen, setIsOpen] = useState(opened)

  const closeModal = (): void => {
    setIsOpen(false)
    onClose?.()
  }

  useEffect(() => setIsOpen(opened), [opened])

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModal}>
          <div className={classNames('min-h-screen text-center', size === 'auto' ? 'px-28' : 'px-4')}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-[#000] bg-opacity-50" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={classNames(
                  'relative inline-block transform rounded-xl bg-white text-left align-middle shadow-xl transition-all',
                  {
                    'max-w-screen-2xl': size === 'screen-xl',
                    'max-w-7xl': size === '2xlarge',
                    'max-w-6xl': size === 'xlarge',
                    'max-w-5xl': size === 'large',
                    'max-w-3xl': size === 'medium',
                    'max-w-2xl': size === 'small',
                    'h-full-modal': screenHeight,
                    'w-full': size !== 'auto',
                    'my-8': !screenHeight,
                  },
                )}
              >
                <div className="h-full overflow-hidden rounded-xl">
                  {showClose ? (
                    <button
                      onClick={closeModal}
                      className="absolute right-8 top-8 z-50 flex h-8 w-8 items-center justify-center rounded-md bg-grey-extra-light hover:bg-grey-bright hover:text-navy-dark"
                    >
                      <Close className="h-4 w-4" />
                    </button>
                  ) : null}
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default Modal
