import { createContext, useContext } from 'react'

import { Maybe, User } from '@/graphql/generated'
import { CustomCohort } from '@/types/custom'

type CohortContextProviderProps = {
  children: React.ReactNode
  cohort?: CustomCohort
}
type CohortContextProps = {
  cohort?: CustomCohort
  people?: Maybe<Array<User>>
}

export const CohortContext = createContext<CohortContextProps | undefined>(undefined)

export const useCohortContext = (): CohortContextProps | undefined =>
  useContext<CohortContextProps | undefined>(CohortContext)

export const CohortContextProvider: React.FC<React.PropsWithChildren<CohortContextProviderProps>> = ({
  children,
  cohort,
}) => {
  const cohortContext = {
    cohort,
    people: cohort?.people,
  }

  return <CohortContext.Provider value={cohortContext}>{children}</CohortContext.Provider>
}
