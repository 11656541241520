import { gql, useQuery } from '@apollo/client'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import debounce from 'lodash.debounce'
import { useState } from 'react'
import { Waypoint } from 'react-waypoint'

import { Avatar, AvatarGroup, Button, Modal, Text } from '@/components'
import { IconCheckCircle } from '@/components/Icons'
import { ChatLine } from '@/components/Icons/v2'
import { useCohortContext } from '@/contexts/CohortContext'
import { useLearningCommunityContext } from '@/contexts/LearningCommunityContext'
import { Maybe, Query, User } from '@/graphql/generated'
import useDirectMessages from '@/hooks/useDirectMessages'

const PAGE_SIZE = 12

type Props = {
  onModalClose?: () => void
}
const isUser = (user?: User | null): user is User => !!user

export default function NewDirectMessageModal({ onModalClose }: Props): JSX.Element {
  const cohortContext = useCohortContext()
  const learningCommunityContext = useLearningCommunityContext()
  const [searchQuery, setSearchQuery] = useState<string | undefined>()
  const [selectedPeople, setSelectedPeople] = useState<Maybe<User[]>>([])
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const { openChannel, creatingChannel } = useDirectMessages({ onDone: onModalClose })

  const { data, loading, fetchMore } = useQuery<Pick<Query, 'peopleForDirectChannel'>>(
    GET_PEOPLE_IN_LEARNING_COMMUNITY,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only',
      skip: !learningCommunityContext?.learningCommunity?.id,
      variables: {
        learningCommunityId: learningCommunityContext?.learningCommunity?.id,
        first: PAGE_SIZE,
        query: searchQuery,
      },
    },
  )

  async function handleSubmit(): Promise<void> {
    openChannel(selectedPeople, cohortContext?.cohort)
  }

  const onWaypointEnter = async (): Promise<void> => {
    setIsLoadingMore(true)
    await fetchMore?.({
      variables: {
        after: data?.peopleForDirectChannel?.pageInfo?.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          peopleForDirectChannel: {
            ...fetchMoreResult.peopleForDirectChannel,
            edges: [
              ...(previousResult.peopleForDirectChannel.edges ?? []),
              ...(fetchMoreResult.peopleForDirectChannel.edges ?? []),
            ],
          },
        }
      },
    })
    setIsLoadingMore(false)
  }

  const people = data?.peopleForDirectChannel?.edges?.map(edge => edge?.node).filter(isUser) ?? []

  return (
    <Modal opened onClose={onModalClose} showClose screenHeight>
      <div className="h-full p-6">
        <div className="flex h-full flex-col space-y-4">
          <div className="flex items-center space-x-2 py-2 text-navy-dark">
            <ChatLine className="h-5 w-5 text-navy-dark" />
            <Text variant={TextStyle => TextStyle.MEDIUM_LG_RELAXED}>{t`Chat with your peers`}</Text>
          </div>

          <input
            onChange={debounce(e => setSearchQuery(e.target.value), 1000)}
            placeholder={t`Search`}
            className="w-full rounded-sm border border-grey-light px-4 py-2 outline-none focus:border-purple-base focus:ring-2 focus:ring-purple-bright"
          />

          <div className="space-x-2">
            <Button size="xs" onClick={() => setSelectedPeople([])}>
              {t`Clear selection`}
            </Button>
          </div>

          <div className="no-scrollbar grow overflow-auto rounded-md border border-purple-bright bg-purple-bright bg-opacity-30 p-2">
            <div className="w-full grid-cols-3 gap-2 space-y-3 md:grid md:space-y-0">
              {people?.map(user => {
                const isSelected = selectedPeople?.find(people => people.id === user.id)

                return (
                  <button
                    disabled={creatingChannel}
                    key={user.id}
                    onClick={() => {
                      if (isSelected) {
                        setSelectedPeople(selectedPeople?.filter(people => people.id !== user.id) ?? [])
                        return
                      }

                      setSelectedPeople([...(selectedPeople ?? []), user])
                    }}
                    className={classNames(
                      'flex items-start rounded-sm border bg-white px-3 py-2 text-left hover:border-grey-dark',
                      {
                        'border-grey-light': !isSelected,
                        'border-green-base': isSelected,
                      },
                    )}
                  >
                    <div className="flex w-full items-start space-x-3">
                      <div className="mt-1.5 w-8">
                        <Avatar url={user.avatarUrl} size="lg" />
                      </div>

                      <div className="flex-1 grow">
                        <Text variant={TextStyle => TextStyle.MEDIUM_BASE_NORMAL}>{user.fullName}</Text>
                        <Text
                          variant={TextStyle => TextStyle.REGULAR_SM_NORMAL}
                          className="w-48 truncate overflow-ellipsis whitespace-nowrap text-grey-dark"
                        >
                          {user.jobDescription}
                        </Text>
                      </div>

                      <div className="mt-1.5 w-4">
                        {isSelected ? <IconCheckCircle className="h-4 w-4 text-green-base" /> : null}
                      </div>
                    </div>
                  </button>
                )
              })}
              {data?.peopleForDirectChannel?.pageInfo?.hasNextPage && !loading && !isLoadingMore ? (
                <Waypoint onEnter={onWaypointEnter} />
              ) : null}
            </div>
          </div>

          <div className="mx-auto w-full space-y-4 py-4 md:w-1/3">
            <div className="flex h-12 items-center space-x-2">
              {selectedPeople?.length ? (
                <>
                  <AvatarGroup
                    size="md"
                    images={
                      selectedPeople?.map(user => ({
                        url: `${user.avatarUrl}`,
                        name: `${user.fullName}`,
                      })) ?? []
                    }
                    shouldHideImageCount
                  />

                  <Text variant={TextStyle => TextStyle.REGULAR_SM_NORMAL} className="text-grey-dark">
                    {selectedPeople && selectedPeople.length > 3
                      ? `${selectedPeople
                          .slice(0, 3)
                          .map(user => user?.firstName ?? '')
                          .join(', ')} and ${selectedPeople?.length - 3} more`
                      : new Intl.ListFormat().format(selectedPeople?.map(user => user?.firstName ?? '') ?? [])}
                  </Text>
                </>
              ) : (
                <>
                  <div className="flex items-center space-x-2">
                    <div>
                      <Text className="text-grey-dark" variant={TextStyle => TextStyle.REGULAR_SM_NORMAL}>
                        {t`Select people to start chatting`}
                      </Text>
                    </div>
                  </div>
                </>
              )}
            </div>

            <Button
              onClick={handleSubmit}
              disabled={!selectedPeople?.length || creatingChannel}
              variant="primary"
              block
            >
              {t`Start chatting`}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const GET_PEOPLE_IN_LEARNING_COMMUNITY = gql`
  query PeopleForDirectChannel($learningCommunityId: ID!, $first: Int, $after: String, $query: String) {
    peopleForDirectChannel(learningCommunityId: $learningCommunityId, first: $first, after: $after, query: $query) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          avatarUrl
          firstName
          fullName
          id
          jobDescription
          lastName
        }
      }
    }
  }
`
