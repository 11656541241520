import { gql, useQuery } from '@apollo/client'
import { Disclosure } from '@headlessui/react'
import { t } from '@lingui/macro'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { Avatar, Tooltip } from '@/components'
import { CaretDown, CaretRight, Plus } from '@/components/Icons/v2'
import MainMenuItem from '@/components/MainMenuItem/MainMenuItem'
import NewDirectMessageModal from '@/compositions/Spaces/NewDirectMessageModal'
import { useCurrentUserContext } from '@/contexts/CurrentUserContext'
import { Channel, ChannelEdge } from '@/graphql/generated'

function ChannelItem({ channel }: { channel: Channel }): JSX.Element {
  const { query, asPath } = useRouter()
  const currentUserContext = useCurrentUserContext()

  const channelPath = `/${query.slug}/space/${channel.id}`
  const isCurrentPath = asPath === channelPath

  // Remove current user from list of channel users
  const channelUsers = channel.users?.filter(user => user.id !== currentUserContext?.currentUser?.me?.id)
  const isGroupChat = channelUsers && channelUsers?.length > 1

  const channelAvatar =
    channelUsers?.find(user => user.id === process.env.NEXT_PUBLIC_COLEAP_BOT_USER_ID)?.avatarUrl ??
    channelUsers?.[0]?.avatarUrl

  return (
    <MainMenuItem
      href={channelPath}
      icon={
        <>
          <Avatar url={`${channelAvatar ?? ''}`} size="md" />

          {isGroupChat ? (
            <div className="absolute bottom-1 left-6 flex h-4 w-4 items-center justify-center rounded-full bg-navy-dark font-medium text-white text-[10px]">
              {channel.users?.length}
            </div>
          ) : null}
        </>
      }
      label={
        isGroupChat
          ? new Intl.ListFormat().format(channelUsers?.slice(0, 3)?.map(user => user?.firstName ?? ''))
          : (channelUsers?.[0]?.firstName as string)
      }
      isActive={isCurrentPath}
      indicator={channel?.viewer?.hasUnreadMessages}
    />
  )
}

export default function DirectMessages(): JSX.Element {
  const { data } = useQuery(GET_DIRECT_CHANNELS)
  const [showNewDirectMessageModal, setShowNewDirectMessageModal] = useState(false)

  const directChannels: Channel[] = data?.channels?.edges?.map((edge: ChannelEdge) => edge.node) ?? []

  return (
    <div>
      <Disclosure defaultOpen>
        {({ open }) => {
          const chevronProps = {
            className: 'w-4 h-4',
          }

          return (
            <>
              <div className="flex items-center px-1.5 text-grey-dark">
                <Disclosure.Button className="shrink rounded-sm p-1.5 text-left hover:bg-grey-bright hover:text-navy-dark">
                  {open ? <CaretDown {...chevronProps} /> : <CaretRight {...chevronProps} />}
                </Disclosure.Button>

                <div className="flex grow items-center space-x-1 rounded-sm p-1.5 font-medium text-sm">
                  {t`Direct messages`}
                </div>

                <Tooltip content="New direct message">
                  <button
                    onClick={() => setShowNewDirectMessageModal(true)}
                    className="flex items-center space-x-2 rounded-sm p-2 text-left font-medium text-navy-dark hover:bg-grey-bright"
                  >
                    <Plus className="h-3 w-3 text-grey-dark" />
                    <div className="-mt-px block text-sm md:hidden">{t`New`}</div>
                  </button>
                </Tooltip>
              </div>

              <Disclosure.Panel>
                <div className="py-2">
                  {directChannels
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                    ?.map(channel => (
                      <ChannelItem key={channel.id} channel={channel} />
                    ))}
                </div>
              </Disclosure.Panel>
            </>
          )
        }}
      </Disclosure>

      {showNewDirectMessageModal ? (
        <NewDirectMessageModal onModalClose={() => setShowNewDirectMessageModal(false)} />
      ) : null}
    </div>
  )
}

export const GET_DIRECT_CHANNELS = gql`
  query GetDirectChannels {
    channels(type: DIRECT) {
      edges {
        node {
          id
          name
          users {
            id
            avatarUrl
            firstName
          }
          viewer {
            hasUnreadMessages
          }
          updatedAt
        }
      }
    }
  }
`
