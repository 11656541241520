const SvgIconBook = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg fill="none" viewBox="0 0 16 16" role="img" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.5 1H0v12h3.5l2.75 2H7V3L3.5 1Zm9 0H16v12h-3.5l-2.75 2H9V3l3.5-2Z"
      clipRule="evenodd"
    />
  </svg>
)

export default SvgIconBook
