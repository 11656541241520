import axios from 'axios'

const { get, post } = axios.create({
  baseURL: '/api',
  headers: { 'Content-Type': 'application/json' },
  params: {
    secret: process.env.NEXT_CLIENT_API_TOKEN,
  },
})

const stream = async (endpoint: string, body = {}): Promise<Response> => {
  return fetch(endpoint, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ...body,
      secret: process.env.NEXT_CLIENT_API_TOKEN,
    }),
  })
}

export { get, post, stream }
