import { gql, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { createContext, PropsWithChildren, RefObject, useContext, useEffect, useReducer, useRef } from 'react'
import { scrollIntoView } from 'seamless-scroll-polyfill'

import { Toast } from '@/components'
import { YoutubeVideoDetails } from '@/compositions/AskMyYoutubeChannel/VideoDetails'
import { Events } from '@/constants'
import { Channel, Cohort, LearningCommunity } from '@/graphql/generated'
import useTracking from '@/hooks/useTracking'
import { stream } from '@/rest/clientApi'

type DispatchType = { type: string; value?: number | boolean | YoutubeVideoDetails }
type StateType = {
  scrollPosition: number
  displayNewMessagesIndicator?: boolean
  channelBottomRef?: RefObject<HTMLDivElement>
  threadBottomRef?: RefObject<HTMLDivElement>
  waitingForAIResponse?: boolean
  currentAIResponse?: string
  discussionWithAiStarted?: boolean
  selectedYoutubeVideo?: YoutubeVideoDetails | null
}

type FetchMoreType = {
  before?: string
  last?: string
}

type ReturnType = {
  channel?: Channel & {
    source?: Cohort | LearningCommunity
  }
  state: StateType
  fetchMore: ({ variables }: { variables: FetchMoreType }) => null
  dispatch: (action: DispatchType) => null
  handleAssistantDiscussion: (messageId?: string, content?: string, isAskMyYtChannel?: boolean) => void
}

const initialState = {
  scrollPosition: 0,
  displayNewMessagesIndicator: false,
}

const SpacesContext = createContext({
  channel: undefined,
  state: initialState,
  fetchMore: () => null,
  dispatch: () => null,
  handleAssistantDiscussion: () => null,
})

export function useSpacesContext(): ReturnType {
  return useContext(SpacesContext)
}

export default function SpaceProvider({ children }: PropsWithChildren): JSX.Element {
  const { query, replace, pathname } = useRouter()
  const { trackEvent, withLearningCommunitySlugFromQuery, withCohortIdFromQuery } = useTracking()
  const channelId = query?.channelId
  const { subscribeToMore, data, fetchMore } = useQuery(GET_CHANNEL, {
    variables: {
      channelId,
      last: 20,
    },
    skip: !channelId,
  })
  const channelBottomRef = useRef<HTMLDivElement>(null)
  const threadBottomRef = useRef<HTMLDivElement>(null)
  const isPhone = screen.width < 640

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [state, dispatch] = useReducer((state: StateType, action: { type: string; value?: any }) => {
    switch (action.type) {
      case 'THREAD_LOADED':
        return { ...state, threadBottomRef }
      case 'MESSAGE_ADDED_TO_THREAD':
        if (isPhone && state?.threadBottomRef?.current) {
          scrollIntoView(state.threadBottomRef.current)
        } else {
          state?.threadBottomRef?.current?.scrollIntoView()
        }

        return { ...state }
      case 'CHANNEL_LOADED':
        return { ...state, channelBottomRef }
      case 'MESSAGE_ADDED_TO_CHANNEL':
        if (isPhone && state?.channelBottomRef?.current) {
          scrollIntoView(state.channelBottomRef.current)
        } else {
          state?.channelBottomRef?.current?.scrollIntoView()
        }

        return { ...state }
      case 'SCROLL_MESSAGES_CONTAINER':
        return { ...state, scrollPosition: action.value }
      case 'NEW_MESSAGES_INDICATOR':
        return { ...state, displayNewMessagesIndicator: action.value }
      case 'REQUESTED_AI_RESPONSE':
        if (isPhone && state?.channelBottomRef?.current) {
          scrollIntoView(state.channelBottomRef.current)
        } else {
          state?.channelBottomRef?.current?.scrollIntoView()
        }
        return { ...state, waitingForAIResponse: true, currentAIResponse: '', discussionWithAiStarted: true }
      case 'RECEIVED_AI_ANSWER_PART':
        return {
          ...state,
          waitingForAIResponse: true,
          currentAIResponse: `${state.currentAIResponse ?? ''}${action.value}`,
        }
      case 'RECEIVED_AI_RESPONSE':
        return { ...state, waitingForAIResponse: false }
      case 'RECEIVED_AI_ANSWER_ERROR':
        return { ...state, waitingForAIResponse: false, currentAIResponse: '', discussionWithAiStarted: false }
      case 'SELECTED_VIDEO':
        return { ...state, selectedYoutubeVideo: action.value }
      default:
        return state
    }
  }, initialState)

  const handleAssistantDiscussion = async (
    messageId?: string,
    content?: string,
    isAskMyYtChannel = false,
  ): Promise<void> => {
    if (!content || !messageId) return

    const handleAIDiscussionError = (): void => {
      Toast({ message: "Can't anwer your question, please try again or ask a different question.", type: 'error' })
      dispatch({ type: 'RECEIVED_AI_ANSWER_ERROR' })
    }

    dispatch({ type: 'REQUESTED_AI_RESPONSE' })
    replace({ pathname, query: { ...query, messageId } }, undefined, { scroll: false })

    try {
      const response = await stream(isAskMyYtChannel ? '/ai/askYoutubeChannel' : '/ai/addDiscussionAnswer', {
        messageId,
        content,
      })
      const reader = response.body?.pipeThrough(new TextDecoderStream()).getReader()
      while (reader) {
        const { done, value } = await reader.read()
        if (done) break

        if (value === '[ERROR]') {
          handleAIDiscussionError()
        } else {
          dispatch({ type: 'RECEIVED_AI_ANSWER_PART', value })
        }
      }
    } catch (_) {
      handleAIDiscussionError()
    }

    trackEvent(Events.ASKED_QUESTION_TO_AI, withLearningCommunitySlugFromQuery(), withCohortIdFromQuery(), {
      isThreadReply: false,
    })
  }

  useEffect(() => {
    subscribeToMore({
      document: MESSAGES_SUBSCRIPTION,
      updateQuery(_, { subscriptionData }) {
        if (subscriptionData.data?.userEvent?.message?.user?.id === process.env.NEXT_PUBLIC_COLEAP_BOT_USER_ID) {
          dispatch({ type: 'RECEIVED_AI_RESPONSE' })
        }
        if (state.scrollPosition < 0) {
          dispatch({ type: 'NEW_MESSAGES_INDICATOR', value: true })
        }
      },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!data) return <></>

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <SpacesContext.Provider value={{ channel: data.channel, fetchMore, state, dispatch, handleAssistantDiscussion }}>
      {children}
    </SpacesContext.Provider>
  )
}

export const MESSAGE_FRAGMENT = gql`
  fragment MessageFragment on ChannelMessage {
    id
    content
    category
    user {
      id
      firstName
      fullName
      avatarUrl
    }
    attachments {
      id
      url
      type
      createdAt
    }
    reactions {
      content
      users {
        id
        fullName
      }
      total
      viewerStatus {
        reacted
      }
    }
    createdAt
  }
`

const GET_CHANNEL = gql`
  ${MESSAGE_FRAGMENT}
  query GetChannel($channelId: ID!, $last: Int, $before: String) {
    channel(channelId: $channelId) {
      id
      name
      description
      emojiIcon
      type
      messages(last: $last, before: $before) {
        edges {
          node {
            ...MessageFragment
            replies {
              ...MessageFragment
            }
          }
        }
        pageInfo {
          hasPreviousPage
          startCursor
        }
      }
      users {
        id
        avatarUrl
        firstName
        fullName
        jobDescription
      }
      source {
        __typename
        ... on Cohort {
          id
        }
        ... on LearningCommunity {
          id
        }
      }
    }
  }
`

const MESSAGES_SUBSCRIPTION = gql`
  ${MESSAGE_FRAGMENT}
  subscription MessagesSubscription {
    userEvent {
      message {
        ...MessageFragment
        replies {
          ...MessageFragment
        }
      }
    }
  }
`
