import LaunchDarkly, { LDClient } from 'launchdarkly-node-server-sdk'

import { CurrentUserQuery } from '@/graphql/generated'

let launchDarklyClient: LDClient

const initialize = async (): Promise<LDClient> => {
  const client = LaunchDarkly.init(process.env.NEXT_PRIVATE_LAUNCHDARKLY_SDK_KEY ?? '')
  await client.waitForInitialization()
  return client
}

export const getClient = async (): Promise<LDClient> => {
  if (launchDarklyClient) return launchDarklyClient
  return (launchDarklyClient = await initialize())
}

export const getFeatureFlags = async (user: CurrentUserQuery): Promise<{ [key: string]: boolean }> => {
  const ldUser = user.me
    ? {
        key: user.me.id,
        name: user.me.fullName ?? '',
        email: user.me.email,
        custom: {
          learningCommunities: user.me.accounts?.map(({ learningCommunity }) => learningCommunity.slug),
        },
      }
    : {
        key: '',
        anonymous: true,
      }

  const ldClient = await getClient()
  ldClient.identify(ldUser)
  const flags = await ldClient.allFlagsState(ldUser)
  return Object.fromEntries(
    Object.entries(flags.allValues()).map(([key, value]) => [key.replace(/-([a-z])/g, m => m[1].toUpperCase()), value]),
  )
}
