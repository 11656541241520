const SvgIconInfoCircle = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg fill="none" viewBox="0 0 16 16" role="img" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM7 8H6V6h3v5h1v2H7V8Zm2-3V3H7v2h2Z"
      clipRule="evenodd"
    />
  </svg>
)

export default SvgIconInfoCircle
