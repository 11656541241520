import { gql, useQuery } from '@apollo/client'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { PropsWithChildren, useEffect, useState } from 'react'

import { DEFAULT_THEME_COLOR } from '@/constants/Theme'

export default function ThemeWrapper({ children }: PropsWithChildren): JSX.Element {
  const [primaryColor, setPrimaryColor] = useState<string | undefined>()
  const [mounted, setMounted] = useState(false)

  const { query, isReady, asPath } = useRouter()
  const { data } = useQuery(GET_LEARNING_COMMUNITY_LOCALE, {
    skip: !query?.slug || !isReady,
    variables: {
      slug: query?.slug,
    },
  })

  useEffect(() => {
    // Fallback to default when no slug present
    if (!query?.slug) {
      setPrimaryColor(DEFAULT_THEME_COLOR)
      setMounted(true)
      return
    }

    if (!data) return

    const themeColor = data?.learningCommunity?.themeColor

    setPrimaryColor(themeColor ?? DEFAULT_THEME_COLOR)
    setMounted(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, asPath])

  if (!mounted) return <></>

  return (
    <>
      <Head>
        <style>{`
            :root {
              --primary: ${primaryColor};
            }
          `}</style>
      </Head>
      {children}
    </>
  )
}

const GET_LEARNING_COMMUNITY_LOCALE = gql`
  query LearningCommunity($slug: String!) {
    learningCommunity(slug: $slug) {
      id
      themeColor
    }
  }
`
