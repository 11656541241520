const SvgIconCheckCircle = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg fill="none" viewBox="0 0 16 16" role="img" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm4.707-10.293-1.414-1.414L6.5 9.086 4.707 7.293 3.293 8.707 6.5 11.914l6.207-6.207Z"
      clipRule="evenodd"
    />
  </svg>
)

export default SvgIconCheckCircle
