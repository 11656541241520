import classNames from 'classnames'
import toast from 'react-hot-toast'

import { IconAlert, IconCheckCircle, IconInfoCircle } from '@/components/Icons'

type Props = {
  message: string | JSX.Element
  description?: string | JSX.Element
  type: 'error' | 'success' | 'info'
  duration?: number
}

export const Toast = ({ message, description, type, duration = 4000 }: Props): string => {
  const Icon = {
    error: <IconAlert className="icon-md text-red-base" />,
    success: <IconCheckCircle className="icon-md text-green-dark" />,
    info: <IconInfoCircle className="icon-md text-teal-dark" />,
  }?.[type]

  return toast.custom(
    t => (
      <div
        className={classNames(
          'pointer-events-auto flex w-full max-w-sm rounded-lg bg-white shadow-hover',
          t.visible ? 'animate-enter' : 'animate-leave',
        )}
      >
        <div className="w-0 flex-1 px-5 py-4">
          <div className="flex items-center space-x-3">
            <div className="shrink-0">{Icon}</div>

            <div className="flex-1">
              <p className="font-medium text-navy-dark antialiased">{message}</p>
              {description ? <p className="text-grey-base antialiased">{description}</p> : null}
            </div>
          </div>
        </div>
      </div>
    ),
    {
      duration,
    },
  )
}

export default Toast
