import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { t } from '@lingui/macro'
import { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Toast } from '@/components'
import { Events } from '@/constants'
import { useCurrentUserContext } from '@/contexts/CurrentUserContext'
import { MESSAGE_FRAGMENT } from '@/contexts/SpaceContext'
import {
  Channel,
  ChannelMessageAttachment,
  ChannelMessageAttachmentInput,
  ChannelMessageInput,
  Maybe,
  Mutation,
  PageInfo,
  Query,
} from '@/graphql/generated'

import useRoutes from '../useRoutes'
import useTracking from '../useTracking'

export interface ReturnType {
  isCreatingMessage: boolean
  isLoadingChannel: boolean
  channel?: Maybe<Channel>
  handleSendMessage: (
    data?: string | ChannelMessageInput,
    attachments?: ChannelMessageAttachmentInput,
  ) => Promise<string>
  pageInfo?: PageInfo | undefined
}

const PAGE_SIZE = 20

const useChannels = (): ReturnType => {
  const {
    router: { query },
  } = useRoutes()

  const channelId = query.channelId
  const userContext = useCurrentUserContext()
  const { trackEvent } = useTracking()

  const [fetchChannel, { data: channelData, loading: isLoadingChannel, error: fetchChannelError }] = useLazyQuery<
    Pick<Query, 'channel'>
  >(GET_CHANNEL, {
    onCompleted: data => {
      userContext?.setUnreadChannelsCount(data?.channel?.viewer?.user?.unreadChannelsCount ?? 0)
    },
    fetchPolicy: 'cache-and-network',
  })

  const [createMessage, { loading: isCreatingMessage }] = useMutation<Pick<Mutation, 'sendChannelMessage'>>(
    CREATE_MESSAGE,
    {
      onCompleted: data => {
        if (!data?.sendChannelMessage?.errors) {
          trackEvent(Events.SENT_MESSAGE_IN_CHANNEL, {
            channel: {
              type: data.sendChannelMessage?.message?.channel?.type,
              id: data.sendChannelMessage?.message?.channel?.id,
              numberOfUsers: data.sendChannelMessage?.message?.channel?.users?.length,
            },
            message: {
              attachments: data.sendChannelMessage?.message?.attachments?.map(attachment => attachment.type),
            },
          })
        } else {
          Toast({
            message: t`Something went wrong. Try again later.`,
            type: 'error',
          })
        }
      },
    },
  )

  useEffect(() => {
    fetchChannel({
      variables: {
        channelId,
        last: PAGE_SIZE,
      },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId])

  const handleSendMessage = async (
    content?: string | ChannelMessageInput,
    attachments?: ChannelMessageAttachmentInput | ChannelMessageAttachmentInput[] | undefined,
  ): Promise<string> => {
    const messageId = uuidv4()
    const parsedContent =
      typeof content === 'object'
        ? {
            ...content,
          }
        : { content }

    await createMessage({
      variables: {
        channelId,
        attributes: {
          ...parsedContent,
          attachments,
          id: messageId,
        },
      },
      optimisticResponse: {
        sendChannelMessage: {
          message: {
            id: messageId,
            ...parsedContent,
            attachments: (attachments as Maybe<ChannelMessageAttachment[]> | undefined) ?? null,
            __typename: 'ChannelMessage',
            reactions: [],
            createdAt: new Date().toISOString(),
            user: userContext?.currentUser?.me,
            channel: channelData?.channel,
            replies: [],

            // content.category might be undefined (send regular message from Spaces)
            // We need to have category value so Apollo can optimistically render the message
            category: typeof content === 'object' ? (content.category ? content.category : null) : null,
          },
          errors: null,
        },
      },
    })

    return messageId
  }

  return {
    isCreatingMessage,
    isLoadingChannel: (isLoadingChannel && !channelData && !fetchChannelError) || !!query.user_id,
    channel: channelId ? channelData?.channel : undefined,
    handleSendMessage,
    pageInfo: channelData?.channel?.messages?.pageInfo,
  }
}

export const GET_CHANNELS = gql`
  ${MESSAGE_FRAGMENT}

  query GetChannels($type: [ChannelTypeEnum!]) {
    channels(type: $type) {
      edges {
        node {
          id
          type
          name
          messages(last: 1) {
            pageInfo {
              startCursor
              hasPreviousPage
            }
            edges {
              node {
                ...MessageFragment
              }
            }
          }
          users {
            id
            fullName
            avatarUrl
            firstName
          }
          viewer {
            hasUnreadMessages
          }
        }
      }
    }
  }
`
export const GET_CHANNEL = gql`
  ${MESSAGE_FRAGMENT}
  query GetChannel($last: Int, $before: String, $channelId: ID!) {
    channel(channelId: $channelId) {
      id
      type
      name
      messages(last: $last, before: $before) {
        pageInfo {
          startCursor
          hasPreviousPage
        }
        edges {
          node {
            ...MessageFragment
            replies {
              ...MessageFragment
            }
          }
        }
      }
      users {
        id
        jobDescription
        fullName
        avatarUrl
        firstName
        timezone
        location
        pronouns
      }
      viewer {
        user {
          unreadChannelsCount
        }
        hasUnreadMessages
      }
    }
  }
`

const CREATE_MESSAGE = gql`
  mutation SendChannelMessage($channelId: ID!, $attributes: CreateChannelMessageInput!) {
    sendChannelMessage(channelId: $channelId, attributes: $attributes) {
      message {
        id
        content
        createdAt
        attachments {
          createdAt
          id
          type
          url
        }
        user {
          id
          fullName
          avatarUrl
          firstName
        }
        reactions {
          content
          users {
            id
            fullName
          }
          total
          viewerStatus {
            reacted
          }
        }
        replies {
          id
          user {
            id
            avatarUrl
            fullName
          }
        }
        channel {
          id
          type
          users {
            id
          }
        }
        category
      }
      errors {
        field
        message
      }
    }
  }
`

export default useChannels
