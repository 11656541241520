const SvgIconAlert = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg fill="none" viewBox="0 0 16 15" role="img" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 15H0v-3L7 0h2l7 12v3ZM7 4h2v5H7V4Zm0 7h2v2H7v-2Z"
      clipRule="evenodd"
    />
  </svg>
)

export default SvgIconAlert
