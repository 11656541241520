import classNames from 'classnames'
import { createElement, SyntheticEvent } from 'react'

import { Spinner } from '..'
import { IconExternalLink } from '../Icons'

type Props = {
  block?: boolean
  className?: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'link'
  icon?: React.ComponentType<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>
  iconPosition?: 'left' | 'right'
  loading?: boolean
  onClick?: (e: SyntheticEvent) => void
  size?: 'xs' | 'sm' | 'md' | 'lg'
  variant?: 'primary' | 'secondary' | 'inverted' | 'inline' | 'danger' | 'ghost'
  noWrap?: boolean
  href?: string
  excludeIcon?: boolean
  newTab?: boolean
  shouldUnderlineLink?: boolean
  lightText?: boolean
  align?: 'start' | 'center' | 'end'
}

const Button: React.FC<React.PropsWithChildren<Props>> = ({
  block,
  children,
  disabled,
  type = 'button',
  icon: Icon,
  iconPosition = 'left',
  loading,
  onClick,
  size = 'md',
  variant = 'secondary',
  className,
  noWrap = false,
  excludeIcon = false,
  newTab = false,
  shouldUnderlineLink = true,
  align = 'center',
  lightText = false,
  ...props
}) => {
  const fontSizes = {
    xs: 'text-base-none',
    sm: 'text-base-none',
    md: 'text-base-none',
    lg: 'text-lg-none',
  }
  const variantClasses = {
    primary: 'text-white bg-purple-base hover:bg-purple-dark',
    secondary: classNames(
      'bg-white ring-1 ring-grey-extra-light shadow-btn-white hover:ring-grey-light',
      lightText ? 'text-grey-dark' : 'text-navy-dark',
    ),
    inverted: 'text-purple-base bg-purple-bright hover:bg-purple-extra-light',
    translucent: 'text-white bg-white/[.1] hover:bg-white/[.2]',
    danger: 'text-white bg-red-base hover:bg-red-dark',
    ghost: lightText ? 'text-grey-dark' : 'text-navy-dark',
    inline: 'inline',
  }

  const buttonSizes = {
    xs: 'px-3 py-1 h-8 rounded-sm',
    sm: 'px-4 py-2 h-10 rounded-md',
    md: 'px-4 py-3 h-12 rounded-base',
    lg: 'px-8 py-[18px] h-[60px] rounded-lg',
  }

  const classes = {
    container: classNames(
      'font-sans capsize font-medium',
      fontSizes[size],
      variantClasses[variant],
      variant !== 'inline' ? buttonSizes[size] : '',
      {
        'w-full': block,
        'pointer-events-none cursor-not-allowed opacity-60': disabled,
        underline: variant === 'inline' && type === 'link' && shouldUnderlineLink,
        'whitespace-nowrap': noWrap,
      },
      className,
    ),

    content: classNames('flex items-center', fontSizes[size], {
      'space-x-[8px]': (Icon || loading || type === 'link') && children && !excludeIcon,
      'items-center': variant !== 'inline',
      'justify-start': align === 'start',
      'justify-center': align === 'center',
      'justify-end': align === 'end',
    }),

    iconSize: classNames({
      'w-4 h-4': size === 'sm' || size === 'md',
      'w-6 h-6': size === 'lg',
    }),

    addonBefore: classNames('relative', fontSizes[size]),
    children: classNames('font-sans', Icon ? 'capsize' : ''),
  }

  const renderIcon = (): JSX.Element | undefined => {
    return Icon && !loading ? <Icon className={classes.iconSize} /> : undefined
  }

  const content = (
    <div className={classes.content}>
      <div className={classes.addonBefore}>
        {loading ? <Spinner ghost /> : null}

        {iconPosition === 'left' ? renderIcon() : null}
        {type === 'link' && !excludeIcon ? <IconExternalLink className={classes.iconSize} /> : null}
      </div>

      <div className={classes.children}>{children}</div>

      {iconPosition === 'right' ? renderIcon() : null}
    </div>
  )

  const commonProps = {
    className: classes.container,
    onClick,
    ...props,
  }
  const component =
    type === 'link'
      ? createElement(
          'a',
          {
            ...commonProps,
            className: `${commonProps.className} flex ${variant === 'inline' ? 'items-start' : 'items-center'}`,
            role: 'button',
            ...(newTab ? { target: '_blank', rel: 'noreferrer' } : null),
          },
          content,
        )
      : createElement('button', { ...commonProps, type }, content)

  return <>{component}</>
}

export default Button
