import { ApolloClient, createHttpLink, from, InMemoryCache, NormalizedCacheObject, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import { datadogLogs } from '@datadog/browser-logs'
import * as Ably from 'ably'
import AblyLink from 'graphql-ruby-client/subscriptions/AblyLink'
import Cookies from 'js-cookie'

import { DatadogEvents } from '@/constants'
import { Storage } from '@/constants/Storage'
import { ErrorCodeEnum } from '@/graphql/generated'

const ablyClient = new Ably.Realtime({ key: process.env.NEXT_PUBLIC_ABLY_API_KEY, clientId: 'test' })
const ablyLink = new AblyLink({ ably: ablyClient })

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Cookies.get(Storage.API_TOKEN)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const errorLink = onError(({ graphQLErrors, operation }) => {
  if (graphQLErrors && typeof window !== 'undefined') {
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions?.code === ErrorCodeEnum.AuthError) {
        datadogLogs.logger.error(DatadogEvents.ACCESS_DENIED, {
          operationName: operation.operationName,
          variables: operation.variables,
        })
      }
    })
  }
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)

    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  ablyLink,
  httpLink,
)

const client = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  link: from([errorLink, authLink, splitLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: true,
    typePolicies: {
      Feed: {
        fields: {
          entries: {
            keyArgs: ['filters'],
            merge(existing = {}, incoming, { args }) {
              if (args?.after) {
                return {
                  edges: [...existing.edges, ...incoming.edges],
                  pageInfo: incoming.pageInfo,
                }
              }

              return incoming
            },
          },
        },
      },
      LearningCommunity: {
        fields: {
          events: {
            keyArgs: ['to', 'from'],
            merge(existing = {}, incoming, { args }) {
              if (args?.after) {
                return {
                  edges: [...existing.edges, ...incoming.edges],
                  pageInfo: incoming.pageInfo,
                }
              }

              return incoming
            },
          },
        },
      },
      Cohort: {
        fields: {
          fellows: {
            keyArgs: false,
            merge(existing = {}, incoming, { args }) {
              if (args?.after) {
                return {
                  ...incoming,
                  pageInfo: incoming.pageInfo,
                  edges: [...(existing?.edges ?? []), ...incoming.edges],
                }
              }

              return incoming
            },
          },
        },
      },
      Channel: {
        fields: {
          messages: {
            keyArgs: false,
            merge(existing = {}, incoming, { args }) {
              if (args?.last && args.last === 1 && existing.edges?.length > incoming.edges?.length) {
                return existing
              }

              if (args?.before) {
                return {
                  edges: [...incoming.edges, ...(existing?.edges ?? [])],
                  pageInfo: incoming.pageInfo,
                }
              }

              return incoming
            },
          },
          viewer: {
            merge(existing = {}, incoming) {
              return {
                ...existing,
                ...incoming,
              }
            },
          },
        },
      },
    },
    possibleTypes: {
      LearningPathBlockInterface: [
        'LearningPathLiveSessionBlock',
        'LearningPathAssignmentBlock',
        'LearningPathContentBlack',
      ],
    },
  }),
})

export const getSSRClient = (token: string): ApolloClient<NormalizedCacheObject> => {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: token.length ? `Bearer ${token}` : '',
      },
    }
  })
  client.setLink(from([errorLink, authLink, httpLink]))

  return client
}

export default client
