export enum TextStyle {
  REGULAR_3XL_NONE = 'text-3xl-none', // 48x48

  MEDIUM_3XL_NORMAL = 'font-medium text-3xl', // 48x56

  REGULAR_2XL_NORMAL = 'text-2xl', // 32x40
  REGULAR_2XL_SNUG = 'text-2xl-snug', // 32x36
  REGULAR_2XL_NONE = 'text-2xl-none', // 32x32

  MEDIUM_2XL_NORMAL = 'font-medium text-2xl', // 32x40
  MEDIUM_2XL_SNUG = 'font-medium text-2xl-snug', // 32x36
  MEDIUM_2XL_NONE = 'font-medium text-2xl-none', // 32x32

  REGULAR_XL_NORMAL = 'text-xl', // 24x32
  REGULAR_XL_SNUG = 'text-xl-snug', // 24x28

  MEDIUM_XL_NORMAL = 'font-medium text-xl', // 24x32
  MEDIUM_XL_SNUG = 'font-medium text-xl-snug', // 24x28

  REGULAR_LG_RELAXED = 'text-lg-relaxed', // 18x32
  REGULAR_LG_NORMAL = 'text-lg', // 18x24
  REGULAR_LG_NONE = 'text-lg-none', // 18x18

  MEDIUM_LG_RELAXED = 'font-medium text-lg-relaxed', // 18x32
  MEDIUM_LG_NORMAL = 'font-medium text-lg', // 18x24
  MEDIUM_LG_NONE = 'font-medium text-lg-none', // 18x18

  REGULAR_BASE_NORMAL = 'text-base', // 16x24
  REGULAR_BASE_NONE = 'text-base-none', // 16x16

  MEDIUM_BASE_NORMAL = 'font-medium text-base', // 16x24

  REGULAR_SM_NORMAL = 'text-sm', // 14x24
  REGULAR_SM_SNUG = 'text-sm-snug', // 14x20
  REGULAR_SM_TIGHT = 'text-sm-tight', // 14x18
  REGULAR_SM_NONE = 'text-sm-none', // 14x14

  MEDIUM_SM_SNUG = 'font-medium text-sm-snug', // 14x20

  REGULAR_XS_NORMAL = 'text-xs', // 10x18
}
