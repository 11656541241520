import { ApolloClient } from '@apollo/client'

import { CurrentUserDocument, Query } from '@/graphql/generated'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchUser = async (client: ApolloClient<Record<string, any>>): Promise<Pick<Query, 'me'>> => {
  try {
    const { data } = await client.query<Pick<Query, 'me'>>({
      query: CurrentUserDocument,
      fetchPolicy: 'no-cache',
    })
    return data
  } catch (_) {
    return {
      me: null,
    }
  }
}

export default fetchUser
