import { t } from '@lingui/macro'
import * as Sentry from '@sentry/nextjs'
import Link from 'next/link'
import React, { Component, ReactNode } from 'react'

import { Card, Heading, Space } from '..'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error): void {
    // invoke errorService and provide error details
    Sentry.captureException(error)
  }

  public resetError = (): void => this.setState({ hasError: false })

  public render(): ReactNode {
    if (this.state.hasError) {
      return (
        <div className="mt-24">
          <div className="mx-auto sm:w-8/12 md:w-6/12 lg:w-4/12">
            <Card justify="center">
              <Space y={6}>
                <Heading level={1} variant={TextStyle => TextStyle.REGULAR_LG_NORMAL}>
                  {t`Oops, something went wrong`}
                </Heading>
              </Space>

              <Space y={12} />

              <Link href="/" passHref className="block rounded-base px-4 py-2 text-lg hover:bg-grey-light">
                {t`Go to Home`}
              </Link>
            </Card>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
