import Image from 'next/legacy/image'

export type AvatarSizes = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '48'

type Props = {
  url: string
  name?: string
  size?: AvatarSizes
  bordered?: boolean
}

export const getWidthAndHeight = (size: AvatarSizes): number => {
  switch (size) {
    case 'sm':
      return 16
    case 'md':
      return 24
    case 'lg':
      return 32
    case 'xl':
      return 40
    case '2xl':
      return 64
    case '3xl':
      return 80
    case '4xl':
      return 120
    case '48':
      return 48
  }
}

const Avatar: React.FC<React.PropsWithChildren<Props>> = ({ url, name, size = 'sm', bordered = false }) => {
  const widthAndHeight = getWidthAndHeight(size)

  const AvatarImage: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ ...props }) => (
    <Image
      {...props}
      src={url}
      title={name}
      alt={name ?? ''}
      width={widthAndHeight}
      height={widthAndHeight}
      objectFit="cover"
    />
  )

  return bordered ? (
    <div
      className={`overflow-hidden rounded-full ring-4 ring-white`}
      style={{ width: widthAndHeight, height: widthAndHeight }}
    >
      <AvatarImage />
    </div>
  ) : (
    <div className="leading-[0]">
      <AvatarImage className="rounded-full" />
    </div>
  )
}

export default Avatar
