import classNames from 'classnames'
import { Url } from 'next/dist/shared/lib/router/router'
import Link from 'next/link'

type Props = {
  href: Url | string
  isActive?: boolean
  icon?: React.ComponentType<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>> | JSX.Element
  label: string
  indicator?: boolean
}

export default function MainMenuItem({ href, isActive, icon: Icon, label, indicator }: Props): JSX.Element {
  return (
    <Link
      href={href}
      passHref
      className={classNames(
        'relative flex items-center space-x-3 rounded-sm px-3 py-1 text-sm',
        isActive ? 'bg-grey-bright text-navy-dark' : 'text-grey-dark hover:bg-grey-bright hover:text-navy-dark',
      )}
    >
      <div>{typeof Icon === 'object' ? Icon : Icon ? <Icon className="h-4 w-4" /> : null}</div>

      <div className="grow truncate">{label}</div>

      {indicator ? (
        <div>
          <div
            className={classNames('h-2 w-2 rounded-full bg-green-base ring', isActive ? 'ring-white' : 'ring-white')}
          />
        </div>
      ) : null}
    </Link>
  )
}
