import { gql, Reference, useApolloClient, useSubscription } from '@apollo/client'
import { dispatch } from 'use-bus'

import { Routes, UseBusEvents } from '@/constants'
import { GET_DIRECT_CHANNELS } from '@/containers/Layouts/Learner/DirectMessages'
import { Channel, ChannelConnection, ChannelMessage, ChannelTypeEnum, Query, Subscription } from '@/graphql/generated'

import { GET_CHANNEL, GET_CHANNELS } from '../useChannels'
import useRoutes from '../useRoutes'

interface ArgumentsType {
  currentUser: Pick<Query, 'me'> | undefined
  increaseUnreadChannelsCount: () => void
}

const useRealtime = ({ currentUser, increaseUnreadChannelsCount }: ArgumentsType): void => {
  const {
    router: { query, pathname },
  } = useRoutes()

  const isSpace = pathname.includes(Routes.SPACE) || pathname.includes('/[slug]/ask')
  const channelId = typeof query.channelId === 'object' ? query.channelId?.[0] : query.channelId
  const client = useApolloClient()

  useSubscription<Pick<Subscription, 'userEvent'>>(USER_EVENT_SUBSCRIPTION, {
    onData: event => {
      const message = event?.data?.data?.userEvent?.message
      const channel = event.data.data?.userEvent.channel

      if (!message && !channel) return

      if (message) {
        handleNewMessage(message)
      } else if (channel) {
        addNewChannelToCache(channel)
      }
    },
    skip: !currentUser?.me,
  })

  const handleNewMessage = (message: ChannelMessage): void => {
    dispatch({
      type: UseBusEvents.NEW_MESSAGE_RECEIVED,
      payload: message,
    })

    const isMyMsg = currentUser?.me?.id === message.user?.id
    const channelsQuery = getChannelsFromCache()
    const isMessageFromCurrentChannel = channelId && channelId === message.channel?.id
    const channel = channelsQuery?.channels?.edges?.find(channel => channel?.node?.id === message?.channel?.id)
    const isDirectChannel = message.channel?.type === ChannelTypeEnum.Direct

    if (isSpace) {
      if (isMessageFromCurrentChannel) {
        updateChannelCacheAfterNewMessage(message)
      } else if (!isMyMsg && !channel?.node?.viewer?.hasUnreadMessages && isDirectChannel) {
        increaseUnreadChannelsCount()
      }

      updateChannelsCacheAfterNewMessage(message, !isMyMsg && !isMessageFromCurrentChannel)
    } else {
      if (channelsQuery) {
        if (!channel?.node?.viewer?.hasUnreadMessages && isDirectChannel) {
          increaseUnreadChannelsCount()
        }

        updateChannelsCacheAfterNewMessage(message, !isMyMsg)
      } else if (!isMyMsg && isDirectChannel) {
        // ending up in this branch means user didn't visit inbox in the current session
        // ideal solution would be to always rely on the API response channel.viewer.user.unreadChannelsCount or hasUnreadMessages but at the moment API returns stale data
        // we need to optimistically increase unreadChannelsCount by 1 because we don't know if the channel is read or not
        // in this case scenario we'll have a small bug - every time we receive new message we'll increase unreadChannelsCount by 1
        // when user navigates to inbox and visit this channel, proper unreadChannelsCount will be loaded and displayed
        increaseUnreadChannelsCount()
      }
    }
  }

  const updateMessageReplies = (message: ChannelMessage): void => {
    client.cache.modify({
      id: client.cache.identify({
        id: message.parent?.id,
        __typename: 'ChannelMessage',
      }),
      fields: {
        replies: (existingMessagesRefs = [], { readField }) => {
          const newMessageRef = client.cache.writeFragment({
            data: message,
            fragment: gql`
              fragment Message on ChannelMessage {
                id
              }
            `,
          })

          if (existingMessagesRefs.some((ref: Reference) => readField('id', ref) === message.id)) {
            return existingMessagesRefs
          }

          return [...existingMessagesRefs, newMessageRef]
        },
      },
    })
  }

  const updateChannelCacheAfterNewMessage = (message: ChannelMessage): void => {
    const channelQuery: { channel: Channel } | null = client.readQuery({
      query: GET_CHANNEL,
      variables: {
        channelId: message.channel?.id,
      },
    })

    const isAlreadyInCache =
      channelQuery?.channel.messages.edges?.find(messageInCache => messageInCache?.node?.id === message.id) ||
      channelQuery?.channel.messages.edges?.find(messageInCache =>
        messageInCache?.node?.replies?.find(reply => reply.id === message.id),
      )

    if (message.isReply) {
      updateMessageReplies(message)
    } else if (!isAlreadyInCache) {
      client.cache.modify({
        id: client.cache.identify({
          id: message.channel?.id,
          __typename: 'Channel',
        }),
        fields: {
          messages: (existingMessages: ChannelConnection) => {
            const newMessageRef = client.cache.writeFragment({
              data: message,
              fragment: gql`
                fragment Message on ChannelMessage {
                  id
                }
              `,
            })

            return {
              ...existingMessages,
              edges: [
                ...(existingMessages?.edges ?? []),
                {
                  node: newMessageRef,
                  __typename: 'ChannelMessageEdge',
                },
              ],
            }
          },
          updatedAt: () => new Date(),
        },
      })
    }
  }

  const addNewChannelToCache = (channel: Channel): void => {
    const isDirectChannel = channel?.type === ChannelTypeEnum.Direct
    const query = isDirectChannel ? GET_DIRECT_CHANNELS : GET_CHANNELS
    const channelsQuery = getChannelsFromCache(query)

    const isAlreadyInCache = !!channelsQuery?.channels?.edges?.find(
      channelInCache => channelInCache?.node?.id === channel?.id,
    )

    if (!channel || isAlreadyInCache || !channelsQuery) return

    client.cache.writeQuery({
      query: query,
      data: {
        channels: {
          ...channelsQuery?.channels,
          edges: [
            {
              node: channel,
              __typename: 'ChannelEdge',
            },
            ...(channelsQuery?.channels?.edges ?? []),
          ],
        },
      },
    })
  }

  const updateChannelsCacheAfterNewMessage = (message: ChannelMessage, isUnreadChannel: boolean): void => {
    const channelsQuery = getChannelsFromCache()

    let updatedChannelIndex = 0

    const updatedChannels = {
      ...channelsQuery?.channels,
      edges: channelsQuery?.channels?.edges?.map((channelConnection, index) => {
        if (channelConnection?.node?.id === message.channel?.id) {
          updatedChannelIndex = index

          return {
            ...channelConnection,
            node: {
              ...(channelConnection?.node ?? {}),
              messages: {
                edges: [
                  {
                    node: message,
                  },
                ],
              },
              viewer: {
                ...message.channel?.viewer,
                hasUnreadMessages: isUnreadChannel,
              },
            },
          }
        }

        return channelConnection
      }),
    }

    if (updatedChannelIndex && updatedChannels.edges?.length) {
      const updatedChannel = updatedChannels.edges?.[updatedChannelIndex]

      updatedChannels.edges?.splice(updatedChannelIndex, 1)
      updatedChannels.edges?.unshift(updatedChannel)
    }

    client.writeQuery({
      query: GET_CHANNELS,
      data: {
        channels: updatedChannels,
      },
    })
  }

  const getChannelsFromCache = (query = GET_CHANNELS): { channels: ChannelConnection } | null => {
    const channelsQuery: { channels: ChannelConnection } | null = client.readQuery({
      query,
    })

    return channelsQuery
  }
}

const USER_EVENT_SUBSCRIPTION = gql`
  subscription UserEvent {
    userEvent {
      message {
        id
        content
        createdAt
        isReply
        parent {
          id
        }
        attachments {
          createdAt
          id
          type
          url
        }
        user {
          id
          fullName
          avatarUrl
          firstName
        }
        channel {
          id
          type
          name
          viewer {
            hasUnreadMessages
          }
          updatedAt
        }
      }
      channel {
        id
        type
        name
        messages {
          edges {
            node {
              id
              content
              createdAt
              attachments {
                createdAt
                id
                type
                url
              }
              user {
                id
                fullName
                avatarUrl
                firstName
              }
            }
          }
        }
        users {
          id
          jobDescription
          fullName
          avatarUrl
          firstName
          timezone
          location
          pronouns
        }
        viewer {
          hasUnreadMessages
        }
      }
    }
  }
`

export default useRealtime
