import classNames from 'classnames'
import { createElement, ReactNode } from 'react'

import { TextStyle } from '@/constants/TextStyle'

enum Breakpoints {
  default = 'default',
  md = 'md',
}

type VariantOptions = {
  [key in Breakpoints]?: TextStyle
}

type Props = {
  level: 1 | 2 | 3 | 4 | 5 | 6
  variant: (arg: typeof TextStyle) => TextStyle | VariantOptions
  className?: string
  capsize?: boolean
}

const Heading: React.FC<React.PropsWithChildren<Props>> = ({ level, children, variant, className, capsize }) => {
  // Depending if `variant` is an object or a `TextStyle` we need to build the classes accordingly
  const variantValue = variant(TextStyle)
  const variantClasses =
    typeof variantValue === 'object'
      ? Object.keys(variantValue)
          .map(breakpointKey => {
            const breakpointValue = variantValue[breakpointKey as Breakpoints]

            if (breakpointKey === Breakpoints.default) {
              return breakpointValue
            }

            return `${breakpointKey}:${breakpointValue}`
          })
          .flat()
          .join(' ')
      : variantValue

  const classes = classNames(variantClasses, className, {
    'font-sans capsize': capsize,
  })

  const create = (el: string): ReactNode => createElement(el, { className: classes }, children)
  const component = create(`h${level}`)

  return <>{component}</>
}

export default Heading
