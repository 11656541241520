export enum Routes {
  LOGIN = '/auth/login',
  ONBOARDING = '/onboarding',
  SIGN_UP = '/auth/sign-up',
  RESET_PASSWORD = '/auth/reset-password',
  LEARNING_COMMUNITIES = '/',
  INBOX = '/inbox',

  // Routes per Learning Community
  ANALYTICS = '/analytics',
  APPLICATION_FORM = '/manage/application-form',
  APPLY = '/apply',
  COMMUNITY = '/community',
  CRM = '/manage/crm',
  WAITING_LIST = '/manage/waiting-list',
  INTEGRATIONS = '/manage/integrations',
  CREATOR_ONBOARDING = '/manage/onboarding',
  DASHBOARD = '/dashboard',
  EVENTS = '/events',
  FEED = '/feed',
  JOIN = '/join',
  LEARN = '/learn',
  PROFILE = '/profile',
  RESOURCES = '/resources',
  SETTINGS = '/manage/settings',
  SPACE = '/space',
  SUCCESSFUL_APPLY = '/apply/success',
  TEAM = '/manage/team',
  WELCOME_CREATOR = '/manage/welcome',
  MANAGEMENT = '/manage',

  // Creator views per Cohort
  LEARNING_PATH = '/learning-path',
  EMAIL_TEMPLATES = '/email-templates',
  SUBMISSIONS = '/submissions',
}
