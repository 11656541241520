import { t } from '@lingui/macro'

import { IconBook, IconCheckCircle, IconFlag, IconVideo } from '@/components/Icons'
import { ChannelMessageCategoryEnum, LearningPathBlockTypeEnum } from '@/graphql/generated'

type BlockTypesInfoType = {
  [key: string]: {
    icon: React.ComponentType<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>
    iconOutlined?: React.ComponentType<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>
    name: string
    description: string
    classNames?: string
  }
}

export function useBlockTypeInfo(): BlockTypesInfoType {
  const BlockTypesInfo: BlockTypesInfoType = {
    [LearningPathBlockTypeEnum.Livesession]: {
      icon: IconVideo,
      name: t`Live session`,
      description: t`Video meetings for the learning community`,
    },
    [LearningPathBlockTypeEnum.Content]: {
      icon: IconBook,
      name: t`Content`,
      description: t`Curated material or resources learners will read or watch at their own pace`,
    },
    [LearningPathBlockTypeEnum.Assignment]: {
      icon: IconCheckCircle,
      name: t`Task`,
      description: t`Task for learners to complete`,
    },
    [LearningPathBlockTypeEnum.Milestone]: {
      icon: IconFlag,
      name: t`Milestone`,
      description: t`The goal learners are aiming for each week (in the case of Deep Dives, within every 1-2 days)`,
    },
  }

  return BlockTypesInfo
}

export const LEARNER_ONBOARDING_ABOUT_ME_MIN_LENGTH = 2

export const BLOCK_TITLE_MIN_LENGTH = 2

export const BLOCK_TITLE_MAX_LENGTH = 80

export const LEARN_MORE_LINK_LIVE_SESSION =
  'https://noble-discovery-518.notion.site/Live-Session-Blocks-735b3057ef884c3a82b2724f6dec03d4'

export const AVATAR_GROUP_VISIBLE_IMAGES_LIMIT = 3

export const CohortPostCategories: {
  [key: string]: {
    name: string
    availableForLearner: boolean
  }
} = {
  POST: {
    name: 'Post',
    availableForLearner: true,
  },
  QUESTION: {
    name: 'Question',
    availableForLearner: true,
  },
  OPPORTUNITY: {
    name: 'Opportunity',
    availableForLearner: true,
  },
  EVENT: {
    name: 'Event',
    availableForLearner: true,
  },
  ANNOUNCEMENT: {
    name: 'Announcement',
    availableForLearner: false,
  },
}

export function useSpacesMessagePlaceholder(): { [key: string]: string } {
  const SpacesMessagePlaceholder: { [key: string]: string } = {
    [ChannelMessageCategoryEnum.Announcement]: t`What would you like to announce? Posting as an announcement increases the visibility of your message`,
    [ChannelMessageCategoryEnum.Question]: t`What’s your question? Posting your message as a question increases the likelihood of a response`,
    [ChannelMessageCategoryEnum.Resource]: t`Add the link to your resource here...`,
  }

  return SpacesMessagePlaceholder
}

export const NUMBER_OF_DAYS_BEFORE_SECTION_TO_SHOW_AVAILABILITY_BANNER = 4
