import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A valid ISO8601 datetime format in UTC timezone */
  DateTimeUtc: any;
  /** A timeslot containing the hour and minute in HH:MM format */
  HourAndMinute: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
};

/** Autogenerated return type of AddApplicantComment. */
export type AddApplicantCommentPayload = {
  __typename?: 'AddApplicantCommentPayload';
  comment?: Maybe<ApplicantComment>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of AddCommentToBlock. */
export type AddCommentToBlockPayload = {
  __typename?: 'AddCommentToBlockPayload';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of AddCommentToCohortPost. */
export type AddCommentToCohortPostPayload = {
  __typename?: 'AddCommentToCohortPostPayload';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of AddCommentToCohortProfileIntroduction. */
export type AddCommentToCohortProfileIntroductionPayload = {
  __typename?: 'AddCommentToCohortProfileIntroductionPayload';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of AddCommentToEvent. */
export type AddCommentToEventPayload = {
  __typename?: 'AddCommentToEventPayload';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of AddCommentToFeedbackRequest. */
export type AddCommentToFeedbackRequestPayload = {
  __typename?: 'AddCommentToFeedbackRequestPayload';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of AddFavoritePeer. */
export type AddFavoritePeerPayload = {
  __typename?: 'AddFavoritePeerPayload';
  errors?: Maybe<Array<UserError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of AddReplyToChannelMessage. */
export type AddReplyToChannelMessagePayload = {
  __typename?: 'AddReplyToChannelMessagePayload';
  errors?: Maybe<Array<UserError>>;
  message?: Maybe<ChannelMessage>;
};

/** Autogenerated return type of AddReplyToComment. */
export type AddReplyToCommentPayload = {
  __typename?: 'AddReplyToCommentPayload';
  commentReply?: Maybe<Comment>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of AddResponseToFeedbackRequest. */
export type AddResponseToFeedbackRequestPayload = {
  __typename?: 'AddResponseToFeedbackRequestPayload';
  errors?: Maybe<Array<UserError>>;
  feedbackResponse?: Maybe<MilestoneSubmissionFeedbackResponse>;
};

/** Available types of content a learner can submit for a milestone step */
export enum AllowedSubmissionTypesEnum {
  /** External submission type */
  External = 'EXTERNAL',
  /** Inline user input via Coleap editor */
  Inline = 'INLINE'
}

export type Applicant = {
  __typename?: 'Applicant';
  /** Answers provided in application form */
  answers: Array<ApplicantFormAnswer>;
  /** Cohort the applicant belongs to */
  cohort: Cohort;
  /** List of comments for the applicant */
  comments: Array<ApplicantComment>;
  /** Number of comments made on the applicant */
  commentsCount: Scalars['Int'];
  id: Scalars['ID'];
  /** Invoice link */
  invoiceLink?: Maybe<Scalars['String']>;
  /** Date of the last status change */
  lastStatusChangeDate: Scalars['ISO8601Date'];
  /** Payment link */
  paymentLink?: Maybe<Scalars['String']>;
  /** Information about Applicant Status */
  status: ApplicantStatus;
  /** User associated with the applicant */
  user: User;
};

export type ApplicantComment = {
  __typename?: 'ApplicantComment';
  /** Applicant that received the comment */
  applicant: Applicant;
  /** Status of the applicant when comment was made */
  atStatus: ApplicantStatusOption;
  /** Content of the comment */
  content: Scalars['String'];
  /** When the comment was made */
  createdAt: Scalars['ISO8601DateTime'];
  /** ID of the comment */
  id: Scalars['ID'];
  /** User that made the comment */
  user: User;
};

export type ApplicantFormAnswer = {
  __typename?: 'ApplicantFormAnswer';
  /** Answer provided by applicant */
  answer: Scalars['String'];
  /** Text of the question */
  question: Scalars['String'];
};

/** Attributes for submitting an answer in the applicant form */
export type ApplicantFormAnswerInput = {
  /** Answer to the question */
  answer?: Maybe<Scalars['String']>;
  /** Question text at time of answer */
  question: Scalars['String'];
  questionId: Scalars['ID'];
};

export type ApplicantFormQuestion = {
  __typename?: 'ApplicantFormQuestion';
  /** ID of the question */
  id: Scalars['ID'];
  /** If the question is mandatory or not */
  isRequired: Scalars['Boolean'];
  /** Position the question should have in the UI */
  position: Scalars['Int'];
  /** Text of the question */
  question: Scalars['String'];
};

/** Attributes for creating a new applicant */
export type ApplicantInput = {
  /** If the applicant accepts to be contacted directly by the creator */
  acceptedCreatorReachOutOptIn?: Maybe<Scalars['Boolean']>;
  /** Answers to the form questions */
  answers: Array<ApplicantFormAnswerInput>;
  /** URL Query params */
  urlTrackingParams?: Maybe<Scalars['JSON']>;
};

export type ApplicantStatus = {
  __typename?: 'ApplicantStatus';
  /** Current status of the applicant */
  current: ApplicantStatusOption;
  /** Possible options for new status */
  options: Array<ApplicantStatusOption>;
};

export type ApplicantStatusOption = {
  __typename?: 'ApplicantStatusOption';
  /** Color for the background */
  backgroundColor: Scalars['String'];
  /** Display value for status */
  displayValue: Scalars['String'];
  /** Identifier of the status */
  key: Scalars['String'];
  /** Color for the text */
  textColor: Scalars['String'];
};

export type ApplicationForm = {
  __typename?: 'ApplicationForm';
  /** Body text of the applicant form */
  body?: Maybe<Scalars['String']>;
  /** List of available cohorts */
  cohorts: Array<Cohort>;
  /** Cloudinary Public ID of the hero image */
  heroImagePublicId?: Maybe<Scalars['String']>;
  /** Link to the hero image */
  heroImageUrl?: Maybe<Scalars['String']>;
  /** If the form is published or not */
  isPublished: Scalars['Boolean'];
  /** Learning community */
  learningCommunity: LearningCommunity;
  /** List of questions */
  questions: Array<ApplicantFormQuestion>;
  /** Title of the applicant form */
  title?: Maybe<Scalars['String']>;
  /** Permanent public URL for the applicant form */
  url?: Maybe<Scalars['String']>;
};

/** Attributes for updating the application form */
export type ApplicationFormInput = {
  /** Body of the application form */
  body: Scalars['String'];
  /** Cloudinary Public ID of the hero image */
  heroImagePublicId?: Maybe<Scalars['String']>;
  /** Hero Image URL of the application form */
  heroImageUrl?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<ApplicationFormQuestionInput>>;
  /** Title of the application form */
  title: Scalars['String'];
};

/** Attributes for creating/updating application form questions */
export type ApplicationFormQuestionInput = {
  id?: Maybe<Scalars['ID']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['Int']>;
  question: Scalars['String'];
  withDestroy?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of ApplyToCohort. */
export type ApplyToCohortPayload = {
  __typename?: 'ApplyToCohortPayload';
  applicant?: Maybe<Applicant>;
  errors?: Maybe<Array<UserError>>;
};

/** Attributes for creating or updating a assignment block */
export type AssignmentBlockInput = {
  content: Scalars['String'];
  endsAt?: Maybe<Scalars['DateTimeUtc']>;
  resources?: Maybe<Array<ResourceInput>>;
  startsAt?: Maybe<Scalars['DateTimeUtc']>;
  submissionDeadline: Scalars['DateTimeUtc'];
  timeCommitmentInMinutes: Scalars['Int'];
  title: Scalars['String'];
};

export type AvailabilityTimeSlot = {
  __typename?: 'AvailabilityTimeSlot';
  /** The day of the week */
  day: Scalars['String'];
  /** The end hour and minute */
  endTime: Scalars['HourAndMinute'];
  /** The start hour and minute */
  startTime: Scalars['HourAndMinute'];
};

/** Extra information about the error */
export type BaseErrorExtensions = {
  __typename?: 'BaseErrorExtensions';
  code?: Maybe<ErrorCodeEnum>;
  /** ActiveRecord model */
  model: Scalars['String'];
};

export type BlockComment = {
  __typename?: 'BlockComment';
  /** Learning block object */
  block: LearningPathBlockInterface;
  /** Content of the comment */
  content: Scalars['String'];
  /** When the comment was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** ID of the comment */
  id: Scalars['ID'];
  /** Whether the comment is a reply */
  isReply?: Maybe<Scalars['Boolean']>;
  /** Aggregated list of reactions to the comment */
  reactions: Array<Reaction>;
  /** List of replies to the comment */
  replies: Array<Comment>;
  /** User who created the comment */
  user: User;
};

/** A block comment as a feed entry */
export type BlockCommentFeedEntry = FeedEntryInterface & {
  __typename?: 'BlockCommentFeedEntry';
  /** The block comment object */
  blockComment: BlockComment;
  /** ID of the entry */
  id: Scalars['ID'];
  /** Title of the entry */
  title: Scalars['String'];
};

export type BlockComments = {
  __typename?: 'BlockComments';
  /** # of comments */
  count: Scalars['Int'];
  /** List of comments */
  list: Array<BlockComment>;
};


export type BlockCommentsListArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type BlockRating = {
  __typename?: 'BlockRating';
  /** Feedback provided for the block */
  feedback?: Maybe<Scalars['String']>;
  /** The user who rated */
  user?: Maybe<User>;
  /** Rating value for the block */
  value?: Maybe<Scalars['Int']>;
};

/** Attributes for creating or updating a block rating */
export type BlockRatingInput = {
  /** Feedback for the block */
  feedback?: Maybe<Scalars['String']>;
  /** Rating value, from 1 to 5 */
  value: Scalars['Int'];
};

/** List of block resource types */
export enum BlockResourceTypeEnum {
  /** Download */
  Download = 'DOWNLOAD',
  /** Link */
  Link = 'LINK'
}

export type Channel = {
  __typename?: 'Channel';
  /** Description of the channel */
  description?: Maybe<Scalars['String']>;
  /** Emoji icon of the channel */
  emojiIcon?: Maybe<Scalars['String']>;
  /** ID of the channel */
  id: Scalars['ID'];
  /** List of messages in the channel */
  messages: ChannelMessageConnection;
  /** Name of the channel */
  name?: Maybe<Scalars['String']>;
  /** Source of the channel */
  source?: Maybe<ChannelSourceUnion>;
  /** Type of channel */
  type?: Maybe<ChannelTypeEnum>;
  /** Date/time when the channel was updated */
  updatedAt: Scalars['ISO8601DateTime'];
  /** Users in the channel */
  users?: Maybe<Array<User>>;
  /** Specific information related to the current user */
  viewer?: Maybe<ChannelViewer>;
};


export type ChannelMessagesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Channel. */
export type ChannelConnection = {
  __typename?: 'ChannelConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ChannelEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Channel>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ChannelEdge = {
  __typename?: 'ChannelEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Channel>;
};

/** Attributes for creating a channel */
export type ChannelInput = {
  /** Description of the channel */
  description?: Maybe<Scalars['String']>;
  /** Emoji icon for the channel */
  emojiIcon?: Maybe<Scalars['String']>;
  /** Name of the channel */
  name?: Maybe<Scalars['String']>;
  /** ID of the source of the channel */
  sourceId: Scalars['ID'];
  /** Type of the source */
  sourceType: ChannelSourceTypeEnum;
  /** Type of channel */
  type: ChannelTypeEnum;
  /** Array of user ids */
  userIds?: Maybe<Array<Scalars['ID']>>;
};

export type ChannelMessage = {
  __typename?: 'ChannelMessage';
  /** List of message attachments */
  attachments?: Maybe<Array<ChannelMessageAttachment>>;
  /** Category of the message */
  category?: Maybe<ChannelMessageCategoryEnum>;
  /** The channel the message was sent to */
  channel?: Maybe<Channel>;
  /** Content of the message */
  content?: Maybe<Scalars['String']>;
  /** Date/time when the message was sent */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** ID of the message */
  id: Scalars['ID'];
  /** Whether the message is a reply */
  isReply?: Maybe<Scalars['Boolean']>;
  /** Parent message */
  parent?: Maybe<ChannelMessage>;
  /** Aggregated list of reactions to the message */
  reactions: Array<Reaction>;
  /** Referenced object */
  reference?: Maybe<ChannelMessageReferenceUnion>;
  /** List of replies */
  replies: Array<ChannelMessage>;
  /** The user that created the message */
  user?: Maybe<User>;
};

export type ChannelMessageAttachment = {
  __typename?: 'ChannelMessageAttachment';
  /** Date/time when the attachment was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** ID of the attachment */
  id: Scalars['ID'];
  /** Target URL of the attachment for external linking */
  targetUrl?: Maybe<Scalars['String']>;
  /** Type of the attachment */
  type?: Maybe<ChannelMessageAttachmentTypeEnum>;
  /** URL of the attachment */
  url?: Maybe<Scalars['String']>;
};

/** Attributes for creating a channel message attachment */
export type ChannelMessageAttachmentInput = {
  /** URL to link to */
  targetUrl?: Maybe<Scalars['String']>;
  /** Type of the attachment */
  type: ChannelMessageAttachmentTypeEnum;
  /** URL of the attachment */
  url: Scalars['String'];
};

/** Types of message attachments */
export enum ChannelMessageAttachmentTypeEnum {
  /** Image */
  Image = 'IMAGE',
  /** Link */
  Link = 'LINK',
  /** Video */
  Video = 'VIDEO'
}

/** Channel message categories */
export enum ChannelMessageCategoryEnum {
  /** Announcement */
  Announcement = 'ANNOUNCEMENT',
  /** Question */
  Question = 'QUESTION',
  /** Resource */
  Resource = 'RESOURCE'
}

/** The connection type for ChannelMessage. */
export type ChannelMessageConnection = {
  __typename?: 'ChannelMessageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ChannelMessageEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ChannelMessage>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ChannelMessageEdge = {
  __typename?: 'ChannelMessageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ChannelMessage>;
};

/** Attributes for a channel message */
export type ChannelMessageInput = {
  /** List of attachments to create for the message */
  attachments?: Maybe<Array<ChannelMessageAttachmentInput>>;
  /** Category of the message */
  category?: Maybe<ChannelMessageCategoryEnum>;
  /** Content of the message */
  content?: Maybe<Scalars['String']>;
};

export type ChannelMessageReferenceUnion = CohortProfileIntroduction | MilestoneSubmissionFeedbackRequest;

/** Available sources for channel source */
export enum ChannelSourceTypeEnum {
  /** Cohort */
  Cohort = 'COHORT',
  /** Learning community */
  LearningCommunity = 'LEARNING_COMMUNITY'
}

/** Objects that can be the source of a channel */
export type ChannelSourceUnion = Cohort | LearningCommunity | PeerGroup;

/** Types of channels */
export enum ChannelTypeEnum {
  /** Channel with AI */
  Ai = 'AI',
  /** Channel with Ask My Youtube Channel feature */
  AskYtChannel = 'ASK_YT_CHANNEL',
  /** Direct Messaging */
  Direct = 'DIRECT',
  /** Private channel - Can't invite new users */
  PrivateClosed = 'PRIVATE_CLOSED',
  /** Public channel - Anyone can join */
  PublicOpen = 'PUBLIC_OPEN',
  /** Channel with custom workflows */
  Space = 'SPACE'
}

export type ChannelViewer = {
  __typename?: 'ChannelViewer';
  /** If the user has unread messages in this channel */
  hasUnreadMessages: Scalars['Boolean'];
  /** The viewer */
  user?: Maybe<User>;
};

/** A Cohort from one Learning Community */
export type Cohort = {
  __typename?: 'Cohort';
  /** Link to Calendly booking */
  calendlyLink?: Maybe<Scalars['String']>;
  /** List of channels for the cohort */
  channels: Array<Channel>;
  /** Community resources in the cohort */
  communityResources?: Maybe<Array<CommunityResource>>;
  /** List of email templates for the cohort */
  emailTemplates?: Maybe<Array<EmailTemplate>>;
  /** List of Users with learner role as a connection */
  fellows: UserConnection;
  /** Whether blocks in this cohort should be sent to AI processing */
  hasAiProcessing: Scalars['Boolean'];
  /** Whether the cohort has dynamic squads */
  hasDynamicSquads: Scalars['Boolean'];
  /** Whether the cohort has freemium access */
  hasFreemium: Scalars['Boolean'];
  /** ID of the cohort */
  id: Scalars['ID'];
  /**
   * Learners of the cohort
   * @deprecated Use fellows instead
   */
  learners?: Maybe<Array<User>>;
  /** Learning community the cohort belongs to */
  learningCommunity: LearningCommunity;
  /** Learning path of the cohort */
  learningPath: LearningPath;
  /** The main channel for the cohort */
  mainChannel?: Maybe<Channel>;
  /** Name of the cohort */
  name: Scalars['String'];
  /** List of peer groups for the cohort */
  peerGroups?: Maybe<Array<PeerGroup>>;
  /** All users with access to the cohort */
  people?: Maybe<Array<User>>;
  /** Base price for cohort */
  price?: Maybe<CohortPrice>;
  /** Schedule for the cohort */
  schedule?: Maybe<CohortSchedule>;
  /** The status of the cohort */
  status: CohortStatusEnum;
  /** Type of the cohort */
  type: CohortTypeEnum;
  /** Link to VideoAsk interview */
  videoaskLink?: Maybe<Scalars['String']>;
  /** Cohort viewer */
  viewer?: Maybe<CohortViewer>;
  /**
   * Role for viewer
   * @deprecated User viewer
   */
  viewerRole?: Maybe<Role>;
};


/** A Cohort from one Learning Community */
export type CohortChannelsArgs = {
  onlyMine?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<ChannelTypeEnum>>;
};


/** A Cohort from one Learning Community */
export type CohortFellowsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  filters?: Maybe<UserFilterInput>;
  first?: Maybe<Scalars['Int']>;
  isBookmarked?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  withSharedProfile?: Maybe<Scalars['Boolean']>;
};

/** A post in a cohort */
export type CohortPost = {
  __typename?: 'CohortPost';
  /** The category of the post */
  category?: Maybe<Scalars['String']>;
  /** Cohort information */
  cohort: Cohort;
  /** List of comments to the post */
  comments?: Maybe<Array<Comment>>;
  /** When the post was made */
  createdAt: Scalars['ISO8601DateTime'];
  /** ID of the cohort post */
  id: Scalars['ID'];
  /** The message of the post */
  message?: Maybe<Scalars['String']>;
  /** Aggregated list of reactions to the post */
  reactions: Array<Reaction>;
  /** User information */
  user: User;
};

/** A cohort post as a feed entry */
export type CohortPostFeedEntry = FeedEntryInterface & {
  __typename?: 'CohortPostFeedEntry';
  /** The post in the feed entry */
  cohortPost: CohortPost;
  /** ID of the entry */
  id: Scalars['ID'];
  /** Title of the entry */
  title: Scalars['String'];
};

/** Attributes belonging to a cohort post */
export type CohortPostInput = {
  category?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** The Price for a Cohort */
export type CohortPrice = {
  __typename?: 'CohortPrice';
  currency?: Maybe<CurrencyEnum>;
  value?: Maybe<Scalars['Float']>;
};

/** Attributes relating to a user's cohort profile */
export type CohortProfileInput = {
  availability?: Maybe<Array<TimeSlotInput>>;
  canBeHelpedWith?: Maybe<Scalars['String']>;
  canHelpWith?: Maybe<Scalars['String']>;
  enrollmentReason?: Maybe<Scalars['String']>;
};

/** A cohort profile introduction */
export type CohortProfileIntroduction = {
  __typename?: 'CohortProfileIntroduction';
  /** The cohort profile */
  cohortProfile?: Maybe<CohortUserProfile>;
  /** List of comments to the profile introduction */
  comments?: Maybe<Array<Comment>>;
  /** When the introduction was made */
  createdAt: Scalars['ISO8601DateTime'];
  /** ID of the cohort profile introduction */
  id: Scalars['ID'];
  /** the shared message */
  message?: Maybe<Scalars['String']>;
  /** Aggregated list of reactions to the introduction */
  reactions: Array<Reaction>;
  /** Author of the introduction */
  user?: Maybe<User>;
};

/** A cohort introduction as a feed entry */
export type CohortProfileIntroductionFeedEntry = FeedEntryInterface & {
  __typename?: 'CohortProfileIntroductionFeedEntry';
  /** ID of the entry */
  id: Scalars['ID'];
  /** The profile introduction */
  introduction?: Maybe<CohortProfileIntroduction>;
  /** Title of the entry */
  title: Scalars['String'];
};

/** The Schedule for one Cohort */
export type CohortSchedule = {
  __typename?: 'CohortSchedule';
  /** Date when cohort closes to applications */
  applicationsEndDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when cohort becomes open to applications */
  applicationsStartDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when cohort ends */
  endDate?: Maybe<Scalars['ISO8601Date']>;
  /** Date when cohort starts */
  startDate?: Maybe<Scalars['ISO8601Date']>;
};

/** List of application statuses for the cohort */
export enum CohortStatusEnum {
  /** Applications closed */
  ApplicationsClosed = 'APPLICATIONS_CLOSED',
  /** Applications open */
  ApplicationsOpen = 'APPLICATIONS_OPEN',
  /** Ended */
  Ended = 'ENDED',
  /** N/A */
  NotApplicable = 'NOT_APPLICABLE',
  /** Running */
  Running = 'RUNNING'
}

/** Available types for a cohort */
export enum CohortTypeEnum {
  /** Async */
  Async = 'ASYNC',
  /** Cohort */
  Cohort = 'COHORT'
}

/** The profile of a user in a cohort */
export type CohortUserProfile = {
  __typename?: 'CohortUserProfile';
  availability?: Maybe<Array<AvailabilityTimeSlot>>;
  /** What the learner would like help with */
  canBeHelpedWith?: Maybe<Scalars['String']>;
  /** What the learner can help others with */
  canHelpWith?: Maybe<Scalars['String']>;
  /** The reason they applied to the cohort */
  enrollmentReason?: Maybe<Scalars['String']>;
  /** ID of the cohort profile */
  id: Scalars['ID'];
  /** If User shared their profile in the feed */
  isShared: Scalars['Boolean'];
  /** User info about the profile */
  viewer: CohortUserProfileViewer;
};

/** Attributes relating to a user's profile in a cohort */
export type CohortUserProfileInput = {
  aboutMe?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  cohortProfile?: Maybe<CohortProfileInput>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  jobDescription?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  professionalUrl?: Maybe<Scalars['String']>;
  pronouns?: Maybe<PronounsEnum>;
  timezone?: Maybe<Scalars['String']>;
};

export type CohortUserProfileViewer = {
  __typename?: 'CohortUserProfileViewer';
  /** If the profile is favorited by current user */
  favorite: Scalars['Boolean'];
};

/** Cohort viewer */
export type CohortViewer = {
  __typename?: 'CohortViewer';
  /** Applicant for the viewer */
  applicant?: Maybe<Applicant>;
  /** Role for viewer */
  role?: Maybe<Role>;
  /** Upcoming blocks for the viewer */
  upcomingBlocks: Array<LearningPathBlockInterface>;
};


/** Cohort viewer */
export type CohortViewerUpcomingBlocksArgs = {
  from: Scalars['ISO8601DateTime'];
};

export type Comment = {
  __typename?: 'Comment';
  /** Content of the comment */
  content: Scalars['String'];
  /** When the comment was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** ID of the comment */
  id: Scalars['ID'];
  /** Whether the comment is a reply */
  isReply?: Maybe<Scalars['Boolean']>;
  /** Aggregated list of reactions to the comment */
  reactions: Array<Reaction>;
  /** List of replies to the comment */
  replies: Array<Comment>;
  /** User who created the comment */
  user: User;
};

/** Attributes of a comment */
export type CommentInput = {
  content?: Maybe<Scalars['String']>;
};

export type CommunityResource = {
  __typename?: 'CommunityResource';
  /** When the resource was added */
  createdAt: Scalars['ISO8601DateTime'];
  /** Description of the resource */
  description: Scalars['String'];
  /** ID of the resource */
  id: Scalars['ID'];
  /** URL to access the resource */
  url: Scalars['String'];
  /** User who created the resource */
  user: User;
};

/** Attributes for creating community resources */
export type CommunityResourceInput = {
  description: Scalars['String'];
  url: Scalars['String'];
};

export type CompletionStatistics = {
  __typename?: 'CompletionStatistics';
  /** % of completion of the block */
  rate: Scalars['String'];
  /** Users who completed the block */
  usersWhoCompleted: Array<User>;
};

export type ContactUser = {
  __typename?: 'ContactUser';
  /** The user object */
  user?: Maybe<User>;
};

/** Attributes for creating or updating a block */
export type ContentBlockInput = {
  content: Scalars['String'];
  endsAt?: Maybe<Scalars['DateTimeUtc']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  resources?: Maybe<Array<ResourceInput>>;
  startsAt?: Maybe<Scalars['DateTimeUtc']>;
  timeCommitmentInMinutes: Scalars['Int'];
  title: Scalars['String'];
};

/** Autogenerated return type of CreateAssignmentBlock. */
export type CreateAssignmentBlockPayload = {
  __typename?: 'CreateAssignmentBlockPayload';
  block?: Maybe<LearningPathAssignmentBlock>;
  errors?: Maybe<Array<UserError>>;
};

/** Attributes for creating a channel message */
export type CreateChannelMessageInput = {
  /** List of attachments to create for the message */
  attachments?: Maybe<Array<ChannelMessageAttachmentInput>>;
  /** Category of the message */
  category?: Maybe<ChannelMessageCategoryEnum>;
  /** Content of the message */
  content?: Maybe<Scalars['String']>;
  /** ID of the message */
  id?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of CreateChannel. */
export type CreateChannelPayload = {
  __typename?: 'CreateChannelPayload';
  channel?: Maybe<Channel>;
  errors?: Maybe<Array<UserError>>;
};

/** Attributes for creating a cohort */
export type CreateCohortInput = {
  /** End date of the cohort */
  applicationsEndDate?: Maybe<Scalars['ISO8601Date']>;
  /** End date of the cohort */
  applicationsStartDate?: Maybe<Scalars['ISO8601Date']>;
  /** Link to Calendly booking */
  calendlyLink?: Maybe<Scalars['String']>;
  /** End date of the cohort */
  endDate?: Maybe<Scalars['ISO8601Date']>;
  /** Whether the cohort has AI Assistant */
  hasAiProcessing?: Maybe<Scalars['Boolean']>;
  /** Whether the cohort has dynamic squads */
  hasDynamicSquads?: Maybe<Scalars['Boolean']>;
  /** Whether the cohort has freemium */
  hasFreemium?: Maybe<Scalars['Boolean']>;
  /** Name of the cohort */
  name: Scalars['String'];
  /** ID of the Cohort to copy the learning path from */
  sourceCohortId?: Maybe<Scalars['ID']>;
  /** Start date of the cohort */
  startDate?: Maybe<Scalars['ISO8601Date']>;
  /** Type of the cohort */
  type?: Maybe<CohortTypeEnum>;
};

/** Autogenerated return type of CreateCohort. */
export type CreateCohortPayload = {
  __typename?: 'CreateCohortPayload';
  cohort?: Maybe<Cohort>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of CreateCohortPost. */
export type CreateCohortPostPayload = {
  __typename?: 'CreateCohortPostPayload';
  cohortPost?: Maybe<CohortPost>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of CreateCohortUserProfileIntroduction. */
export type CreateCohortUserProfileIntroductionPayload = {
  __typename?: 'CreateCohortUserProfileIntroductionPayload';
  errors?: Maybe<Array<UserError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreateCommunityResource. */
export type CreateCommunityResourcePayload = {
  __typename?: 'CreateCommunityResourcePayload';
  communityResource?: Maybe<CommunityResource>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of CreateContentBlock. */
export type CreateContentBlockPayload = {
  __typename?: 'CreateContentBlockPayload';
  block?: Maybe<LearningPathContentBlock>;
  errors?: Maybe<Array<UserError>>;
};

/** Attributes for creating an event */
export type CreateEventInput = {
  /** Description of the event */
  description?: Maybe<Scalars['String']>;
  /** Duration of the event in minutes */
  durationInMinutes: Scalars['Int'];
  /** External video meeting URL */
  externalVideoMeetingUrl?: Maybe<Scalars['String']>;
  /** Image URL */
  imageUrl?: Maybe<Scalars['String']>;
  /** Location of the event */
  location?: Maybe<Scalars['String']>;
  /** ID of the source of the event */
  sourceId: Scalars['ID'];
  /** Type of the source of the event */
  sourceType: EventSourceTypeEnum;
  /** Start date of the event */
  startsAt: Scalars['ISO8601DateTime'];
  /** Title of the event */
  title: Scalars['String'];
};

/** Autogenerated return type of CreateEvent. */
export type CreateEventPayload = {
  __typename?: 'CreateEventPayload';
  errors?: Maybe<Array<UserError>>;
  event?: Maybe<Event>;
};

/** Attributes for creating a learning path outline */
export type CreateLearningPathOutlineInput = {
  /** Reference topic */
  referenceTopic: Scalars['String'];
};

/** Autogenerated return type of CreateLiveSessionBlock. */
export type CreateLiveSessionBlockPayload = {
  __typename?: 'CreateLiveSessionBlockPayload';
  block?: Maybe<LearningPathLiveSessionBlock>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of CreateMilestoneBlock. */
export type CreateMilestoneBlockPayload = {
  __typename?: 'CreateMilestoneBlockPayload';
  block?: Maybe<LearningPathMilestoneBlock>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of CreateSection. */
export type CreateSectionPayload = {
  __typename?: 'CreateSectionPayload';
  errors?: Maybe<Array<UserError>>;
  section?: Maybe<LearningPathSection>;
};

/** Autogenerated return type of CreateSurveySubmission. */
export type CreateSurveySubmissionPayload = {
  __typename?: 'CreateSurveySubmissionPayload';
  errors?: Maybe<Array<UserError>>;
  surveySubmission?: Maybe<SurveySubmission>;
};

/** Autogenerated return type of CreateZapierIntegration. */
export type CreateZapierIntegrationPayload = {
  __typename?: 'CreateZapierIntegrationPayload';
  errors?: Maybe<Array<UserError>>;
  learningCommunity?: Maybe<LearningCommunity>;
};

export type Crm = {
  __typename?: 'Crm';
  /** List of applicants for all given cohorts in learning community */
  applicants: Array<Applicant>;
  /** Available Cohorts from Learning Community */
  cohorts: Array<Cohort>;
  /** List of possible status */
  statuses: Array<ApplicantStatusOption>;
};


export type CrmApplicantsArgs = {
  cohortIds?: Maybe<Array<Scalars['ID']>>;
  cohortStatuses?: Maybe<Array<CohortStatusEnum>>;
  numberOfRecentCohorts?: Maybe<Scalars['Int']>;
};

/** Available currencies */
export enum CurrencyEnum {
  /** Euro */
  Eur = 'EUR',
  /** British Pound sterling */
  Gbp = 'GBP',
  /** US Dollar */
  Usd = 'USD'
}

/** Autogenerated return type of DeleteBlock. */
export type DeleteBlockPayload = {
  __typename?: 'DeleteBlockPayload';
  errors?: Maybe<Array<UserError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteChannelMessage. */
export type DeleteChannelMessagePayload = {
  __typename?: 'DeleteChannelMessagePayload';
  errors?: Maybe<Array<UserError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteChannel. */
export type DeleteChannelPayload = {
  __typename?: 'DeleteChannelPayload';
  errors?: Maybe<Array<UserError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteCohort. */
export type DeleteCohortPayload = {
  __typename?: 'DeleteCohortPayload';
  errors?: Maybe<Array<UserError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteComment. */
export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  errors?: Maybe<Array<UserError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteEvent. */
export type DeleteEventPayload = {
  __typename?: 'DeleteEventPayload';
  errors?: Maybe<Array<UserError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteEventRsvp. */
export type DeleteEventRsvpPayload = {
  __typename?: 'DeleteEventRsvpPayload';
  errors?: Maybe<Array<UserError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteFeedEntry. */
export type DeleteFeedEntryPayload = {
  __typename?: 'DeleteFeedEntryPayload';
  errors?: Maybe<Array<UserError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteRsvpForSectionLiveSessions. */
export type DeleteRsvpForSectionLiveSessionsPayload = {
  __typename?: 'DeleteRsvpForSectionLiveSessionsPayload';
  errors?: Maybe<Array<UserError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeleteSection. */
export type DeleteSectionPayload = {
  __typename?: 'DeleteSectionPayload';
  errors?: Maybe<Array<UserError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DeleteVideoMeetingRecording. */
export type DeleteVideoMeetingRecordingPayload = {
  __typename?: 'DeleteVideoMeetingRecordingPayload';
  errors?: Maybe<Array<UserError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DuplicateBlock. */
export type DuplicateBlockPayload = {
  __typename?: 'DuplicateBlockPayload';
  block?: Maybe<LearningPathBlockInterface>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of DuplicateSection. */
export type DuplicateSectionPayload = {
  __typename?: 'DuplicateSectionPayload';
  errors?: Maybe<Array<UserError>>;
  section?: Maybe<LearningPathSection>;
};

/** Email Template */
export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  /** Available Variables. To use between {{ }} */
  availableVariables: Array<Scalars['String']>;
  /** Content */
  content?: Maybe<Scalars['String']>;
  /** Content Type */
  contentType?: Maybe<EmailTemplateContentTypeEnum>;
  /** Event Description */
  eventDescription?: Maybe<Scalars['String']>;
  /** Event Name */
  eventName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Is Active */
  isActive: Scalars['Boolean'];
  /** Position */
  position: Scalars['Int'];
  /** Subject */
  subject?: Maybe<Scalars['String']>;
};

/** Available content types in email templates */
export enum EmailTemplateContentTypeEnum {
  /** HTML */
  Html = 'HTML',
  /** Markdown */
  Markdown = 'MARKDOWN'
}

/** Available error codes */
export enum ErrorCodeEnum {
  /** Client does not have adequate permissions */
  AuthError = 'AUTH_ERROR',
  /** Requested resource was not found */
  NotFound = 'NOT_FOUND',
  /** Internal server error */
  ServerError = 'SERVER_ERROR',
  /** Error caused by a third party resource */
  ThirdPartyError = 'THIRD_PARTY_ERROR',
  /** Error perceived to be caused by the client */
  UserInputError = 'USER_INPUT_ERROR'
}

/** An event */
export type Event = {
  __typename?: 'Event';
  /** List of comments to the event */
  comments?: Maybe<Array<Comment>>;
  /** Creator of the event */
  creator?: Maybe<User>;
  /** Description of event */
  description?: Maybe<Scalars['String']>;
  /** Duration of event in minutes */
  durationInMinutes?: Maybe<Scalars['Int']>;
  /** ID of the Event */
  id: Scalars['ID'];
  /** Image URL */
  imageUrl?: Maybe<Scalars['String']>;
  /** Location of event if live event */
  location?: Maybe<Scalars['String']>;
  /** The people attending the event */
  peopleAttending?: Maybe<Array<User>>;
  /** Source of the event */
  source?: Maybe<EventSourceUnion>;
  /** Start time of event */
  startsAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Title of event */
  title?: Maybe<Scalars['String']>;
  /** Video meeting of the event if online */
  videoMeeting?: Maybe<VideoMeeting>;
};

/** The connection type for Event. */
export type EventConnection = {
  __typename?: 'EventConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EventEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Event>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type EventEdge = {
  __typename?: 'EventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Event>;
};

/** Available sources for event source */
export enum EventSourceTypeEnum {
  /** Cohort */
  Cohort = 'COHORT',
  /** Learning community */
  LearningCommunity = 'LEARNING_COMMUNITY'
}

/** Objects that can be the source of an event */
export type EventSourceUnion = Cohort | LearningCommunity;

/** Feed for the current user */
export type Feed = {
  __typename?: 'Feed';
  /** Interface for multiple types of feed entries */
  entries: FeedEntryInterfaceConnection;
};


/** Feed for the current user */
export type FeedEntriesArgs = {
  after?: Maybe<Scalars['String']>;
  filters?: Maybe<FilterInput>;
  first?: Maybe<Scalars['Int']>;
};

export type FeedEntryInterface = {
  /** ID of the entry */
  id: Scalars['ID'];
  /** Title of the entry */
  title: Scalars['String'];
};

/** The connection type for FeedEntryInterface. */
export type FeedEntryInterfaceConnection = {
  __typename?: 'FeedEntryInterfaceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FeedEntryInterfaceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<FeedEntryInterface>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type FeedEntryInterfaceEdge = {
  __typename?: 'FeedEntryInterfaceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<FeedEntryInterface>;
};

/** Types of feed entries */
export enum FeedEntryTypeEnum {
  /** A comment */
  Comment = 'COMMENT',
  /** Feedback request on a submission */
  FeedbackRequest = 'FEEDBACK_REQUEST',
  /** Introduction of a cohort profile */
  Introduction = 'INTRODUCTION',
  /** A general post */
  Post = 'POST'
}

/** A milestone submission as a feed entry */
export type FeedMilestoneSubmission = FeedEntryInterface & {
  __typename?: 'FeedMilestoneSubmission';
  /** ID of the entry */
  id: Scalars['ID'];
  /** Milestone block the submission belongs to */
  milestone?: Maybe<LearningPathMilestoneBlock>;
  /** The milestone submission */
  submission?: Maybe<MilestoneSubmission>;
  /** Title of the entry */
  title: Scalars['String'];
  /** Author of the feed entry */
  user: User;
};

/** The user giving the feedback */
export type FeedbackResponseAuthor = {
  __typename?: 'FeedbackResponseAuthor';
  /** The role of the user in the learning community or cohort */
  role: Role;
  /** The User object */
  user: User;
};

export type FeedbackStatistics = {
  __typename?: 'FeedbackStatistics';
  /** The average rating from all the learners who rated */
  averageRating: Scalars['Float'];
  /** The person who rated and the rating score */
  ratings: Array<BlockRating>;
};

/** Filters for entries on the feed */
export type FilterInput = {
  /** ID of the learning block */
  blockId?: Maybe<Scalars['ID']>;
  /** Category of the entries */
  category?: Maybe<Scalars['String']>;
  /** Entries from specific people */
  people?: Maybe<PeopleFilterEnum>;
  /** Type of the entry */
  type?: Maybe<Array<FeedEntryTypeEnum>>;
};

export type FormAnswer = StructuredFeedbackFormatInterface & {
  __typename?: 'FormAnswer';
  /** Description of the rubric */
  description?: Maybe<Scalars['String']>;
  /** Selected option */
  option: Scalars['String'];
  /** Reason for the selection */
  reason?: Maybe<Scalars['String']>;
  /** The id of the milestone rubric */
  rubricId: Scalars['String'];
  /** Title of the rubric */
  title: Scalars['String'];
  /** ENUM value for the type */
  type: StructuredFeedbackFormatEnum;
};

export type FormQuestion = MilestoneRubricQuestionInterface & {
  __typename?: 'FormQuestion';
  /** Description of the description */
  description?: Maybe<Scalars['String']>;
  /** List of options */
  options: Array<FormQuestionOption>;
  /** ID of the rubric */
  rubricId: Scalars['String'];
  /** Title of the question */
  title: Scalars['String'];
  /** ENUM value for the type */
  type: StructuredFeedbackFormatEnum;
};

export type FormQuestionOption = {
  __typename?: 'FormQuestionOption';
  /** The name of the option */
  label: Scalars['String'];
  /** The position of the option for display purpose */
  position: Scalars['Int'];
};

/** Autogenerated return type of GenerateLearningCommunityTopicSuggestions. */
export type GenerateLearningCommunityTopicSuggestionsPayload = {
  __typename?: 'GenerateLearningCommunityTopicSuggestionsPayload';
  errors?: Maybe<Array<UserError>>;
  /** Job ID in case of async processing */
  jobId?: Maybe<Scalars['String']>;
  topicSuggestions?: Maybe<Array<LearningCommunityTopicSuggestion>>;
};

/** Autogenerated return type of GenerateLearningCommunityValidationSurvey. */
export type GenerateLearningCommunityValidationSurveyPayload = {
  __typename?: 'GenerateLearningCommunityValidationSurveyPayload';
  errors?: Maybe<Array<UserError>>;
  /** Job ID in case of async processing */
  jobId?: Maybe<Scalars['String']>;
  /** Survey */
  survey?: Maybe<Survey>;
};

/** Autogenerated return type of GenerateLearningPathOutline. */
export type GenerateLearningPathOutlinePayload = {
  __typename?: 'GenerateLearningPathOutlinePayload';
  errors?: Maybe<Array<UserError>>;
  jobId?: Maybe<Scalars['String']>;
  learningPathOutline?: Maybe<LearningPathOutline>;
};

/** Google Integration */
export type GoogleAccount = {
  __typename?: 'GoogleAccount';
  /** User that added the integration */
  addedBy?: Maybe<User>;
  /** Enabled scopes */
  enabledScopes: Array<Scalars['String']>;
  /** Intent */
  intent: Scalars['String'];
};

/** Autogenerated return type of JoinChannel. */
export type JoinChannelPayload = {
  __typename?: 'JoinChannelPayload';
  errors?: Maybe<Array<UserError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of JoinLearningCommunityTeam. */
export type JoinLearningCommunityTeamPayload = {
  __typename?: 'JoinLearningCommunityTeamPayload';
  errors?: Maybe<Array<UserError>>;
  user?: Maybe<User>;
};

export type LearnerCertificate = {
  __typename?: 'LearnerCertificate';
  /** URL of the certificate image */
  imageUrl?: Maybe<Scalars['String']>;
  /** URL to add certificate to linkedin */
  linkedinShareUrl?: Maybe<Scalars['String']>;
  /** URL to share on twitter */
  twitterShareUrl?: Maybe<Scalars['String']>;
};

/** A Learning Community */
export type LearningCommunity = {
  __typename?: 'LearningCommunity';
  /** Application form for the learning community */
  applicationForm?: Maybe<ApplicationForm>;
  /** List of channels for the learning community */
  channels: Array<Channel>;
  /** List of cohorts for the learning community */
  cohorts: Array<Cohort>;
  /** Main creator of the Learning Community */
  creator: User;
  /** Text to display in application form to opt in for direct creator reach out */
  creatorReachOutOptInText?: Maybe<Scalars['String']>;
  /** List of events for the learning community */
  events: EventConnection;
  /** External URL for the learning community website */
  externalWebsiteUrl?: Maybe<Scalars['String']>;
  /** If the Learning Community has Ask My YT Channel feature enabled */
  hasAskMyYtChannel: Scalars['Boolean'];
  /** If the Learning Community has interviews enabled */
  hasInterviews: Scalars['Boolean'];
  /** ID of the Learning Community */
  id: Scalars['ID'];
  /** Integrations for the learning */
  integrations?: Maybe<LearningCommunityIntegrations>;
  /** Invitation code for the learning community */
  invitationCode?: Maybe<Scalars['String']>;
  /** Locale of the learning community */
  locale: LocaleEnum;
  /** URL of the learning community logo */
  logoUrl?: Maybe<Scalars['String']>;
  /** Name of the learning community */
  name: Scalars['String'];
  /** Selected reference topic */
  referenceTopic?: Maybe<Scalars['String']>;
  /** Slug where the learning community can be accessed */
  slug: Scalars['String'];
  /** Support email for the learning community */
  supportEmail: Scalars['String'];
  /** List of users with access to the learning community */
  team: Array<User>;
  /** Temporary learning path outline */
  temporaryLearningPathOutline?: Maybe<Scalars['JSON']>;
  /** Theme color for the learning community */
  themeColor?: Maybe<Scalars['String']>;
  topicSuggestions?: Maybe<Array<LearningCommunityTopicSuggestion>>;
  /** Validation survey for the learning community */
  validationSurvey?: Maybe<Survey>;
  /** Video meeting provider */
  videoMeetingProvider: VideoMeetingSourceEnum;
  /** External URL for the learning community waiting list */
  waitingListExternalUrl?: Maybe<Scalars['String']>;
  /** Waiting list records for the learning community */
  waitingListRecords: WaitingListRecordConnection;
};


/** A Learning Community */
export type LearningCommunityChannelsArgs = {
  onlyMine?: Maybe<Scalars['Boolean']>;
};


/** A Learning Community */
export type LearningCommunityEventsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['ISO8601DateTime']>;
  last?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['ISO8601DateTime']>;
};


/** A Learning Community */
export type LearningCommunityWaitingListRecordsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
};

/** Google Integration */
export type LearningCommunityGoogleIntegration = {
  __typename?: 'LearningCommunityGoogleIntegration';
  /** User that added the integration */
  addedBy?: Maybe<User>;
  /** Google calendar account */
  calendarAccount?: Maybe<GoogleAccount>;
  /** Google youtube account */
  youtubeAccount?: Maybe<YoutubeAccount>;
};

/** LC integrations */
export type LearningCommunityIntegrations = {
  __typename?: 'LearningCommunityIntegrations';
  google?: Maybe<LearningCommunityGoogleIntegration>;
  zapier?: Maybe<LearningCommunityZapierIntegration>;
  zoom?: Maybe<LearningCommunityZoomIntegration>;
};

/** Learning Community Topic Suggestion */
export type LearningCommunityTopicSuggestion = {
  __typename?: 'LearningCommunityTopicSuggestion';
  /** Relevance */
  relevance: Scalars['String'];
  /** Topic */
  topic?: Maybe<Scalars['String']>;
  /** Videos */
  videos: Array<YoutubeChannelVideo>;
};

/** Autogenerated return type of LearningCommunityValidationSurveyGenerated. */
export type LearningCommunityValidationSurveyGeneratedPayload = {
  __typename?: 'LearningCommunityValidationSurveyGeneratedPayload';
  success: Scalars['Boolean'];
  survey?: Maybe<Survey>;
};

/** LC integrations */
export type LearningCommunityZapierIntegration = {
  __typename?: 'LearningCommunityZapierIntegration';
  /** API KEY */
  apiKey?: Maybe<Scalars['String']>;
};

/** Google Integration */
export type LearningCommunityZoomIntegration = {
  __typename?: 'LearningCommunityZoomIntegration';
  /** User that added the integration */
  addedBy?: Maybe<User>;
};

export type LearningPath = {
  __typename?: 'LearningPath';
  /** Project of the learning path */
  project?: Maybe<LearningPathProject>;
  /** Sections of the learning path */
  sections: Array<LearningPathSection>;
};

export type LearningPathAssignmentBlock = LearningPathBlockInterface & {
  __typename?: 'LearningPathAssignmentBlock';
  /** Body information for the block */
  body: LearningPathBlockBody;
  /** Comments for the block */
  comments: BlockComments;
  /** ID of the learning block */
  id: Scalars['ID'];
  /** Navigation data of the learner block */
  navigation?: Maybe<LearningPathBlockNavigation>;
  /** List of resources for block */
  resources: Array<LearningPathBlockResource>;
  /** Schedule informtation for block */
  schedule: LearningPathBlockSchedule;
  /** Section the block belongs to */
  section: LearningPathSection;
  /** Statistics information for the block */
  statistics: Statistics;
  /** Deadline for assignment submission */
  submissionDeadlineAt: Scalars['ISO8601DateTime'];
  /** Title of the block */
  title: Scalars['String'];
  /** Type of learning block */
  type: LearningPathBlockType;
  /** Information about the status of the learning block from the user perspective */
  viewerStatus: LearningPathBlockStatus;
};

export type LearningPathBlockBody = {
  __typename?: 'LearningPathBlockBody';
  /** Unparsed body content */
  rawContent?: Maybe<Scalars['String']>;
};

export type LearningPathBlockInterface = {
  /** Body information for the block */
  body: LearningPathBlockBody;
  /** Comments for the block */
  comments: BlockComments;
  /** ID of the learning block */
  id: Scalars['ID'];
  /** Navigation data of the learner block */
  navigation?: Maybe<LearningPathBlockNavigation>;
  /** List of resources for block */
  resources: Array<LearningPathBlockResource>;
  /** Schedule informtation for block */
  schedule: LearningPathBlockSchedule;
  /** Section the block belongs to */
  section: LearningPathSection;
  /** Statistics information for the block */
  statistics: Statistics;
  /** Title of the block */
  title: Scalars['String'];
  /** Type of learning block */
  type: LearningPathBlockType;
  /** Information about the status of the learning block from the user perspective */
  viewerStatus: LearningPathBlockStatus;
};

export type LearningPathBlockNavigation = {
  __typename?: 'LearningPathBlockNavigation';
  /** Next block */
  nextBlock?: Maybe<LearningPathBlockInterface>;
  /** Position of learning block in the learning path */
  position?: Maybe<Scalars['Int']>;
  /** Previous block */
  previousBlock?: Maybe<LearningPathBlockInterface>;
};

export type LearningPathBlockResource = {
  __typename?: 'LearningPathBlockResource';
  /** Description of the resource */
  description: Scalars['String'];
  /** ID of the resource */
  id: Scalars['ID'];
  /** Type of the resource */
  type: BlockResourceTypeEnum;
  /** URL to access the resource */
  url: Scalars['String'];
};

export type LearningPathBlockSchedule = {
  __typename?: 'LearningPathBlockSchedule';
  /** End date/time of the section */
  endsAt?: Maybe<Scalars['DateTimeUtc']>;
  /** Start date/time of the section */
  startsAt?: Maybe<Scalars['DateTimeUtc']>;
  /** Total minutes commitment necessary for this block */
  timeCommitmentInMinutes?: Maybe<Scalars['Int']>;
};

export type LearningPathBlockStatus = {
  __typename?: 'LearningPathBlockStatus';
  /** If the user can mark the block as completed */
  canMarkAsDone: Scalars['Boolean'];
  /** If the user can view the detail of the learning block */
  canViewDetail: Scalars['Boolean'];
  /** When the learning block was marked as done */
  markedAsDoneAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Rating provided for the block */
  rating?: Maybe<BlockRating>;
};

export type LearningPathBlockType = {
  __typename?: 'LearningPathBlockType';
  /** Value to display in clients */
  displayValue: Scalars['String'];
  /** Idenfifier of Learning Block type */
  identifier: LearningPathBlockTypeEnum;
};

/** Types of Learning Blocks */
export enum LearningPathBlockTypeEnum {
  /** Task */
  Assignment = 'ASSIGNMENT',
  /** Content */
  Content = 'CONTENT',
  /** Live Session */
  Livesession = 'LIVESESSION',
  /** Milestone */
  Milestone = 'MILESTONE'
}

export type LearningPathContentBlock = LearningPathBlockInterface & {
  __typename?: 'LearningPathContentBlock';
  /** Body information for the block */
  body: LearningPathBlockBody;
  /** Comments for the block */
  comments: BlockComments;
  /** ID of the learning block */
  id: Scalars['ID'];
  /** Whether the block is public */
  isPublic: Scalars['Boolean'];
  /** Navigation data of the learner block */
  navigation?: Maybe<LearningPathBlockNavigation>;
  /** List of resources for block */
  resources: Array<LearningPathBlockResource>;
  /** Schedule informtation for block */
  schedule: LearningPathBlockSchedule;
  /** Section the block belongs to */
  section: LearningPathSection;
  /** Auto-generated slug for the content block */
  slug?: Maybe<Scalars['String']>;
  /** Statistics information for the block */
  statistics: Statistics;
  /** Title of the block */
  title: Scalars['String'];
  /** Type of learning block */
  type: LearningPathBlockType;
  /** Information about the status of the learning block from the user perspective */
  viewerStatus: LearningPathBlockStatus;
};

export type LearningPathLiveSessionBlock = LearningPathBlockInterface & {
  __typename?: 'LearningPathLiveSessionBlock';
  /** Body information for the block */
  body: LearningPathBlockBody;
  /** Displays if calendar invites were sent */
  calendarInviteSent?: Maybe<Scalars['Boolean']>;
  /** Comments for the block */
  comments: BlockComments;
  /** ID of the learning block */
  id: Scalars['ID'];
  /** Whether this block is an option for dynamic squads */
  isOptionForDynamicSquads: Scalars['Boolean'];
  /** Navigation data of the learner block */
  navigation?: Maybe<LearningPathBlockNavigation>;
  /** The peer group associated with this live session */
  peerGroup?: Maybe<PeerGroup>;
  /** The people attending the live session */
  peopleAttending?: Maybe<Array<User>>;
  /** List of resources for block */
  resources: Array<LearningPathBlockResource>;
  /** Schedule informtation for block */
  schedule: LearningPathBlockSchedule;
  /** Section the block belongs to */
  section: LearningPathSection;
  /** Statistics information for the block */
  statistics: Statistics;
  /** Title of the block */
  title: Scalars['String'];
  /** Type of learning block */
  type: LearningPathBlockType;
  /** The video meeting associated to the block */
  videoMeeting?: Maybe<VideoMeeting>;
  /** Information about the status of the learning block from the user perspective */
  viewerStatus: LearningPathBlockStatus;
};

export type LearningPathMilestoneBlock = LearningPathBlockInterface & {
  __typename?: 'LearningPathMilestoneBlock';
  /** Body information for the block */
  body: LearningPathBlockBody;
  /** Comments for the block */
  comments: BlockComments;
  /** List of questions for the evaluation rubric */
  evaluationRubric?: Maybe<Array<MilestoneRubricQuestionInterface>>;
  /** ID of the learning block */
  id: Scalars['ID'];
  /** Instructions to help with the project */
  instructions?: Maybe<Scalars['String']>;
  /** Progress */
  learnersSubmissions?: Maybe<Array<MilestoneLearnerSubmissionDetails>>;
  /** Navigation data of the learner block */
  navigation?: Maybe<LearningPathBlockNavigation>;
  /** The position of the milestone within the project */
  position: Scalars['Int'];
  /** List of resources for block */
  resources: Array<LearningPathBlockResource>;
  /** Schedule informtation for block */
  schedule: LearningPathBlockSchedule;
  /** Section the block belongs to */
  section: LearningPathSection;
  /** Statistics information for the block */
  statistics: Statistics;
  /** List of milestone steps */
  steps?: Maybe<Array<LearningPathMilestoneBlockStep>>;
  /** Should be submitted by this time */
  submissionDeadline?: Maybe<Scalars['ISO8601DateTime']>;
  /** Title of the block */
  title: Scalars['String'];
  /** Type of learning block */
  type: LearningPathBlockType;
  /** User generated project content */
  viewer?: Maybe<MilestoneViewer>;
  /** Information about the status of the learning block from the user perspective */
  viewerStatus: LearningPathBlockStatus;
};

export type LearningPathMilestoneBlockStep = {
  __typename?: 'LearningPathMilestoneBlockStep';
  /** Types of allowed content */
  allowedSubmissionTypes: Array<AllowedSubmissionTypesEnum>;
  /** Description of the step */
  description: Scalars['String'];
  /** ID of the milestone block step */
  id: Scalars['ID'];
  /** If the milestone block step is optional for the learners */
  isOptional: Scalars['Boolean'];
  /** The milestone block the step belongs to */
  milestone: LearningPathMilestoneBlock;
  /** The position of the step relative to other steps in the block */
  position?: Maybe<Scalars['Int']>;
  /** Url of the template */
  templateInline?: Maybe<Scalars['String']>;
  /** Url of the template */
  templateUrl?: Maybe<Scalars['String']>;
  /** Title of the step */
  title: Scalars['String'];
};

/** Learning path outline */
export type LearningPathOutline = {
  __typename?: 'LearningPathOutline';
  rawData?: Maybe<Scalars['JSON']>;
};

/** Autogenerated return type of LearningPathOutlineGenerated. */
export type LearningPathOutlineGeneratedPayload = {
  __typename?: 'LearningPathOutlineGeneratedPayload';
  learningPathOutline?: Maybe<LearningPathOutline>;
  success: Scalars['Boolean'];
};

export type LearningPathProject = {
  __typename?: 'LearningPathProject';
  /** Description of the project */
  description?: Maybe<Scalars['String']>;
  /** ID of the project */
  id: Scalars['ID'];
  /** List of milestones */
  milestones?: Maybe<Array<LearningPathMilestoneBlock>>;
  /** @deprecated Use via `viewer` instead */
  progress: LearningPathProjectProgress;
  /** Title of the project */
  title: Scalars['String'];
  viewer?: Maybe<LearningPathProjectViewer>;
};

export type LearningPathProjectProgress = {
  __typename?: 'LearningPathProjectProgress';
  /** Certificate for the learner */
  certificate?: Maybe<LearnerCertificate>;
  /** Nr of submitted milestone blocks */
  submittedCount: Scalars['Int'];
  /** Total nr of milestone blocks */
  totalCount: Scalars['Int'];
};

export type LearningPathProjectViewer = {
  __typename?: 'LearningPathProjectViewer';
  progress: LearningPathProjectProgress;
};

export type LearningPathSection = {
  __typename?: 'LearningPathSection';
  /** List of learning blocks */
  blocks: Array<LearningPathBlockInterface>;
  /** The cohort it belongs to */
  cohort: Cohort;
  /** ID of the section */
  id: Scalars['ID'];
  /** Whether the section is free */
  isFree: Scalars['Boolean'];
  /** Schedule info for the section */
  schedule?: Maybe<LearningPathSectionSchedule>;
  /** Title of the section */
  title: Scalars['String'];
  /** Information about the status of the section from the user perspective */
  viewerStatus: LearningPathSectionStatus;
};

export type LearningPathSectionSchedule = {
  __typename?: 'LearningPathSectionSchedule';
  /** End date of the section */
  endDate?: Maybe<Scalars['ISO8601Date']>;
  /** Start date of the section */
  startDate?: Maybe<Scalars['ISO8601Date']>;
  /** Total minutes commitment necessary for this section */
  timeCommitmentInMinutes: Scalars['Int'];
};

export type LearningPathSectionStatus = {
  __typename?: 'LearningPathSectionStatus';
  /** If the user completed all blocks from the section */
  isComplete: Scalars['Boolean'];
  /** Progress of the user in the section */
  progress: Scalars['Float'];
};

/** Autogenerated return type of LeaveChannel. */
export type LeaveChannelPayload = {
  __typename?: 'LeaveChannelPayload';
  errors?: Maybe<Array<UserError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Attributes for creating or updating a livesession block */
export type LiveSessionBlockInput = {
  content: Scalars['String'];
  endsAt: Scalars['DateTimeUtc'];
  /** URL for the Video Meeting */
  externalVideoMeetingUrl?: Maybe<Scalars['String']>;
  /** Whether this block is an option for dynamic squads */
  isOptionForDynamicSquads?: Maybe<Scalars['Boolean']>;
  /** Peer group ID to restrict access to */
  peerGroupId?: Maybe<Scalars['ID']>;
  resources?: Maybe<Array<ResourceInput>>;
  /** When the block is open in UTC */
  startsAt: Scalars['DateTimeUtc'];
  timeCommitmentInMinutes: Scalars['Int'];
  title: Scalars['String'];
  videoMeetingProvider?: Maybe<VideoMeetingSourceEnum>;
};

/** Available Locales */
export enum LocaleEnum {
  /** Deutsch */
  De = 'DE',
  /** English */
  En = 'EN',
  /** Español */
  Es = 'ES',
  /** Português */
  Pt = 'PT'
}

/** Autogenerated return type of MarkBlockCompleted. */
export type MarkBlockCompletedPayload = {
  __typename?: 'MarkBlockCompletedPayload';
  block?: Maybe<LearningPathBlockInterface>;
  errors?: Maybe<Array<UserError>>;
};

/** Attributes for creating or updating a milestone block */
export type MilestoneBlockInput = {
  content?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['DateTimeUtc']>;
  evaluationRubric?: Maybe<Scalars['JSON']>;
  instructions?: Maybe<Scalars['String']>;
  resources?: Maybe<Array<ResourceInput>>;
  startsAt?: Maybe<Scalars['DateTimeUtc']>;
  steps?: Maybe<Array<MilestoneBlockStepsInput>>;
  submissionDeadline?: Maybe<Scalars['DateTimeUtc']>;
  timeCommitmentInMinutes: Scalars['Int'];
  title: Scalars['String'];
};

/** Attributes for creating/deleting resources for blocks */
export type MilestoneBlockStepsInput = {
  /** Types of allowed content */
  allowedSubmissionTypes?: Maybe<Array<AllowedSubmissionTypesEnum>>;
  /** Description of the milestone step */
  description: Scalars['String'];
  /** The ID of the milestone step */
  id?: Maybe<Scalars['ID']>;
  /** If the milestone step is optional for the learners */
  isOptional?: Maybe<Scalars['Boolean']>;
  /** The position of the milestone step relative to other steps */
  position: Scalars['Int'];
  /** Content which will be used as a starting template for the learner submission */
  templateInline?: Maybe<Scalars['String']>;
  /** URL to the template */
  templateUrl?: Maybe<Scalars['String']>;
  /** Title of the milestone step */
  title: Scalars['String'];
  /** Flag indicating the record should be deleted */
  withDestroy?: Maybe<Scalars['Boolean']>;
};

export type MilestoneLearnerSubmissionDetails = {
  __typename?: 'MilestoneLearnerSubmissionDetails';
  /** Status of the submission */
  status: MilestoneLearnerSubmissionStatus;
  /** The milestone submission details */
  submission?: Maybe<MilestoneSubmission>;
  /** User information */
  user: User;
};

export type MilestoneLearnerSubmissionStatus = {
  __typename?: 'MilestoneLearnerSubmissionStatus';
  /** Color for the background */
  backgroundColor: Scalars['String'];
  /** Display value for status */
  displayValue: Scalars['String'];
  /** Identifier of the status */
  key: Scalars['String'];
  /** Color for the text */
  textColor: Scalars['String'];
};

export type MilestoneRubricQuestionInterface = {
  /** Description of the description */
  description?: Maybe<Scalars['String']>;
  /** ID of the rubric */
  rubricId: Scalars['String'];
  /** Title of the question */
  title: Scalars['String'];
  /** ENUM value for the type */
  type: StructuredFeedbackFormatEnum;
};

export type MilestoneSubmission = {
  __typename?: 'MilestoneSubmission';
  /** User discussion on the milestone submissions */
  feedbackRequest?: Maybe<MilestoneSubmissionFeedbackRequest>;
  id: Scalars['ID'];
  isShared: Scalars['Boolean'];
  /** Milestone associated with the submissions */
  milestone?: Maybe<LearningPathMilestoneBlock>;
  /** Last user submitted content */
  rawContent?: Maybe<Scalars['String']>;
  /** Status of the submission */
  status?: Maybe<MilestoneLearnerSubmissionStatus>;
  /** Content submitted by the user per step */
  submissionSteps?: Maybe<Array<MilestoneSubmissionStep>>;
  /** When the submission was marked as done */
  submittedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** When the submission was last saved */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** User that submitted */
  user?: Maybe<User>;
};

export type MilestoneSubmissionFeedbackRequest = {
  __typename?: 'MilestoneSubmissionFeedbackRequest';
  /** List of comments to the feedback request */
  comments?: Maybe<Array<Comment>>;
  /** Content of the feedback request */
  content?: Maybe<Scalars['String']>;
  /** When the feedback request was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** List of structured feedbacks given to the request */
  evaluations?: Maybe<Array<MilestoneSubmissionFeedbackResponse>>;
  /** ID of the feedback request */
  id: Scalars['ID'];
  /** Aggregated list of reactions to the comment */
  reactions: Array<Reaction>;
  /** User who created the milestone submission */
  user?: Maybe<User>;
};

export type MilestoneSubmissionFeedbackResponse = {
  __typename?: 'MilestoneSubmissionFeedbackResponse';
  /** An answer in a structured format */
  answers?: Maybe<Array<StructuredFeedbackFormatInterface>>;
  /** User who has left the feedback response */
  author?: Maybe<FeedbackResponseAuthor>;
  /** When the feedback response was created */
  createdAt: Scalars['ISO8601DateTime'];
  /** ID of the feedback response */
  id: Scalars['ID'];
};

/** Attributes for updating a milestone submission */
export type MilestoneSubmissionInput = {
  /** Steps data for the submission */
  submissionSteps?: Maybe<Array<MilestoneSubmissionStepInput>>;
};

export type MilestoneSubmissionStep = {
  __typename?: 'MilestoneSubmissionStep';
  /** User submitted content */
  content?: Maybe<Scalars['String']>;
  /** The type of content that was submitted */
  contentType?: Maybe<AllowedSubmissionTypesEnum>;
  /** When the submission was created */
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** User submitted content as HTML */
  htmlContent?: Maybe<Scalars['String']>;
  /** ID of the submission step */
  id: Scalars['ID'];
  /** The parent milestone block step details */
  milestoneStep: LearningPathMilestoneBlockStep;
  /** The parent milestone submission details */
  submission?: Maybe<MilestoneSubmission>;
  /** When the submission was last updated */
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Attributes for updating a milestone submission step */
export type MilestoneSubmissionStepInput = {
  /** User generated content for step */
  content: Scalars['String'];
  /** ENUM for Content type */
  contentType: AllowedSubmissionTypesEnum;
  /** HTML version of user content. Ex: an iframe code */
  htmlContent?: Maybe<Scalars['String']>;
  /** The ID of the milestone submission step. Only provide if intent is to update */
  id?: Maybe<Scalars['ID']>;
  /** The ID of the referenced milestone block step */
  milestoneBlockStepId: Scalars['ID'];
  /** Flag indicating the record should be deleted */
  withDestroy?: Maybe<Scalars['Boolean']>;
};

export type MilestoneSubmissionViewer = {
  __typename?: 'MilestoneSubmissionViewer';
  /** Last submitted content in a milestone block */
  milestone?: Maybe<LearningPathMilestoneBlock>;
  /** Last submitted content in a milestone block */
  submission?: Maybe<MilestoneSubmission>;
};

export type MilestoneViewer = {
  __typename?: 'MilestoneViewer';
  /** Last submitted content in a milestone block */
  submission?: Maybe<MilestoneSubmission>;
};

/** Autogenerated return type of MoveBlock. */
export type MoveBlockPayload = {
  __typename?: 'MoveBlockPayload';
  block?: Maybe<LearningPathBlockInterface>;
  errors?: Maybe<Array<UserError>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Adds a comment to the applicant */
  addApplicantComment?: Maybe<AddApplicantCommentPayload>;
  /** Adds a comment to a block */
  addCommentToBlock?: Maybe<AddCommentToBlockPayload>;
  /** Adds a comment to a cohort post */
  addCommentToCohortPost?: Maybe<AddCommentToCohortPostPayload>;
  /** Adds a comment to a cohort profile introduction */
  addCommentToCohortProfileIntroduction?: Maybe<AddCommentToCohortProfileIntroductionPayload>;
  /** Adds a comment to an event */
  addCommentToEvent?: Maybe<AddCommentToEventPayload>;
  /** Adds a comment to a milestone submission feedback request */
  addCommentToFeedbackRequest?: Maybe<AddCommentToFeedbackRequestPayload>;
  /** Add a peer as favorite in a cohort */
  addFavoritePeer?: Maybe<AddFavoritePeerPayload>;
  /** Adds a reply to a channel message */
  addReplyToChannelMessage?: Maybe<AddReplyToChannelMessagePayload>;
  /** Adds a reply to a comment */
  addReplyToComment?: Maybe<AddReplyToCommentPayload>;
  /** Creates a MilestoneSubmissionFeedbackResponse */
  addResponseToFeedbackRequest?: Maybe<AddResponseToFeedbackRequestPayload>;
  /** An application to a cohort */
  applyToCohort?: Maybe<ApplyToCohortPayload>;
  /** Creates a LearningPathAssignmentBlock inside a section */
  createAssignmentBlock?: Maybe<CreateAssignmentBlockPayload>;
  /** Creates a channel for messaging */
  createChannel?: Maybe<CreateChannelPayload>;
  /** Creates a cohort for a learning community */
  createCohort?: Maybe<CreateCohortPayload>;
  /** Creates a Cohort Post */
  createCohortPost?: Maybe<CreateCohortPostPayload>;
  /** Creates community resource in a cohort */
  createCommunityResource?: Maybe<CreateCommunityResourcePayload>;
  /** Creates a LearningPathContentBlock inside a section */
  createContentBlock?: Maybe<CreateContentBlockPayload>;
  /** Creates an event */
  createEvent?: Maybe<CreateEventPayload>;
  /** Creates a LearningPathLiveSessionBlock inside a section */
  createLiveSessionBlock?: Maybe<CreateLiveSessionBlockPayload>;
  /** Creates a LearningPathMilestoneBlock inside a section */
  createMilestoneBlock?: Maybe<CreateMilestoneBlockPayload>;
  /** Creates a section in a cohort's learning path */
  createSection?: Maybe<CreateSectionPayload>;
  /** Creates a survey submission */
  createSurveySubmission?: Maybe<CreateSurveySubmissionPayload>;
  /** Create a Zapier integration */
  createZapierIntegration?: Maybe<CreateZapierIntegrationPayload>;
  /** Deletes a learning path block in a section */
  deleteBlock?: Maybe<DeleteBlockPayload>;
  /** Deletes a channel */
  deleteChannel?: Maybe<DeleteChannelPayload>;
  /** Deletes a channel message */
  deleteChannelMessage?: Maybe<DeleteChannelMessagePayload>;
  /** Delete a cohort */
  deleteCohort?: Maybe<DeleteCohortPayload>;
  /** Deletes a comment */
  deleteComment?: Maybe<DeleteCommentPayload>;
  /** Deletes an event */
  deleteEvent?: Maybe<DeleteEventPayload>;
  /** Removes RSVP for an event. */
  deleteEventRsvp?: Maybe<DeleteEventRsvpPayload>;
  /** Deletes a feed entry */
  deleteFeedEntry?: Maybe<DeleteFeedEntryPayload>;
  /** Removes RSVP for live sessions in a section. */
  deleteRsvpForSectionLiveSessions?: Maybe<DeleteRsvpForSectionLiveSessionsPayload>;
  /** Deletes a section from a cohort's learning path */
  deleteSection?: Maybe<DeleteSectionPayload>;
  /** Deletes a video meeting recording */
  deleteVideoMeetingRecording?: Maybe<DeleteVideoMeetingRecordingPayload>;
  /** Duplicates a learning path block in a section */
  duplicateBlock?: Maybe<DuplicateBlockPayload>;
  /** Duplicates a section from a cohort's learning path */
  duplicateSection?: Maybe<DuplicateSectionPayload>;
  /** Generates topic suggestions for a learning community. */
  generateLearningCommunityTopicSuggestions?: Maybe<GenerateLearningCommunityTopicSuggestionsPayload>;
  /** Generates a validation survey for a learning community */
  generateLearningCommunityValidationSurvey?: Maybe<GenerateLearningCommunityValidationSurveyPayload>;
  /** Generates a learning path outline asynchronously */
  generateLearningPathOutline?: Maybe<GenerateLearningPathOutlinePayload>;
  /** Joins a channel */
  joinChannel?: Maybe<JoinChannelPayload>;
  /** Joins as a creator to the LC Team */
  joinLearningCommunityTeam?: Maybe<JoinLearningCommunityTeamPayload>;
  /** Leaves a channel */
  leaveChannel?: Maybe<LeaveChannelPayload>;
  /** Mark a Learning Block as Completed */
  markBlockAsDone?: Maybe<MarkBlockCompletedPayload>;
  /** Moves a learning path block to a different section */
  moveBlock?: Maybe<MoveBlockPayload>;
  /** Rate a learning block */
  rateLearningBlock?: Maybe<RateLearningBlockPayload>;
  /** Remove a peer as favorite in a cohort */
  removeFavoritePeer?: Maybe<RemoveFavoritePeerPayload>;
  /** Removes a user from the learning community team */
  removeUserFromLearningCommunityTeam?: Maybe<RemoveUserFromLearningCommunityTeamPayload>;
  /** RSVPs yes to an event */
  rsvpYesToEvent?: Maybe<RsvpYesToEventPayload>;
  /** RSVPs yes to a live session block. Removes RSVPs from other blocks in the same section. */
  rsvpYesToLiveSessionBlock?: Maybe<RsvpYesToLiveSessionBlockPayload>;
  /** Updates onboarding progress for a cohort learner */
  saveLearnerOnboarding?: Maybe<SaveLearnerOnboardingPayload>;
  /** Updates Milestone Submission with sent content */
  saveMilestoneProgress?: Maybe<SaveMilestoneProgressPayload>;
  /** Creates a Calendar Event and send invites to attendees */
  sendCalendarInvites?: Maybe<SendCalendarInvitesPayload>;
  /** Sends a message to a channel */
  sendChannelMessage?: Maybe<SendChannelMessagePayload>;
  /** Introduces a user to the cohort */
  shareCohortProfile?: Maybe<CreateCohortUserProfileIntroductionPayload>;
  /** Shares a Milestone Submission to the feed */
  shareMilestoneSubmission?: Maybe<ShareMilestoneSubmissionPayload>;
  /** Adds or removes a reaction to a channel message */
  toggleReactionToChannelMessage?: Maybe<ToggleReactionToChannelMessagePayload>;
  /** Adds or removes a reaction to a cohort post */
  toggleReactionToCohortPost?: Maybe<ToggleReactionToCohortPostPayload>;
  /** Adds or removes a reaction to a cohort profile introduction */
  toggleReactionToCohortProfileIntroduction?: Maybe<ToggleReactionToCohortProfileIntroductionPayload>;
  /** Adds or removes a reaction to a comment */
  toggleReactionToComment?: Maybe<ToggleReactionToCommentPayload>;
  /** Adds or removes a reaction to a milestone submission feedback request */
  toggleReactionToFeedbackRequest?: Maybe<ToggleReactionToFeedbackRequestPayload>;
  /** Updates the applicant cohort */
  updateApplicantCohort?: Maybe<UpdateApplicantCohortPayload>;
  /** Updates the applicant status */
  updateApplicantStatus?: Maybe<UpdateApplicantStatusPayload>;
  /** Updates the applicant form for a learning community */
  updateApplicationForm?: Maybe<UpdateApplicationFormPayload>;
  /** Updates a LearningPathAssignmentBlock */
  updateAssignmentBlock?: Maybe<UpdateAssignmentBlockPayload>;
  /** Updates a channel */
  updateChannel?: Maybe<UpdateChannelPayload>;
  /** Updates a channel message */
  updateChannelMessage?: Maybe<UpdateChannelMessagePayload>;
  /** Update a cohort */
  updateCohort?: Maybe<UpdateCohortPayload>;
  /** Updates a Cohort Post */
  updateCohortPost?: Maybe<UpdateCohortPostPayload>;
  /** Updates the profile of a user */
  updateCohortUserProfile?: Maybe<UpdateCohortUserProfilePayload>;
  /** Updates a comment */
  updateComment?: Maybe<UpdateCommentPayload>;
  /** Updates a LearningPathContentBlock */
  updateContentBlock?: Maybe<UpdateContentBlockPayload>;
  /** Updates an email template */
  updateEmailTemplate?: Maybe<UpdateEmailTemplatePayload>;
  /** Updates an event */
  updateEvent?: Maybe<UpdateEventPayload>;
  /** Update a learning community */
  updateLearningCommunity?: Maybe<UpdateLearningCommunityPayload>;
  /** Updates a LearningPathLiveSessionBlock */
  updateLiveSessionBlock?: Maybe<UpdateLiveSessionBlockPayload>;
  /** Updates a LearningPathMilestoneBlock */
  updateMilestoneBlock?: Maybe<UpdateMilestoneBlockPayload>;
  /** Updates the profile of the current user */
  updateMyProfile?: Maybe<UpdateUserProfilePayload>;
  /** Updates a section for a cohort's learning path */
  updateSection?: Maybe<UpdateSectionPayload>;
  /** Updates a survey */
  updateSurvey?: Maybe<UpdateSurveyPayload>;
};


export type MutationAddApplicantCommentArgs = {
  applicantId: Scalars['ID'];
  content: Scalars['String'];
};


export type MutationAddCommentToBlockArgs = {
  blockId: Scalars['ID'];
  content: Scalars['String'];
  withShare?: Maybe<Scalars['Boolean']>;
};


export type MutationAddCommentToCohortPostArgs = {
  cohortPostId: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationAddCommentToCohortProfileIntroductionArgs = {
  introductionId: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationAddCommentToEventArgs = {
  eventId: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationAddCommentToFeedbackRequestArgs = {
  feedbackRequestId: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationAddFavoritePeerArgs = {
  cohortId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationAddReplyToChannelMessageArgs = {
  attributes: ChannelMessageInput;
  messageId: Scalars['ID'];
};


export type MutationAddReplyToCommentArgs = {
  commentId: Scalars['ID'];
  content: Scalars['String'];
};


export type MutationAddResponseToFeedbackRequestArgs = {
  feedbackRequestId: Scalars['ID'];
  response: Scalars['JSON'];
};


export type MutationApplyToCohortArgs = {
  applicant: ApplicantInput;
  cohortId: Scalars['ID'];
  joinFreemium?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateAssignmentBlockArgs = {
  attributes: AssignmentBlockInput;
  sectionId: Scalars['ID'];
};


export type MutationCreateChannelArgs = {
  attributes: ChannelInput;
};


export type MutationCreateCohortArgs = {
  attributes: CreateCohortInput;
  learningCommunityId: Scalars['ID'];
};


export type MutationCreateCohortPostArgs = {
  attributes: CohortPostInput;
  cohortId: Scalars['ID'];
};


export type MutationCreateCommunityResourceArgs = {
  attributes: CommunityResourceInput;
  cohortId: Scalars['ID'];
};


export type MutationCreateContentBlockArgs = {
  attributes: ContentBlockInput;
  sectionId: Scalars['ID'];
};


export type MutationCreateEventArgs = {
  attributes: CreateEventInput;
};


export type MutationCreateLiveSessionBlockArgs = {
  attributes: LiveSessionBlockInput;
  sectionId: Scalars['ID'];
};


export type MutationCreateMilestoneBlockArgs = {
  attributes: MilestoneBlockInput;
  sectionId: Scalars['ID'];
};


export type MutationCreateSectionArgs = {
  attributes: SectionInput;
  cohortId: Scalars['ID'];
};


export type MutationCreateSurveySubmissionArgs = {
  attributes: SurveySubmissionInput;
  surveyId: Scalars['ID'];
};


export type MutationCreateZapierIntegrationArgs = {
  learningCommunityId: Scalars['ID'];
};


export type MutationDeleteBlockArgs = {
  blockId: Scalars['ID'];
};


export type MutationDeleteChannelArgs = {
  channelId: Scalars['ID'];
};


export type MutationDeleteChannelMessageArgs = {
  messageId: Scalars['ID'];
};


export type MutationDeleteCohortArgs = {
  cohortId: Scalars['ID'];
};


export type MutationDeleteCommentArgs = {
  commentId: Scalars['ID'];
};


export type MutationDeleteEventArgs = {
  eventId: Scalars['ID'];
};


export type MutationDeleteEventRsvpArgs = {
  eventId: Scalars['ID'];
};


export type MutationDeleteFeedEntryArgs = {
  feedEntryId: Scalars['ID'];
};


export type MutationDeleteRsvpForSectionLiveSessionsArgs = {
  sectionId: Scalars['ID'];
};


export type MutationDeleteSectionArgs = {
  sectionId: Scalars['ID'];
};


export type MutationDeleteVideoMeetingRecordingArgs = {
  recordingId: Scalars['ID'];
};


export type MutationDuplicateBlockArgs = {
  blockId: Scalars['ID'];
};


export type MutationDuplicateSectionArgs = {
  sectionId: Scalars['ID'];
};


export type MutationGenerateLearningCommunityTopicSuggestionsArgs = {
  async?: Maybe<Scalars['Boolean']>;
  learningCommunitySlug: Scalars['String'];
  shouldRegenerate?: Maybe<Scalars['Boolean']>;
};


export type MutationGenerateLearningCommunityValidationSurveyArgs = {
  async?: Maybe<Scalars['Boolean']>;
  learningCommunitySlug: Scalars['String'];
  referenceTopic?: Maybe<Scalars['String']>;
  shouldRegenerate?: Maybe<Scalars['Boolean']>;
};


export type MutationGenerateLearningPathOutlineArgs = {
  attributes: CreateLearningPathOutlineInput;
  learningCommunityId: Scalars['ID'];
  shouldRegenerate?: Maybe<Scalars['Boolean']>;
};


export type MutationJoinChannelArgs = {
  channelId: Scalars['ID'];
};


export type MutationJoinLearningCommunityTeamArgs = {
  invitationCode: Scalars['String'];
  learningCommunitySlug: Scalars['String'];
};


export type MutationLeaveChannelArgs = {
  channelId: Scalars['ID'];
};


export type MutationMarkBlockAsDoneArgs = {
  blockId: Scalars['ID'];
};


export type MutationMoveBlockArgs = {
  blockId: Scalars['ID'];
  position?: Maybe<Scalars['Int']>;
  sectionId?: Maybe<Scalars['ID']>;
};


export type MutationRateLearningBlockArgs = {
  blockId: Scalars['ID'];
  rating: BlockRatingInput;
};


export type MutationRemoveFavoritePeerArgs = {
  cohortId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationRemoveUserFromLearningCommunityTeamArgs = {
  learningCommunityId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationRsvpYesToEventArgs = {
  eventId: Scalars['ID'];
};


export type MutationRsvpYesToLiveSessionBlockArgs = {
  blockId: Scalars['ID'];
};


export type MutationSaveLearnerOnboardingArgs = {
  attributes: CohortUserProfileInput;
  cohortId: Scalars['ID'];
};


export type MutationSaveMilestoneProgressArgs = {
  attributes?: Maybe<MilestoneSubmissionInput>;
  blockId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  withSubmit: Scalars['Boolean'];
};


export type MutationSendCalendarInvitesArgs = {
  blockId: Scalars['ID'];
};


export type MutationSendChannelMessageArgs = {
  attributes: CreateChannelMessageInput;
  channelId: Scalars['ID'];
};


export type MutationShareCohortProfileArgs = {
  cohortId: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
};


export type MutationShareMilestoneSubmissionArgs = {
  feedbackRequestMessage: Scalars['String'];
  milestoneSubmissionId: Scalars['ID'];
};


export type MutationToggleReactionToChannelMessageArgs = {
  messageId: Scalars['ID'];
  reactionContent: Scalars['String'];
};


export type MutationToggleReactionToCohortPostArgs = {
  cohortPostId: Scalars['ID'];
  reactionContent: Scalars['String'];
};


export type MutationToggleReactionToCohortProfileIntroductionArgs = {
  introductionId: Scalars['ID'];
  reactionContent: Scalars['String'];
};


export type MutationToggleReactionToCommentArgs = {
  commentId: Scalars['ID'];
  reactionContent: Scalars['String'];
};


export type MutationToggleReactionToFeedbackRequestArgs = {
  feedbackRequestId: Scalars['ID'];
  reactionContent: Scalars['String'];
};


export type MutationUpdateApplicantCohortArgs = {
  applicantId: Scalars['ID'];
  newCohortId: Scalars['ID'];
};


export type MutationUpdateApplicantStatusArgs = {
  applicantId: Scalars['ID'];
  newStatusKey: Scalars['String'];
};


export type MutationUpdateApplicationFormArgs = {
  attributes: ApplicationFormInput;
  learningCommunitySlug: Scalars['String'];
};


export type MutationUpdateAssignmentBlockArgs = {
  attributes: AssignmentBlockInput;
  blockId: Scalars['ID'];
};


export type MutationUpdateChannelArgs = {
  attributes: UpdateChannelInput;
  channelId: Scalars['ID'];
};


export type MutationUpdateChannelMessageArgs = {
  attributes: ChannelMessageInput;
  messageId: Scalars['ID'];
};


export type MutationUpdateCohortArgs = {
  attributes: UpdateCohortInput;
  cohortId: Scalars['ID'];
};


export type MutationUpdateCohortPostArgs = {
  attributes: CohortPostInput;
  cohortPostId: Scalars['ID'];
};


export type MutationUpdateCohortUserProfileArgs = {
  attributes: CohortUserProfileInput;
  cohortId: Scalars['ID'];
};


export type MutationUpdateCommentArgs = {
  attributes: CommentInput;
  commentId: Scalars['ID'];
};


export type MutationUpdateContentBlockArgs = {
  attributes: ContentBlockInput;
  blockId: Scalars['ID'];
};


export type MutationUpdateEmailTemplateArgs = {
  attributes: UpdateEmailTemplateInput;
  emailTemplateId: Scalars['ID'];
};


export type MutationUpdateEventArgs = {
  attributes: UpdateEventInput;
  eventId: Scalars['ID'];
};


export type MutationUpdateLearningCommunityArgs = {
  attributes: UpdateLearningCommunityInput;
  learningCommunityId: Scalars['ID'];
};


export type MutationUpdateLiveSessionBlockArgs = {
  attributes: LiveSessionBlockInput;
  blockId: Scalars['ID'];
};


export type MutationUpdateMilestoneBlockArgs = {
  attributes: MilestoneBlockInput;
  blockId: Scalars['ID'];
};


export type MutationUpdateMyProfileArgs = {
  attributes: UserProfileInput;
};


export type MutationUpdateSectionArgs = {
  attributes: SectionInput;
  sectionId: Scalars['ID'];
};


export type MutationUpdateSurveyArgs = {
  attributes: SurveyInput;
  surveyId: Scalars['ID'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type ParagraphAnswer = StructuredFeedbackFormatInterface & {
  __typename?: 'ParagraphAnswer';
  /** Content of the answer */
  content: Scalars['String'];
  /** Description of the rubric */
  description?: Maybe<Scalars['String']>;
  /** The id of the milestone rubric */
  rubricId: Scalars['String'];
  /** Title of the rubric */
  title: Scalars['String'];
  /** ENUM value for the type */
  type: StructuredFeedbackFormatEnum;
};

export type ParagraphQuestion = MilestoneRubricQuestionInterface & {
  __typename?: 'ParagraphQuestion';
  /** Description of the description */
  description?: Maybe<Scalars['String']>;
  /** ID of the rubric */
  rubricId: Scalars['String'];
  /** Title of the question */
  title: Scalars['String'];
  /** ENUM value for the type */
  type: StructuredFeedbackFormatEnum;
};

export type PeerGroup = {
  __typename?: 'PeerGroup';
  /** ID of the peer group */
  id: Scalars['ID'];
  /** Name of the peer group */
  name?: Maybe<Scalars['String']>;
};

/** Possible filters for people */
export enum PeopleFilterEnum {
  /** Feed entries from everyone */
  All = 'all',
  /** Only my feed entries */
  Me = 'me'
}

/** List of preferred pronouns */
export enum PronounsEnum {
  /** He/Him */
  HeHim = 'HE_HIM',
  /** Rather not say */
  RatherNotSay = 'RATHER_NOT_SAY',
  /** She/Her */
  SheHer = 'SHE_HER',
  /** They/Them */
  TheyThem = 'THEY_THEM'
}

export type Query = {
  __typename?: 'Query';
  /** Applicant related information */
  applicant?: Maybe<Applicant>;
  /** Information for the application form */
  applicationForm?: Maybe<ApplicationForm>;
  /** A single channel */
  channel?: Maybe<Channel>;
  /** List of channels for the user */
  channels: ChannelConnection;
  /** Information about a cohort */
  cohort?: Maybe<Cohort>;
  /** CRM related information for all cohorts */
  crm: Crm;
  /** Information about an email template */
  emailTemplate?: Maybe<EmailTemplate>;
  /** Information about an event */
  event?: Maybe<Event>;
  /** A feed containing entries */
  feed: Feed;
  /** A single feed entry */
  feedEntry?: Maybe<FeedEntryInterface>;
  /** Information about a learning community */
  learningCommunity?: Maybe<LearningCommunity>;
  /** Information about a survey */
  learningCommunitySurvey?: Maybe<Survey>;
  /** List of sections for the cohort's learning path */
  learningPath: LearningPath;
  /** Information about a learning block */
  learningPathBlock?: Maybe<LearningPathBlockInterface>;
  /** The project information for a cohort */
  learningPathProject?: Maybe<LearningPathProject>;
  /** List of live sessions for dynamic squads */
  liveSessionsForDynamicSquads: Array<LearningPathLiveSessionBlock>;
  /** Look up a single direct channel */
  lookupDirectChannel?: Maybe<Channel>;
  /** Information about the logged in user */
  me?: Maybe<User>;
  /** Retrieve a single message */
  message?: Maybe<ChannelMessage>;
  /** Information about a milestone submissions */
  milestoneSubmission?: Maybe<MilestoneSubmission>;
  /** The feedback request for a milestone submission */
  milestoneSubmissionFeedbackRequest: MilestoneSubmissionFeedbackRequest;
  /** People for a direct channel */
  peopleForDirectChannel: UserConnection;
  /** Information about a learning block that is public */
  publicLearningPathBlock?: Maybe<LearningPathContentBlock>;
  /** Get Relevant from a query */
  relevantPeople: Array<SuggestedUser>;
  /** A Learning path section */
  section?: Maybe<LearningPathSection>;
  user?: Maybe<User>;
};


export type QueryApplicantArgs = {
  id: Scalars['ID'];
};


export type QueryApplicationFormArgs = {
  learningCommunitySlug: Scalars['String'];
};


export type QueryChannelArgs = {
  channelId: Scalars['ID'];
};


export type QueryChannelsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sourceId?: Maybe<Scalars['ID']>;
  sourceType?: Maybe<ChannelSourceTypeEnum>;
  type?: Maybe<Array<ChannelTypeEnum>>;
};


export type QueryCohortArgs = {
  id: Scalars['ID'];
};


export type QueryCrmArgs = {
  learningCommunitySlug: Scalars['String'];
};


export type QueryEmailTemplateArgs = {
  emailTemplateId: Scalars['ID'];
};


export type QueryEventArgs = {
  id: Scalars['ID'];
};


export type QueryFeedArgs = {
  cohortId: Scalars['ID'];
};


export type QueryFeedEntryArgs = {
  feedEntryId: Scalars['ID'];
};


export type QueryLearningCommunityArgs = {
  slug?: Maybe<Scalars['String']>;
};


export type QueryLearningCommunitySurveyArgs = {
  learningCommunitySlug: Scalars['String'];
  surveyId: Scalars['ID'];
};


export type QueryLearningPathArgs = {
  cohortId: Scalars['ID'];
};


export type QueryLearningPathBlockArgs = {
  blockId: Scalars['ID'];
};


export type QueryLearningPathProjectArgs = {
  cohortId: Scalars['ID'];
};


export type QueryLiveSessionsForDynamicSquadsArgs = {
  sectionId: Scalars['ID'];
};


export type QueryLookupDirectChannelArgs = {
  where: SearchDirectChannelQueryInput;
};


export type QueryMessageArgs = {
  messageId: Scalars['ID'];
};


export type QueryMilestoneSubmissionArgs = {
  milestoneSubmissionId: Scalars['ID'];
};


export type QueryMilestoneSubmissionFeedbackRequestArgs = {
  milestoneSubmissionId: Scalars['ID'];
};


export type QueryPeopleForDirectChannelArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  learningCommunityId: Scalars['ID'];
  query?: Maybe<Scalars['String']>;
};


export type QueryPublicLearningPathBlockArgs = {
  blockSlug: Scalars['String'];
  learningCommunitySlug: Scalars['String'];
};


export type QueryRelevantPeopleArgs = {
  cohortId: Scalars['ID'];
  query: Scalars['String'];
};


export type QuerySectionArgs = {
  sectionId: Scalars['ID'];
};


export type QueryUserArgs = {
  userId: Scalars['ID'];
};

/** Autogenerated return type of RateLearningBlock. */
export type RateLearningBlockPayload = {
  __typename?: 'RateLearningBlockPayload';
  block?: Maybe<LearningPathBlockInterface>;
  errors?: Maybe<Array<UserError>>;
};

export type Reaction = {
  __typename?: 'Reaction';
  /** Emoji value */
  content: Scalars['String'];
  /** Number of reactions of this type for the object */
  total: Scalars['Int'];
  /** List of users that reacted */
  users?: Maybe<Array<User>>;
  /** Viewer info on the reaction */
  viewerStatus: ViewerReactionStatus;
};

/** Autogenerated return type of RemoveFavoritePeer. */
export type RemoveFavoritePeerPayload = {
  __typename?: 'RemoveFavoritePeerPayload';
  errors?: Maybe<Array<UserError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of RemoveUserFromLearningCommunityTeam. */
export type RemoveUserFromLearningCommunityTeamPayload = {
  __typename?: 'RemoveUserFromLearningCommunityTeamPayload';
  errors?: Maybe<Array<UserError>>;
  learningCommunity?: Maybe<LearningCommunity>;
};

/** Attributes for creating/deleting resources for blocks */
export type ResourceInput = {
  description: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  type: BlockResourceTypeEnum;
  url: Scalars['String'];
  withDestroy?: Maybe<Scalars['Boolean']>;
};

/** A role */
export type Role = {
  __typename?: 'Role';
  /** Value to display in client */
  displayValue: Scalars['String'];
  /** ID */
  id: Scalars['ID'];
  /** ENUM value for the role */
  name: RoleNameEnum;
};

/** List of roles */
export enum RoleNameEnum {
  /** Assistant */
  Assistant = 'ASSISTANT',
  /** Collaborator */
  Collaborator = 'COLLABORATOR',
  /** Guest */
  Guest = 'GUEST',
  /** Learner */
  Learner = 'LEARNER',
  /** Creator */
  Owner = 'OWNER',
  /** Success Manager */
  SuccessManager = 'SUCCESS_MANAGER'
}

/** Autogenerated return type of RsvpYesToEvent. */
export type RsvpYesToEventPayload = {
  __typename?: 'RsvpYesToEventPayload';
  errors?: Maybe<Array<UserError>>;
  event?: Maybe<Event>;
};

/** Autogenerated return type of RsvpYesToLiveSessionBlock. */
export type RsvpYesToLiveSessionBlockPayload = {
  __typename?: 'RsvpYesToLiveSessionBlockPayload';
  block?: Maybe<LearningPathLiveSessionBlock>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of SaveLearnerOnboarding. */
export type SaveLearnerOnboardingPayload = {
  __typename?: 'SaveLearnerOnboardingPayload';
  errors?: Maybe<Array<UserError>>;
  user?: Maybe<User>;
};

/** Autogenerated return type of SaveMilestoneProgress. */
export type SaveMilestoneProgressPayload = {
  __typename?: 'SaveMilestoneProgressPayload';
  errors?: Maybe<Array<UserError>>;
  savedMilestone?: Maybe<SavedMilestoneSubmission>;
};

/** Milestone data response after save */
export type SavedMilestoneSubmission = {
  __typename?: 'SavedMilestoneSubmission';
  /** Project of the learning path */
  project?: Maybe<LearningPathProject>;
  /** User generated project content */
  viewer?: Maybe<MilestoneSubmissionViewer>;
};

/** Attributes for searching a channel */
export type SearchDirectChannelQueryInput = {
  /** Array of user ids */
  userIds: Array<Scalars['ID']>;
};

/** Attributes for creating or updating a section */
export type SectionInput = {
  /** End date of the section */
  endDate?: Maybe<Scalars['ISO8601Date']>;
  /** Whether the section is free */
  isFree?: Maybe<Scalars['Boolean']>;
  /** Start date of the section */
  startDate?: Maybe<Scalars['ISO8601Date']>;
  /** Title of the section */
  title: Scalars['String'];
};

/** Autogenerated return type of SendCalendarInvites. */
export type SendCalendarInvitesPayload = {
  __typename?: 'SendCalendarInvitesPayload';
  errors?: Maybe<Array<UserError>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of SendChannelMessage. */
export type SendChannelMessagePayload = {
  __typename?: 'SendChannelMessagePayload';
  errors?: Maybe<Array<UserError>>;
  message?: Maybe<ChannelMessage>;
};

/** Autogenerated return type of ShareMilestoneSubmission. */
export type ShareMilestoneSubmissionPayload = {
  __typename?: 'ShareMilestoneSubmissionPayload';
  errors?: Maybe<Array<UserError>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Statistics = {
  __typename?: 'Statistics';
  /** Completion statistics */
  completion: CompletionStatistics;
  /** Block ratings statistics */
  feedback: FeedbackStatistics;
};

/** Listing the types of answers we can receive at structured feedback */
export enum StructuredFeedbackFormatEnum {
  Form = 'FORM',
  Paragraph = 'PARAGRAPH'
}

export type StructuredFeedbackFormatInterface = {
  /** Description of the rubric */
  description?: Maybe<Scalars['String']>;
  /** The id of the milestone rubric */
  rubricId: Scalars['String'];
  /** Title of the rubric */
  title: Scalars['String'];
  /** ENUM value for the type */
  type: StructuredFeedbackFormatEnum;
};

export type Subscription = {
  __typename?: 'Subscription';
  learningCommunityValidationSurveyGenerated: LearningCommunityValidationSurveyGeneratedPayload;
  learningPathOutlineGenerated: LearningPathOutlineGeneratedPayload;
  suggestedTopicsWereGenerated: SuggestedTopicsWereGeneratedPayload;
  userEvent: UserEventPayload;
};


export type SubscriptionLearningCommunityValidationSurveyGeneratedArgs = {
  learningCommunityId: Scalars['ID'];
};


export type SubscriptionLearningPathOutlineGeneratedArgs = {
  learningCommunityId: Scalars['ID'];
};


export type SubscriptionSuggestedTopicsWereGeneratedArgs = {
  learningCommunityId: Scalars['ID'];
};

/** Autogenerated return type of SuggestedTopicsWereGenerated. */
export type SuggestedTopicsWereGeneratedPayload = {
  __typename?: 'SuggestedTopicsWereGeneratedPayload';
  success: Scalars['Boolean'];
  topicSuggestions: Array<LearningCommunityTopicSuggestion>;
};

/** A suggested user and the reason */
export type SuggestedUser = {
  __typename?: 'SuggestedUser';
  reason?: Maybe<Scalars['String']>;
  user: User;
};

/** Survey */
export type Survey = {
  __typename?: 'Survey';
  /** Created at */
  createdAt: Scalars['ISO8601DateTime'];
  /** Hero image */
  heroImage?: Maybe<Scalars['String']>;
  /** Hero text */
  heroText?: Maybe<Scalars['String']>;
  /** ID */
  id: Scalars['ID'];
  /** Is published */
  isPublished: Scalars['Boolean'];
  /** Questions */
  questions: Array<SurveyQuestion>;
  /** Source of the channel */
  source?: Maybe<SurveySurveyableUnion>;
  /** Statistics */
  statistics?: Maybe<SurveyStatistics>;
  /** Submissions */
  submissions: SurveySubmissionConnection;
  /** Theme color */
  themeColor?: Maybe<Scalars['String']>;
  /** Title */
  title: Scalars['String'];
  /** Type */
  type: Scalars['String'];
};


/** Survey */
export type SurveySubmissionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Attributes for creating/updating a survey */
export type SurveyInput = {
  /** Hero image */
  heroImage?: Maybe<Scalars['String']>;
  /** Hero text */
  heroText?: Maybe<Scalars['String']>;
  /** Is published */
  isPublished?: Maybe<Scalars['Boolean']>;
  /** Questions */
  questions?: Maybe<Array<SurveyQuestionInput>>;
  /** Theme color */
  themeColor?: Maybe<Scalars['String']>;
  /** Title */
  title?: Maybe<Scalars['String']>;
};

/** Survey question */
export type SurveyQuestion = {
  __typename?: 'SurveyQuestion';
  /** ID */
  id: Scalars['ID'];
  /** Options */
  options?: Maybe<Array<Scalars['String']>>;
  /** Position */
  position: Scalars['Int'];
  /** Text of the question */
  question: Scalars['String'];
  /** Type of the question */
  type: SurveyQuestionTypeEnum;
};

/** Attributes for a survey question */
export type SurveyQuestionInput = {
  /** ID of the question */
  id?: Maybe<Scalars['ID']>;
  /** Options */
  options?: Maybe<Array<Scalars['String']>>;
  /** Position */
  position?: Maybe<Scalars['Int']>;
  /** Text of the question */
  question: Scalars['String'];
  /** Type of the question */
  type: SurveyQuestionTypeEnum;
  withDestroy?: Maybe<Scalars['Boolean']>;
};

/** Available survey question types */
export enum SurveyQuestionTypeEnum {
  /** Multiple choice */
  MultipleChoice = 'MULTIPLE_CHOICE',
  /** Paragraph */
  Paragraph = 'PARAGRAPH',
  /** Rating */
  Rating = 'RATING',
  /** Yes/No */
  YesNo = 'YES_NO'
}

/** Survey statistics */
export type SurveyStatistics = {
  __typename?: 'SurveyStatistics';
  /** Submissions report */
  report?: Maybe<Scalars['JSON']>;
  /** Total has accepted more contact */
  totalHasAcceptedMoreContact: Scalars['Int'];
  /** Total submissions */
  totalSubmissions: Scalars['Int'];
  /** Total views */
  totalViews: Scalars['Int'];
};

/** Survey submission */
export type SurveySubmission = {
  __typename?: 'SurveySubmission';
  /** Answers */
  answers: Array<SurveySubmissionAnswer>;
  /** Created at */
  createdAt: Scalars['ISO8601DateTime'];
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** Accepted more contact */
  hasAcceptedMoreContact: Scalars['Boolean'];
  /** ID */
  id: Scalars['ID'];
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Survey */
  survey: Survey;
  /** URL */
  url?: Maybe<Scalars['String']>;
};

/** Survey submission answer */
export type SurveySubmissionAnswer = {
  __typename?: 'SurveySubmissionAnswer';
  /** Answer */
  answer: Scalars['String'];
  /** Question */
  question: SurveyQuestion;
};

/** Attributes of a survey submission answer */
export type SurveySubmissionAnswerInput = {
  /** Answer */
  answer: Scalars['String'];
  /** ID of the question */
  questionId: Scalars['ID'];
};

/** The connection type for SurveySubmission. */
export type SurveySubmissionConnection = {
  __typename?: 'SurveySubmissionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SurveySubmissionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SurveySubmission>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SurveySubmissionEdge = {
  __typename?: 'SurveySubmissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<SurveySubmission>;
};

/** Attributes for creating a survey submission */
export type SurveySubmissionInput = {
  /** Answers */
  answers?: Maybe<Array<SurveySubmissionAnswerInput>>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** Accepted more contact */
  hasAcceptedMoreContact: Scalars['Boolean'];
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** URL */
  url?: Maybe<Scalars['String']>;
};

/** Objects that can be surveyed */
export type SurveySurveyableUnion = Cohort | LearningCommunity;

/** Attributes used to mutate availability time slots */
export type TimeSlotInput = {
  day: WeekDaysEnum;
  endTime: Scalars['HourAndMinute'];
  startTime: Scalars['HourAndMinute'];
};

/** Autogenerated return type of ToggleReactionToChannelMessage. */
export type ToggleReactionToChannelMessagePayload = {
  __typename?: 'ToggleReactionToChannelMessagePayload';
  errors?: Maybe<Array<UserError>>;
  message?: Maybe<ChannelMessage>;
};

/** Autogenerated return type of ToggleReactionToCohortPost. */
export type ToggleReactionToCohortPostPayload = {
  __typename?: 'ToggleReactionToCohortPostPayload';
  cohortPost?: Maybe<CohortPost>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of ToggleReactionToCohortProfileIntroduction. */
export type ToggleReactionToCohortProfileIntroductionPayload = {
  __typename?: 'ToggleReactionToCohortProfileIntroductionPayload';
  errors?: Maybe<Array<UserError>>;
  introduction?: Maybe<CohortProfileIntroduction>;
};

/** Autogenerated return type of ToggleReactionToComment. */
export type ToggleReactionToCommentPayload = {
  __typename?: 'ToggleReactionToCommentPayload';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of ToggleReactionToFeedbackRequest. */
export type ToggleReactionToFeedbackRequestPayload = {
  __typename?: 'ToggleReactionToFeedbackRequestPayload';
  errors?: Maybe<Array<UserError>>;
  feedbackRequest?: Maybe<MilestoneSubmissionFeedbackRequest>;
};

/** Autogenerated return type of UpdateApplicantCohort. */
export type UpdateApplicantCohortPayload = {
  __typename?: 'UpdateApplicantCohortPayload';
  applicant?: Maybe<Applicant>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of UpdateApplicantStatus. */
export type UpdateApplicantStatusPayload = {
  __typename?: 'UpdateApplicantStatusPayload';
  applicant?: Maybe<Applicant>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of UpdateApplicationForm. */
export type UpdateApplicationFormPayload = {
  __typename?: 'UpdateApplicationFormPayload';
  applicationForm?: Maybe<ApplicationForm>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of UpdateAssignmentBlock. */
export type UpdateAssignmentBlockPayload = {
  __typename?: 'UpdateAssignmentBlockPayload';
  block?: Maybe<LearningPathAssignmentBlock>;
  errors?: Maybe<Array<UserError>>;
};

/** Attributes for updating a channel */
export type UpdateChannelInput = {
  /** Description of the channel */
  description?: Maybe<Scalars['String']>;
  /** Emoji icon for the channel */
  emojiIcon?: Maybe<Scalars['String']>;
  /** Name of the channel */
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateChannelMessage. */
export type UpdateChannelMessagePayload = {
  __typename?: 'UpdateChannelMessagePayload';
  errors?: Maybe<Array<UserError>>;
  message?: Maybe<ChannelMessage>;
};

/** Autogenerated return type of UpdateChannel. */
export type UpdateChannelPayload = {
  __typename?: 'UpdateChannelPayload';
  channel?: Maybe<Channel>;
  errors?: Maybe<Array<UserError>>;
};

/** Attributes for updating a cohort */
export type UpdateCohortInput = {
  /** End date of the cohort */
  applicationsEndDate?: Maybe<Scalars['ISO8601Date']>;
  /** End date of the cohort */
  applicationsStartDate?: Maybe<Scalars['ISO8601Date']>;
  /** Link to Calendly booking */
  calendlyLink?: Maybe<Scalars['String']>;
  /** End date of the cohort */
  endDate?: Maybe<Scalars['ISO8601Date']>;
  /** Whether the cohort has AI Assistant */
  hasAiProcessing?: Maybe<Scalars['Boolean']>;
  /** Whether the cohort has dynamic squads */
  hasDynamicSquads?: Maybe<Scalars['Boolean']>;
  /** Whether the cohort has freemium */
  hasFreemium?: Maybe<Scalars['Boolean']>;
  /** Name of the cohort */
  name?: Maybe<Scalars['String']>;
  /** Start date of the cohort */
  startDate?: Maybe<Scalars['ISO8601Date']>;
  /** Type of the cohort */
  type?: Maybe<CohortTypeEnum>;
};

/** Autogenerated return type of UpdateCohort. */
export type UpdateCohortPayload = {
  __typename?: 'UpdateCohortPayload';
  cohort?: Maybe<Cohort>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of UpdateCohortPost. */
export type UpdateCohortPostPayload = {
  __typename?: 'UpdateCohortPostPayload';
  cohortPost?: Maybe<CohortPost>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of UpdateCohortUserProfile. */
export type UpdateCohortUserProfilePayload = {
  __typename?: 'UpdateCohortUserProfilePayload';
  errors?: Maybe<Array<UserError>>;
  user?: Maybe<User>;
};

/** Autogenerated return type of UpdateComment. */
export type UpdateCommentPayload = {
  __typename?: 'UpdateCommentPayload';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of UpdateContentBlock. */
export type UpdateContentBlockPayload = {
  __typename?: 'UpdateContentBlockPayload';
  block?: Maybe<LearningPathContentBlock>;
  errors?: Maybe<Array<UserError>>;
};

/** Attributes for updating an email template */
export type UpdateEmailTemplateInput = {
  /** Content */
  content: Scalars['String'];
  /** Content Type */
  contentType: EmailTemplateContentTypeEnum;
  /** Is Active */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Subject */
  subject: Scalars['String'];
};

/** Autogenerated return type of UpdateEmailTemplate. */
export type UpdateEmailTemplatePayload = {
  __typename?: 'UpdateEmailTemplatePayload';
  emailTemplate?: Maybe<EmailTemplate>;
  errors?: Maybe<Array<UserError>>;
};

/** Attributes for updating an event */
export type UpdateEventInput = {
  /** Description of the event */
  description?: Maybe<Scalars['String']>;
  /** Duration of the event in minutes */
  durationInMinutes: Scalars['Int'];
  /** Image URL */
  imageUrl?: Maybe<Scalars['String']>;
  /** Location of the event */
  location?: Maybe<Scalars['String']>;
  /** Start date of the event */
  startsAt: Scalars['ISO8601DateTime'];
  /** Title of the event */
  title: Scalars['String'];
};

/** Autogenerated return type of UpdateEvent. */
export type UpdateEventPayload = {
  __typename?: 'UpdateEventPayload';
  errors?: Maybe<Array<UserError>>;
  event?: Maybe<Event>;
};

/** Attributes for updating a LC */
export type UpdateLearningCommunityInput = {
  /** External website URL */
  externalWebsiteUrl?: Maybe<Scalars['String']>;
  /** Whether the LC has Ask My YT Channel feature enabled */
  hasAskMyYtChannel?: Maybe<Scalars['Boolean']>;
  /** Whether the LC has interviews */
  hasInterviews?: Maybe<Scalars['Boolean']>;
  /** Locale of the LC */
  locale?: Maybe<LocaleEnum>;
  /** Logo URL */
  logoUrl?: Maybe<Scalars['String']>;
  /** Name of the cohort */
  name?: Maybe<Scalars['String']>;
  /** Support email */
  supportEmail?: Maybe<Scalars['String']>;
  /** Theme color in RGB (separated by spaces) */
  themeColor?: Maybe<Scalars['String']>;
  /** Video meeting provider */
  videoMeetingProvider?: Maybe<VideoMeetingSourceEnum>;
  /** External waiting list URL */
  waitingListExternalUrl?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateLearningCommunity. */
export type UpdateLearningCommunityPayload = {
  __typename?: 'UpdateLearningCommunityPayload';
  errors?: Maybe<Array<UserError>>;
  learningCommunity?: Maybe<LearningCommunity>;
};

/** Autogenerated return type of UpdateLiveSessionBlock. */
export type UpdateLiveSessionBlockPayload = {
  __typename?: 'UpdateLiveSessionBlockPayload';
  block?: Maybe<LearningPathLiveSessionBlock>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of UpdateMilestoneBlock. */
export type UpdateMilestoneBlockPayload = {
  __typename?: 'UpdateMilestoneBlockPayload';
  block?: Maybe<LearningPathMilestoneBlock>;
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of UpdateSection. */
export type UpdateSectionPayload = {
  __typename?: 'UpdateSectionPayload';
  errors?: Maybe<Array<UserError>>;
  section?: Maybe<LearningPathSection>;
};

/** Autogenerated return type of UpdateSurvey. */
export type UpdateSurveyPayload = {
  __typename?: 'UpdateSurveyPayload';
  errors?: Maybe<Array<UserError>>;
  survey?: Maybe<Survey>;
};

/** Autogenerated return type of UpdateUserProfile. */
export type UpdateUserProfilePayload = {
  __typename?: 'UpdateUserProfilePayload';
  errors?: Maybe<Array<UserError>>;
  user?: Maybe<User>;
};

/** A User of the platform */
export type User = {
  __typename?: 'User';
  /** User's short description of themselves */
  aboutMe?: Maybe<Scalars['String']>;
  /** List of permissions and accounts */
  accounts: Array<UserAccount>;
  /** The application for a specific cohort */
  application?: Maybe<Applicant>;
  /** Stored avatar or Gravatar */
  avatarUrl: Scalars['String'];
  /** The profile of the user in a cohort */
  cohortProfile?: Maybe<CohortUserProfile>;
  /** List of user contacts */
  contacts?: Maybe<Array<ContactUser>>;
  /** Email */
  email: Scalars['String'];
  /** First name */
  firstName?: Maybe<Scalars['String']>;
  /** Derived full name */
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The job description of the user */
  jobDescription?: Maybe<Scalars['String']>;
  /** Last name */
  lastName?: Maybe<Scalars['String']>;
  /** Location that also represents timezone */
  location?: Maybe<Scalars['String']>;
  /** Professional URL */
  professionalUrl?: Maybe<Scalars['String']>;
  /** The user's preferred pronouns */
  pronouns?: Maybe<PronounsEnum>;
  /** Role in the cohort */
  role?: Maybe<Role>;
  /** Timezone provided on signup */
  timezone?: Maybe<Scalars['String']>;
  /** Number of channels with unread messages */
  unreadChannelsCount?: Maybe<Scalars['Int']>;
};


/** A User of the platform */
export type UserApplicationArgs = {
  cohortId: Scalars['ID'];
};


/** A User of the platform */
export type UserCohortProfileArgs = {
  cohortId: Scalars['ID'];
};


/** A User of the platform */
export type UserRoleArgs = {
  cohortId: Scalars['ID'];
};

/** An account of an user */
export type UserAccount = {
  __typename?: 'UserAccount';
  /** ID of the account */
  id: Scalars['ID'];
  /** Learning community */
  learningCommunity: LearningCommunity;
  /** Role information */
  role?: Maybe<Role>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

/** A user-readable error */
export type UserError = {
  __typename?: 'UserError';
  /** Extra information about the error */
  extensions?: Maybe<BaseErrorExtensions>;
  /** Field with the error */
  field: Scalars['String'];
  /** A description of the error */
  message: Scalars['String'];
  /** Which input value this error came from */
  path?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of UserEvent. */
export type UserEventPayload = {
  __typename?: 'UserEventPayload';
  channel?: Maybe<Channel>;
  message?: Maybe<ChannelMessage>;
};

/** Filter users input type */
export type UserFilterInput = {
  interests?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Attributes relating to a user's profile */
export type UserProfileInput = {
  aboutMe?: Maybe<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  jobDescription?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  professionalUrl?: Maybe<Scalars['String']>;
  pronouns?: Maybe<PronounsEnum>;
  timezone?: Maybe<Scalars['String']>;
};

/** A meeting room for the live session block */
export type VideoMeeting = {
  __typename?: 'VideoMeeting';
  /** End date of the meeting */
  endDate: Scalars['ISO8601Date'];
  /** ID of the meeting room */
  id: Scalars['ID'];
  /** List of video meeting recordings */
  recordings: Array<VideoMeetingRecording>;
  /** Meeting URL to share externally */
  shareableUrl?: Maybe<Scalars['String']>;
  /** Source of the video meeting */
  source: VideoMeetingSourceEnum;
  /** Start date of the meeting */
  startDate: Scalars['ISO8601Date'];
  /** Whereby meeting url for both host and participant */
  url?: Maybe<Scalars['String']>;
  /** Status of the user in the meeting */
  viewerStatus: VideoMeetingStatus;
};

/** A recording from a video meeting */
export type VideoMeetingRecording = {
  __typename?: 'VideoMeetingRecording';
  /** ID of the video recording */
  id: Scalars['ID'];
  /** URL of the video */
  url: Scalars['String'];
};

/** Sources for video meetings */
export enum VideoMeetingSourceEnum {
  /** External */
  External = 'EXTERNAL',
  /** Whereby */
  Whereby = 'WHEREBY',
  /** Zoom */
  Zoom = 'ZOOM'
}

export type VideoMeetingStatus = {
  __typename?: 'VideoMeetingStatus';
  /** When the user attended the session for the first time */
  attendedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** Whether the meeting is available for the user */
  isAvailable: Scalars['Boolean'];
  /** Whether the meeting is considered over for the user */
  isOver: Scalars['Boolean'];
};

export type ViewerReactionStatus = {
  __typename?: 'ViewerReactionStatus';
  /** Whether the user reacted */
  reacted: Scalars['Boolean'];
};

/** A record of a person who has joined the waiting list for a learning community */
export type WaitingListRecord = {
  __typename?: 'WaitingListRecord';
  /** Email of the person who joined the waiting list */
  email?: Maybe<Scalars['String']>;
  /** ID of the waiting list record */
  id: Scalars['ID'];
  /** Name of the person who joined the waiting list */
  name?: Maybe<Scalars['String']>;
  /** Survey submission for the record if any */
  surveySubmission?: Maybe<SurveySubmission>;
  /** URL of the person who joined the waiting list */
  url?: Maybe<Scalars['String']>;
};

/** The connection type for WaitingListRecord. */
export type WaitingListRecordConnection = {
  __typename?: 'WaitingListRecordConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WaitingListRecordEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<WaitingListRecord>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type WaitingListRecordEdge = {
  __typename?: 'WaitingListRecordEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<WaitingListRecord>;
};

/** Listing the days of the week */
export enum WeekDaysEnum {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

/** Youtube Integration */
export type YoutubeAccount = {
  __typename?: 'YoutubeAccount';
  /** User that added the integration */
  addedBy?: Maybe<User>;
  /** Youtube channel data */
  channelData?: Maybe<YoutubeChannel>;
  /** Youtube channel videos */
  channelVideos?: Maybe<Array<YoutubeChannelVideo>>;
  /** Enabled scopes */
  enabledScopes: Array<Scalars['String']>;
  /** Intent */
  intent: Scalars['String'];
};

/** Youtube channel */
export type YoutubeChannel = {
  __typename?: 'YoutubeChannel';
  /** External channel ID */
  externalChannelId: Scalars['String'];
  /** ID */
  id: Scalars['ID'];
  /** Snippet */
  snippet?: Maybe<YoutubeChannelSnippet>;
  /** Statistics */
  statistics?: Maybe<YoutubeChannelStatistics>;
};

/** Youtube channel snippet */
export type YoutubeChannelSnippet = {
  __typename?: 'YoutubeChannelSnippet';
  /** Custom URL */
  customUrl: Scalars['String'];
  /** Description */
  description: Scalars['String'];
  /** Thumbnails */
  thumbnails: Array<YoutubeChannelThumbnail>;
  /** Title */
  title: Scalars['String'];
};

/** Youtube channel statistics */
export type YoutubeChannelStatistics = {
  __typename?: 'YoutubeChannelStatistics';
  /** Subscriber count */
  subscriberCount: Scalars['Int'];
  /** Video count */
  videoCount: Scalars['Int'];
  /** View count */
  viewCount: Scalars['Int'];
};

/** Youtube channel snippet */
export type YoutubeChannelThumbnail = {
  __typename?: 'YoutubeChannelThumbnail';
  /** Height */
  height: Scalars['Int'];
  /** size */
  size: Scalars['String'];
  /** URL */
  url: Scalars['String'];
  /** Width */
  width: Scalars['Int'];
};

/** Youtube video */
export type YoutubeChannelVideo = {
  __typename?: 'YoutubeChannelVideo';
  /** Content details */
  contentDetails?: Maybe<YoutubeChannelVideoContentDetails>;
  /** External video ID */
  externalVideoId: Scalars['String'];
  /** ID */
  id: Scalars['ID'];
  /** Snippet */
  snippet?: Maybe<YoutubeChannelVideoSnippet>;
  /** Statistics */
  statistics?: Maybe<YoutubeChannelVideoStatistics>;
};

/** Youtube channel video content details */
export type YoutubeChannelVideoContentDetails = {
  __typename?: 'YoutubeChannelVideoContentDetails';
  /** Duration in ISO 8601 format */
  duration: Scalars['String'];
};

/** Youtube channel snippet */
export type YoutubeChannelVideoSnippet = {
  __typename?: 'YoutubeChannelVideoSnippet';
  /** Description */
  description: Scalars['String'];
  /** Published at */
  publishedAt: Scalars['ISO8601DateTime'];
  /** Tags */
  tags: Array<Scalars['String']>;
  /** Thumbnails */
  thumbnails: Array<YoutubeChannelThumbnail>;
  /** Title */
  title: Scalars['String'];
};

/** Youtube channel video statistics */
export type YoutubeChannelVideoStatistics = {
  __typename?: 'YoutubeChannelVideoStatistics';
  /** Comment count */
  commentCount: Scalars['Int'];
  /** Dislike count */
  dislikeCount: Scalars['Int'];
  /** Favorite count */
  favoriteCount: Scalars['Int'];
  /** Like count */
  likeCount: Scalars['Int'];
  /** View count */
  viewCount: Scalars['Int'];
};

export type AddApplicantCommentMutationVariables = Exact<{
  applicantId: Scalars['ID'];
  content: Scalars['String'];
}>;


export type AddApplicantCommentMutation = { __typename?: 'Mutation', addApplicantComment?: Maybe<{ __typename: 'AddApplicantCommentPayload', comment?: Maybe<{ __typename?: 'ApplicantComment', id: string, content: string, createdAt: any, applicant: { __typename?: 'Applicant', id: string, commentsCount: number }, user: { __typename?: 'User', id: string, firstName?: Maybe<string>, lastName?: Maybe<string>, fullName?: Maybe<string> } }>, errors?: Maybe<Array<{ __typename?: 'UserError', field: string, message: string, path?: Maybe<Array<string>>, extensions?: Maybe<{ __typename?: 'BaseErrorExtensions', code?: Maybe<ErrorCodeEnum>, model: string }> }>> }> };

export type ApplyToCohortMutationVariables = Exact<{
  cohortId: Scalars['ID'];
  applicant: ApplicantInput;
  joinFreemium?: Maybe<Scalars['Boolean']>;
}>;


export type ApplyToCohortMutation = { __typename?: 'Mutation', applyToCohort?: Maybe<{ __typename: 'ApplyToCohortPayload', applicant?: Maybe<{ __typename?: 'Applicant', id: string }>, errors?: Maybe<Array<{ __typename?: 'UserError', field: string, message: string, extensions?: Maybe<{ __typename?: 'BaseErrorExtensions', code?: Maybe<ErrorCodeEnum>, model: string }> }>> }> };

export type UpdateApplicantCohortMutationVariables = Exact<{
  applicantId: Scalars['ID'];
  newCohortId: Scalars['ID'];
}>;


export type UpdateApplicantCohortMutation = { __typename?: 'Mutation', updateApplicantCohort?: Maybe<{ __typename: 'UpdateApplicantCohortPayload', applicant?: Maybe<{ __typename?: 'Applicant', id: string, cohort: { __typename?: 'Cohort', id: string, name: string } }>, errors?: Maybe<Array<{ __typename?: 'UserError', field: string, message: string, path?: Maybe<Array<string>>, extensions?: Maybe<{ __typename?: 'BaseErrorExtensions', code?: Maybe<ErrorCodeEnum>, model: string }> }>> }> };

export type UpdateApplicantStatusMutationVariables = Exact<{
  applicantId: Scalars['ID'];
  newStatusKey: Scalars['String'];
}>;


export type UpdateApplicantStatusMutation = { __typename?: 'Mutation', updateApplicantStatus?: Maybe<{ __typename: 'UpdateApplicantStatusPayload', applicant?: Maybe<{ __typename?: 'Applicant', id: string, status: { __typename?: 'ApplicantStatus', current: { __typename?: 'ApplicantStatusOption', key: string, displayValue: string, textColor: string, backgroundColor: string } } }>, errors?: Maybe<Array<{ __typename?: 'UserError', field: string, message: string, path?: Maybe<Array<string>>, extensions?: Maybe<{ __typename?: 'BaseErrorExtensions', code?: Maybe<ErrorCodeEnum>, model: string }> }>> }> };

export type UpdateApplicationFormMutationVariables = Exact<{
  learningCommunitySlug: Scalars['String'];
  attributes: ApplicationFormInput;
}>;


export type UpdateApplicationFormMutation = { __typename?: 'Mutation', updateApplicationForm?: Maybe<{ __typename: 'UpdateApplicationFormPayload', applicationForm?: Maybe<{ __typename?: 'ApplicationForm', questions: Array<{ __typename?: 'ApplicantFormQuestion', id: string, isRequired: boolean, question: string, position: number }> }>, errors?: Maybe<Array<{ __typename?: 'UserError', field: string, message: string, path?: Maybe<Array<string>>, extensions?: Maybe<{ __typename?: 'BaseErrorExtensions', code?: Maybe<ErrorCodeEnum>, model: string }> }>> }> };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', me?: Maybe<{ __typename?: 'User', id: string, firstName?: Maybe<string>, lastName?: Maybe<string>, fullName?: Maybe<string>, email: string, avatarUrl: string, timezone?: Maybe<string>, location?: Maybe<string>, unreadChannelsCount?: Maybe<number>, accounts: Array<{ __typename?: 'UserAccount', id: string, learningCommunity: { __typename?: 'LearningCommunity', id: string, logoUrl?: Maybe<string>, slug: string, name: string, channels: Array<{ __typename?: 'Channel', id: string, name?: Maybe<string>, viewer?: Maybe<{ __typename?: 'ChannelViewer', hasUnreadMessages: boolean }> }>, cohorts: Array<{ __typename?: 'Cohort', id: string, name: string, viewerRole?: Maybe<{ __typename?: 'Role', id: string, name: RoleNameEnum, displayValue: string }>, schedule?: Maybe<{ __typename?: 'CohortSchedule', startDate?: Maybe<any> }>, mainChannel?: Maybe<{ __typename?: 'Channel', id: string, viewer?: Maybe<{ __typename?: 'ChannelViewer', hasUnreadMessages: boolean }> }> }> }, role?: Maybe<{ __typename?: 'Role', id: string, name: RoleNameEnum, displayValue: string }> }> }> };

export type GetApplicationFormQueryVariables = Exact<{
  learningCommunitySlug: Scalars['String'];
}>;


export type GetApplicationFormQuery = { __typename?: 'Query', applicationForm?: Maybe<{ __typename?: 'ApplicationForm', body?: Maybe<string>, heroImageUrl?: Maybe<string>, heroImagePublicId?: Maybe<string>, isPublished: boolean, title?: Maybe<string>, url?: Maybe<string>, cohorts: Array<{ __typename?: 'Cohort', id: string, name: string, schedule?: Maybe<{ __typename?: 'CohortSchedule', startDate?: Maybe<any> }> }>, learningCommunity: { __typename?: 'LearningCommunity', id: string, name: string, hasInterviews: boolean, externalWebsiteUrl?: Maybe<string>, waitingListExternalUrl?: Maybe<string>, creatorReachOutOptInText?: Maybe<string>, creator: { __typename?: 'User', id: string, firstName?: Maybe<string>, lastName?: Maybe<string>, fullName?: Maybe<string> } }, questions: Array<{ __typename?: 'ApplicantFormQuestion', id: string, isRequired: boolean, question: string, position: number }> }> };


export const AddApplicantCommentDocument = gql`
    mutation AddApplicantComment($applicantId: ID!, $content: String!) {
  addApplicantComment(applicantId: $applicantId, content: $content) {
    comment {
      applicant {
        id
        commentsCount
      }
      id
      user {
        id
        firstName
        lastName
        fullName
      }
      content
      createdAt
    }
    __typename
    errors {
      field
      message
      extensions {
        code
        model
      }
      path
    }
  }
}
    `;
export type AddApplicantCommentMutationFn = Apollo.MutationFunction<AddApplicantCommentMutation, AddApplicantCommentMutationVariables>;

/**
 * __useAddApplicantCommentMutation__
 *
 * To run a mutation, you first call `useAddApplicantCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddApplicantCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApplicantCommentMutation, { data, loading, error }] = useAddApplicantCommentMutation({
 *   variables: {
 *      applicantId: // value for 'applicantId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useAddApplicantCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddApplicantCommentMutation, AddApplicantCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddApplicantCommentMutation, AddApplicantCommentMutationVariables>(AddApplicantCommentDocument, options);
      }
export type AddApplicantCommentMutationHookResult = ReturnType<typeof useAddApplicantCommentMutation>;
export type AddApplicantCommentMutationResult = Apollo.MutationResult<AddApplicantCommentMutation>;
export type AddApplicantCommentMutationOptions = Apollo.BaseMutationOptions<AddApplicantCommentMutation, AddApplicantCommentMutationVariables>;
export const ApplyToCohortDocument = gql`
    mutation ApplyToCohort($cohortId: ID!, $applicant: ApplicantInput!, $joinFreemium: Boolean) {
  applyToCohort(
    cohortId: $cohortId
    applicant: $applicant
    joinFreemium: $joinFreemium
  ) {
    applicant {
      id
    }
    __typename
    errors {
      field
      message
      extensions {
        code
        model
      }
    }
  }
}
    `;
export type ApplyToCohortMutationFn = Apollo.MutationFunction<ApplyToCohortMutation, ApplyToCohortMutationVariables>;

/**
 * __useApplyToCohortMutation__
 *
 * To run a mutation, you first call `useApplyToCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyToCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyToCohortMutation, { data, loading, error }] = useApplyToCohortMutation({
 *   variables: {
 *      cohortId: // value for 'cohortId'
 *      applicant: // value for 'applicant'
 *      joinFreemium: // value for 'joinFreemium'
 *   },
 * });
 */
export function useApplyToCohortMutation(baseOptions?: Apollo.MutationHookOptions<ApplyToCohortMutation, ApplyToCohortMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyToCohortMutation, ApplyToCohortMutationVariables>(ApplyToCohortDocument, options);
      }
export type ApplyToCohortMutationHookResult = ReturnType<typeof useApplyToCohortMutation>;
export type ApplyToCohortMutationResult = Apollo.MutationResult<ApplyToCohortMutation>;
export type ApplyToCohortMutationOptions = Apollo.BaseMutationOptions<ApplyToCohortMutation, ApplyToCohortMutationVariables>;
export const UpdateApplicantCohortDocument = gql`
    mutation UpdateApplicantCohort($applicantId: ID!, $newCohortId: ID!) {
  updateApplicantCohort(applicantId: $applicantId, newCohortId: $newCohortId) {
    applicant {
      id
      cohort {
        id
        name
      }
    }
    __typename
    errors {
      field
      message
      extensions {
        code
        model
      }
      path
    }
  }
}
    `;
export type UpdateApplicantCohortMutationFn = Apollo.MutationFunction<UpdateApplicantCohortMutation, UpdateApplicantCohortMutationVariables>;

/**
 * __useUpdateApplicantCohortMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantCohortMutation, { data, loading, error }] = useUpdateApplicantCohortMutation({
 *   variables: {
 *      applicantId: // value for 'applicantId'
 *      newCohortId: // value for 'newCohortId'
 *   },
 * });
 */
export function useUpdateApplicantCohortMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicantCohortMutation, UpdateApplicantCohortMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApplicantCohortMutation, UpdateApplicantCohortMutationVariables>(UpdateApplicantCohortDocument, options);
      }
export type UpdateApplicantCohortMutationHookResult = ReturnType<typeof useUpdateApplicantCohortMutation>;
export type UpdateApplicantCohortMutationResult = Apollo.MutationResult<UpdateApplicantCohortMutation>;
export type UpdateApplicantCohortMutationOptions = Apollo.BaseMutationOptions<UpdateApplicantCohortMutation, UpdateApplicantCohortMutationVariables>;
export const UpdateApplicantStatusDocument = gql`
    mutation UpdateApplicantStatus($applicantId: ID!, $newStatusKey: String!) {
  updateApplicantStatus(applicantId: $applicantId, newStatusKey: $newStatusKey) {
    applicant {
      id
      status {
        current {
          key
          displayValue
          textColor
          backgroundColor
        }
      }
    }
    __typename
    errors {
      field
      message
      extensions {
        code
        model
      }
      path
    }
  }
}
    `;
export type UpdateApplicantStatusMutationFn = Apollo.MutationFunction<UpdateApplicantStatusMutation, UpdateApplicantStatusMutationVariables>;

/**
 * __useUpdateApplicantStatusMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantStatusMutation, { data, loading, error }] = useUpdateApplicantStatusMutation({
 *   variables: {
 *      applicantId: // value for 'applicantId'
 *      newStatusKey: // value for 'newStatusKey'
 *   },
 * });
 */
export function useUpdateApplicantStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicantStatusMutation, UpdateApplicantStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApplicantStatusMutation, UpdateApplicantStatusMutationVariables>(UpdateApplicantStatusDocument, options);
      }
export type UpdateApplicantStatusMutationHookResult = ReturnType<typeof useUpdateApplicantStatusMutation>;
export type UpdateApplicantStatusMutationResult = Apollo.MutationResult<UpdateApplicantStatusMutation>;
export type UpdateApplicantStatusMutationOptions = Apollo.BaseMutationOptions<UpdateApplicantStatusMutation, UpdateApplicantStatusMutationVariables>;
export const UpdateApplicationFormDocument = gql`
    mutation UpdateApplicationForm($learningCommunitySlug: String!, $attributes: ApplicationFormInput!) {
  updateApplicationForm(
    learningCommunitySlug: $learningCommunitySlug
    attributes: $attributes
  ) {
    __typename
    applicationForm {
      questions {
        id
        isRequired
        question
        position
      }
    }
    errors {
      field
      message
      extensions {
        code
        model
      }
      path
    }
  }
}
    `;
export type UpdateApplicationFormMutationFn = Apollo.MutationFunction<UpdateApplicationFormMutation, UpdateApplicationFormMutationVariables>;

/**
 * __useUpdateApplicationFormMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationFormMutation, { data, loading, error }] = useUpdateApplicationFormMutation({
 *   variables: {
 *      learningCommunitySlug: // value for 'learningCommunitySlug'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateApplicationFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicationFormMutation, UpdateApplicationFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApplicationFormMutation, UpdateApplicationFormMutationVariables>(UpdateApplicationFormDocument, options);
      }
export type UpdateApplicationFormMutationHookResult = ReturnType<typeof useUpdateApplicationFormMutation>;
export type UpdateApplicationFormMutationResult = Apollo.MutationResult<UpdateApplicationFormMutation>;
export type UpdateApplicationFormMutationOptions = Apollo.BaseMutationOptions<UpdateApplicationFormMutation, UpdateApplicationFormMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  me {
    id
    firstName
    lastName
    fullName
    email
    avatarUrl
    timezone
    location
    unreadChannelsCount
    accounts {
      id
      learningCommunity {
        id
        channels {
          id
          name
          viewer {
            hasUnreadMessages
          }
        }
        logoUrl
        cohorts {
          id
          name
          viewerRole {
            id
            name
            displayValue
          }
          schedule {
            startDate
          }
          mainChannel {
            id
            viewer {
              hasUnreadMessages
            }
          }
        }
        slug
        name
      }
      role {
        id
        name
        displayValue
      }
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const GetApplicationFormDocument = gql`
    query GetApplicationForm($learningCommunitySlug: String!) {
  applicationForm(learningCommunitySlug: $learningCommunitySlug) {
    cohorts {
      id
      name
      schedule {
        startDate
      }
    }
    body
    heroImageUrl
    heroImagePublicId
    learningCommunity {
      id
      name
      hasInterviews
      externalWebsiteUrl
      waitingListExternalUrl
      creatorReachOutOptInText
      creator {
        id
        firstName
        lastName
        fullName
      }
    }
    isPublished
    title
    url
    questions {
      id
      isRequired
      question
      position
    }
  }
}
    `;

/**
 * __useGetApplicationFormQuery__
 *
 * To run a query within a React component, call `useGetApplicationFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationFormQuery({
 *   variables: {
 *      learningCommunitySlug: // value for 'learningCommunitySlug'
 *   },
 * });
 */
export function useGetApplicationFormQuery(baseOptions: Apollo.QueryHookOptions<GetApplicationFormQuery, GetApplicationFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApplicationFormQuery, GetApplicationFormQueryVariables>(GetApplicationFormDocument, options);
      }
export function useGetApplicationFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationFormQuery, GetApplicationFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApplicationFormQuery, GetApplicationFormQueryVariables>(GetApplicationFormDocument, options);
        }
export type GetApplicationFormQueryHookResult = ReturnType<typeof useGetApplicationFormQuery>;
export type GetApplicationFormLazyQueryHookResult = ReturnType<typeof useGetApplicationFormLazyQuery>;
export type GetApplicationFormQueryResult = Apollo.QueryResult<GetApplicationFormQuery, GetApplicationFormQueryVariables>;