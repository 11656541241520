import classNames from 'classnames'

type Props = {
  y?: number
  x?: number
}

const Space: React.FC<React.PropsWithChildren<Props>> = ({ children, y, x }) => {
  const classes = classNames({
    'space-y-1': y === 1,
    'space-y-2': y === 2,
    'space-y-3': y === 3,
    'space-y-4': y === 4,
    'space-y-5': y === 5,
    'space-y-6': y === 6,
    'space-y-7': y === 7,
    'space-y-8': y === 8,
    'space-y-9': y === 9,
    'space-y-10': y === 10,
    'space-y-11': y === 11,
    'space-y-12': y === 12,
    'space-y-13': y === 13,
    'space-y-14': y === 14,
    'space-y-15': y === 15,
    'space-y-16': y === 16,
    'space-y-20': y === 20,
    'space-y-24': y === 24,
    'space-x-1': x === 1,
    'space-x-2': x === 2,
    'space-x-3': x === 3,
    'space-x-4': x === 4,
    'space-x-5': x === 5,
    'space-x-6': x === 6,
    'space-x-7': x === 7,
    'space-x-8': x === 8,
    'space-x-9': x === 9,
    'space-x-10': x === 10,
    'space-x-11': x === 11,
    'space-x-12': x === 12,
    'space-x-13': x === 13,
    'space-x-14': x === 14,
    'space-x-15': x === 15,
    'space-x-16': x === 16,
    'space-x-20': x === 20,
    'space-x-24': x === 24,
  })

  return (
    <div className={classes}>
      {children ?? (
        <>
          <div />
          <div />
        </>
      )}
    </div>
  )
}

export default Space
